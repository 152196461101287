// function to create the paylod with necessary fields based on request
export default function getPayload({
  selectedRequests,
  inputsData,
  configValues,
  selectedLang,
  recaptchaToken,
}) {
  let primaryEmail = inputsData["email"] ? inputsData["email"] : "";
  let providedFields = [];

  selectedRequests &&
    selectedRequests.forEach((request) => {
      for (let key in configValues.actions[request]) {
        if (key === "fields") {
          const dataFields = configValues.actions[request][key]
            .map((field) => {
              switch (field.label) {
                case "Relationship to Corteva":
                  return {
                    value: inputsData["relationshipToCorteva"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Please identify the brand":
                  return {
                    value: inputsData["customerApplicableBrand"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Please provide details about your request":
                  return {
                    value: inputsData["otherRequestDetails"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Please provide details about the request":
                  return {
                    value: inputsData["otherRequestDetails"],
                    label: field.label,
                    _id: field._id,
                  };
                case "What data shall be updated?":
                  return {
                    value: inputsData["correctionDetails"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Please specify what needs to be updated/corrected":
                  return {
                    value: inputsData["correctionDetails"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Please explain why you are making this request":
                  return {
                    value: inputsData["retrictProcessingDetails"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Please select the subject of your question":
                  return {
                    value: inputsData["subjectDetails"],
                    label: field.label,
                    _id: field._id,
                  };
                case "First Name":
                  return {
                    value: inputsData["firstName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Last Name":
                  return {
                    value: inputsData["lastName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Surname":
                  return {
                    value: inputsData["lastName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Company":
                  return {
                    value: inputsData["company"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Phone Number":
                  return {
                    value: inputsData["phoneNumber"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Phone":
                  return {
                    value: inputsData["phoneNumber"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Telephone":
                  return {
                    value: inputsData["phoneNumber"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Email":
                  return {
                    value: inputsData["email"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Address":
                  return {
                    value: inputsData["address"],
                    label: field.label,
                    _id: field._id,
                  };
                case "State":
                  return {
                    value: inputsData["state"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Country":
                  return {
                    value: inputsData["country"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Location":
                  return {
                    value: inputsData["country"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Legal Representative First Name and Last Name":
                  return {
                    value: inputsData["legalRepresentativeName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Legal Representative Email":
                  return {
                    value: inputsData["email"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Legal Representative Relationship with the person you represent (Data Subject)":
                  return {
                    value:
                      inputsData[
                        "legalRepresentativeRelationshipWithDataSubject"
                      ],
                    label: field.label,
                    _id: field._id,
                  };
                case "Data Subject Relationship to Corteva":
                  return {
                    value: inputsData["dataSubjectRelationship"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Data Subject First Name":
                  return {
                    value: inputsData["dataSubjectFirstName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Data Subject Last Name":
                  return {
                    value: inputsData["dataSubjectLastName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Data Subject Company":
                  return {
                    value: inputsData["dataSubjectCompany"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Data Subject Email":
                  return {
                    value: inputsData["dataSubjectEmail"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Data Subject Phone Number":
                  return {
                    value: inputsData["dataSubjectPhoneNumber"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Data Subject Country":
                  return {
                    value: inputsData["dataSubjectCountry"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Name and Surname of the Legal Representative":
                  return {
                    value: inputsData["legalRepresentativeName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Name and Surname of the Authorized Agent":
                  return {
                    value: inputsData["legalRepresentativeName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Legal Representative's Email":
                  return {
                    value: inputsData["email"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Authorized Agent's Email":
                  return {
                    value: inputsData["email"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Relationship between the Legal Representative and the person you represent (Data Subject)":
                  return {
                    value:
                      inputsData[
                        "legalRepresentativeRelationshipWithDataSubject"
                      ],
                    label: field.label,
                    _id: field._id,
                  };
                case "Relationship between the Authorized Agent and the person you represent (Data Subject)":
                  return {
                    value:
                      inputsData[
                        "legalRepresentativeRelationshipWithDataSubject"
                      ],
                    label: field.label,
                    _id: field._id,
                  };
                case "Relationship between the Data Subject and Corteva":
                  return {
                    value: inputsData["dataSubjectRelationship"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Name of data subject":
                  return {
                    value: inputsData["dataSubjectFirstName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "First Name of data subject":
                  return {
                    value: inputsData["dataSubjectFirstName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Last name of the data subject":
                  return {
                    value: inputsData["dataSubjectLastName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Surname of the data subject":
                  return {
                    value: inputsData["dataSubjectLastName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Company of the data subject":
                  return {
                    value: inputsData["dataSubjectCompany"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Data subject's email":
                  return {
                    value: inputsData["dataSubjectEmail"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Data subject's telephone number":
                  return {
                    value: inputsData["dataSubjectPhoneNumber"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Country of the data subject":
                  return {
                    value: inputsData["dataSubjectCountry"],
                    label: field.label,
                    _id: field._id,
                  };

                default:
                  console.warn(`unknown field ${field.label}`);
              }
              return null;
            })
            .filter((field) => field);

          providedFields = [...providedFields, ...dataFields];
        }
      }
    });
  const dataInputs = {
    data: {
      providedFields,
      primaryEmail,
      providedRequests: selectedRequests,
      locale: selectedLang,
      recaptchaToken,
    },
    meta: {
      id: `${configValues.settings}`,
      pageDataId: `${configValues._id}`,
      type: "dsar",
    },
  };
  return dataInputs;
}
