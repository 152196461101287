import React, { useState, useEffect, useContext } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { booleanContext } from "App";

// Helpers
import { getText } from "shared/helpers/utils";

function RequestTypes(props) {
  const { selectedLang, countryCode } = useContext(booleanContext);
  const handleText = (attr) => {
    return getText(attr, countryCode, selectedLang, "requestTypes");
  };
  const { requestTypes, onOtherRequestType, relationshipType } = props;
  const [checkedRequestTypes, setCheckedRequestTypes] = useState([]);
  const [requestType, setRequestType] = useState([]);

  const handleChange = (e) => {
    if (e.target?.value) {
      e.stopPropagation();
    }
    const inputValue = e.target?.value || e;
    const indexVal = requestType.indexOf(e.target?.id);
    if (indexVal === -1) {
      setRequestType([...requestType, e.target?.id]);
    } else {
      setRequestType(requestType.filter((ele) => ele !== e.target?.id));
    }

    let currentRequestTypes = [...checkedRequestTypes];
    if (currentRequestTypes.indexOf(inputValue) === -1) {
      currentRequestTypes = [...currentRequestTypes, inputValue];
    } else {
      currentRequestTypes = currentRequestTypes.filter(
        (requestType) => requestType !== inputValue
      );
    }

    setCheckedRequestTypes(currentRequestTypes);
    props.onRequestTypesChange(currentRequestTypes);
  };

  useEffect(() => {
    if (requestType) {
      if (requestType.length > 0 && requestType.includes("other")) {
        onOtherRequestType(true);
      } else {
        onOtherRequestType(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestType]);

  useEffect(() => {
    if (relationshipType && requestTypes) {
      const configRequestTypes = requestTypes.map((req) => req.value);
      let currentRequestTypes = [...checkedRequestTypes];
      currentRequestTypes = currentRequestTypes.filter((req) =>
        configRequestTypes.includes(req)
      );

      setCheckedRequestTypes(currentRequestTypes);
      props.onRequestTypesChange(currentRequestTypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipType, requestTypes]);

  return (
    <>
      <p className="my-3">
        <strong className="label-text">
          {handleText("contactReason")}: <span className="text-danger">*</span>
        </strong>
      </p>
      <Row xs={1} className="g-3 mb-4">
        {requestTypes &&
          requestTypes.map((requestType) => {
            return (
              <Col key={`${requestType.value}`}>
                <Card className="card-requestType" key={`${requestType.value}`}>
                  <Card.Body>
                    <Form.Check>
                      <Form.Check.Input
                        type="checkbox"
                        value={`${requestType.value}`}
                        id={`${requestType.value}`}
                        onClick={handleChange}
                      />

                      <Form.Check.Label className="h6">
                        {requestType.description}
                      </Form.Check.Label>
                    </Form.Check>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
      </Row>
    </>
  );
}

export default RequestTypes;
