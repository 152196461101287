export const languages = {
  cl: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request on your own behalf, please complete the form below. If you are a Legal Representative and are submitting a privacy request on behalf of an individual other than yourself, please scroll down to the bottom of the screen to select the Legal Representative form to complete.",
        endingText:
          "If you are a Legal Representative submitting a privacy request on someone's behalf, please",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.cl/politica-privacidad.html",
        urlText: "https://www.corteva.com.cl/politica-privacidad.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting a privacy request on behalf of a Person residing in Chile, please complete the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.cl/politica-privacidad.html",
        urlText: "https://www.corteva.com.cl/politica-privacidad.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        subjectDetails: "Please select the subject of your question",
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Surname",
        lastNamePlaceholder: "Surname *",
        phone: "Telephone",
        phonePlaceholder: "Telephone *",
        country: "Location",
        countryPlaceholder: "Select Location *",
        company: "Company",
        companyPlaceholder: "Company",
        robot: "I'm not a robot",
        submitRequest: "Send Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Name and Surname of the Legal Representative",
        legalEmail: "Legal Representative's Email",
        legalRelation:
          "Relationship between the Legal Representative and the person you represent (Data Subject)",
        consumerRelation: "Relationship between the Data Subject and Corteva",
        consumerFirstName: "Name of data subject",
        consumerLastName: "Last name of the data subject",
        consumerCompany: "Company of the data subject",
        consumerPhone: "Data subject's telephone number",
        consumerEmail: "Data subject's email",
        consumerCountry: "Country of the data subject",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal Representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves (such as an heir or attorney). Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject, as well as a Power of Attorney or a written authorization from the Data Subject.  If Legal Representative is an attorney, please attach a Power of Attorney.  Upon submitting the request using this form, please login to the Data Privacy Center by clicking on the “View or manage your request” button in the email with the subject line “Data Subject Request Started” to submit the proof of identity documents. Proof of identity documents for the data subject and representative may include a copy of the national ID cards together with the Legal Representative Affidavit.",

        heading: "Instructions for uploading the document:",
        description2:
          'Log in to the Data Privacy Center by clicking the "View or Manage Your Request" button in the email with the subject line "Data Subject Request Started."',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    es: {
      intakeForm: {
        title: "Solicitud de privacidad",
        introText:
          "Si envía una solicitud en su propio nombre, complete el siguiente formulario. Si usted es un Representante Legal y está enviando una solicitud de privacidad en nombre de una persona que no sea usted, desplácese hacia abajo hasta la parte inferior de la pantalla para seleccionar el formulario de Representante Legal para completar.",
        endingText:
          "Si usted es un representante legal que presenta una solicitud de privacidad en nombre de alguien,",
        click: "haga clic aquí",
        access: "access",
        accessDescription: "Solicitud de acceso/copia de información personal",
        deletion: "deletion",
        deletionDescription: "Eliminar información personal",
        correction: "correction",
        correctionDescription: "Corregir/Actualizar Información Personal",
        optOut: "optOut",
        optOutDescription: "Darse de baja de las comunicaciones de marketing",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription:
          "Retirar el consentimiento para el tratamiento",
        description1:
          "Corteva Agriscience™ se preocupa por su privacidad. Su información personal (nombre, correo electrónico, número de teléfono y otros datos de contacto) se almacenará en sistemas de clientes seleccionados alojados principalmente en los Estados Unidos. Esta información será utilizada por las respectivas empresas afiliadas de Corteva Agriscience, socios y terceros seleccionados en otros países para proporcionarle la información solicitada sobre el producto o servicio. Para obtener más información, visite ",
        description2:
          "Al proporcionar su información personal, usted acepta los términos y condiciones de esta Declaración de privacidad.",
        url: "https://www.corteva.com.ar/politica-privacidad.html",
        urlText: "https://www.corteva.com.ar/politica-privacidad.html",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
      },
      legalRepresentative: {
        title: "Solicitud de privacidad",
        header: "Za korištenje od strane pravnog zastupnika",
        introText:
          "Si usted es un Representante Legal que presenta una solicitud de privacidad en nombre de una Persona que reside en Chile, complete el siguiente formulario.",
        endingText: "Si está enviando una solicitud para usted mismo,",
        click: "haga clic aquí",
        access: "access",
        accessDescription: "Solicitud de acceso/copia de información personal",
        deletion: "deletion",
        deletionDescription: "Eliminar información personal",
        correction: "correction",
        correctionDescription: "Corregir/Actualizar Información Personal",
        optOut: "optOut",
        optOutDescription: "Darse de baja de las comunicaciones de marketing",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription:
          "Retirar el consentimiento para el tratamiento",
        description1:
          "Corteva Agriscience™ se preocupa por su privacidad. Su información personal (nombre, correo electrónico, número de teléfono y otros datos de contacto) se almacenará en sistemas de clientes seleccionados alojados principalmente en los Estados Unidos. Esta información será utilizada por las respectivas empresas afiliadas de Corteva Agriscience, socios y terceros seleccionados en otros países para proporcionarle la información solicitada sobre el producto o servicio. Para obtener más información, visite ",
        description2:
          "Al proporcionar su información personal, usted acepta los términos y condiciones de esta Declaración de privacidad.",
        url: "https://www.corteva.com.ar/politica-privacidad.html",
        urlText: "https://www.corteva.com.ar/politica-privacidad.html",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
      },
      requestForm: {
        subjectDetails: "Por favor, seleccione el asunto de su pregunta",
        email: "Correo electrónico",
        emailPlaceholder: "Correo electrónico *",
        firstName: "Nombre",
        firstNamePlaceholder: "Nombre *",
        lastName: "Apellido",
        lastNamePlaceholder: "Apellido *",
        phone: "Teléfono",
        phonePlaceholder: "Teléfono *",
        country: "Ubicación",
        countryPlaceholder: "Seleccionar ubicación *",
        robot: "Non sono un robot",
        submitRequest: "Enviar solicitud",
        errorText: "Se requiere un número válido",
        relationshipToCorteva: "Relación con Corteva",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
        pleaseSpecify: "Molimo navedite",
        company: "Compañía",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Navedite pojedinosti o svom zahtjevu",
        legalName: "Nombre y Apellidos del Representante Legal",
        legalEmail: "Correo electrónico del representante legal",
        legalRelation:
          "Relación entre el Representante Legal y la persona a la que usted representa (Titular)",
        consumerRelation: "Relación entre el Titular de Los Datos y Corteva",
        consumerFirstName: "Nombre del interesado",
        consumerLastName: "Apellidos del interesado",
        consumerCompany: "Empresa del interesado",
        consumerPhone: "Número de teléfono del interesado",
        consumerEmail: "Correo electrónico del interesado",
        consumerCountry: "País del interesado",
        required: "Este campo es obligatorio",
        minPhone: "Se requiere un número válido",
        formUploadDes: "Subir documentación de Representante Legal",
      },
      acknowledgement: {
        title: "Confirmación",
        ackText1: "La ID de solicitud de privacidad es",
        ackText2: "Guarde una copia como registro.",
        ackText3: "",
        unsubscribe: "",
        ackText4: "",
        ackText5:
          "Compruebe su bandeja de entrada para verificar su solicitud. Si no ha recibido un correo electrónico de",
        ackText6:
          "compruebe la carpeta de spam o reenvíe su solicitud. Gracias.",
      },
      AADoc: {
        description1:
          'Un representante legal es una persona facultada para presentar una solicitud de privacidad de datos en nombre de un sujeto de datos que no sea él mismo (como un heredero o un abogado). Dichos registros son accesibles a la Persona a la que pertenecen. Para proteger la divulgación no autorizada, se requiere que proporcione: una prueba de identidad tanto para usted como para el Sujeto de datos, así como un poder notarial o una autorización por escrito del Sujeto de datos.  Si el Representante Legal es un abogado, adjunte un Poder Notarial.  Al enviar la solicitud a través de este formulario, inicie sesión en el Centro de privacidad de datos haciendo clic en el botón "Ver o gestionar su solicitud" en el correo electrónico con el asunto "Solicitud del interesado iniciada" para enviar la prueba de documentos de identidad. Los documentos de identidad del titular y del representante podrán incluir una copia de los documentos nacionales de identidad junto con la Declaración Jurada del Representante Legal.',
        heading: "Instrucciones para cargar el documento:",
        description2:
          'Inicie sesión en el Centro de privacidad de datos haciendo clic en el botón "Ver o administrar su solicitud" en el correo electrónico con el asunto "Solicitud del interesado iniciada".',
      },
      requestTypes: {
        contactReason: "Motivo de contacto",
      },
    },
  },
  au: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request on your own behalf, please complete the form below. If you are an Authorized Agent and are submitting a privacy request on behalf of an individual other than yourself, please scroll down to the bottom of the screen to select the Authorized Agent form to complete.",
        endingText:
          "If you are an Authorized Agent submitting a privacy request on someone's behalf, please",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.au/privacy-policy.html",
        urlText: "https://www.corteva.com.au/privacy-policy.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Authorized Agent",
        introText:
          "If you are an Authorized Agent submitting a privacy request on behalf of a Person residing in Australia, please complete the form below.",
        endingText: "If you are submitting a request for yourself,",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.au/privacy-policy.html",
        urlText: "https://www.corteva.com.au/privacy-policy.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        subjectDetails: "Please select the subject of your question",
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Surname",
        lastNamePlaceholder: "Surname *",
        phone: "Telephone",
        phonePlaceholder: "Telephone *",
        country: "Location",
        countryPlaceholder: "Select Location *",
        company: "Company",
        companyPlaceholder: "Company",
        robot: "I'm not a robot",
        submitRequest: "Send Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        otherRequestDetails: "Please provide details about the request",
        correctionDetails: "Please specify what needs to be updated/corrected",
        legalName: "Name and Surname of the Authorized Agent",
        legalEmail: "Authorized Agent's Email",
        legalRelation:
          "Relationship between the Authorized Agent and the person you represent (Data Subject)",
        consumerRelation: "Relationship between the Data Subject and Corteva",
        consumerFirstName: "First Name of data subject",
        consumerLastName: "Surname of the data subject",
        consumerCompany: "Company of the data subject",
        consumerPhone: "Data subject's telephone number",
        consumerEmail: "Data subject's email",
        consumerCountry: "Country of the data subject",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Authorized Agent documentation",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "An Authorized Agent is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves (such as an heir or attorney). Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: proof of identity both for yourself and the Data Subject, as well as a Power of Attorney or a written authorization from the Data Subject.",
        heading:
          "Please upload the appropriate documentation in order to submit this Privacy Request.",
        description2: "",
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
  },
  nz: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request on your own behalf, please complete the form below. If you are an Authorized Agent and are submitting a privacy request on behalf of an individual other than yourself, please scroll down to the bottom of the screen to select the Authorized Agent form to complete.",
        endingText:
          "If you are an Authorized Agent submitting a privacy request on someone's behalf, please",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.co.nz/privacy-policy.html",
        urlText: "https://www.corteva.co.nz/privacy-policy.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Authorized Agent",
        introText:
          "If you are an Authorized Agent submitting a privacy request on behalf of a Person residing in New Zealand, please complete the form below.",
        endingText: "If you are submitting a request for yourself,",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.co.nz/privacy-policy.html",
        urlText: "https://www.corteva.co.nz/privacy-policy.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        subjectDetails: "Please select the subject of your question",
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Surname",
        lastNamePlaceholder: "Surname *",
        phone: "Telephone",
        phonePlaceholder: "Telephone *",
        country: "Location",
        countryPlaceholder: "Select Location *",
        company: "Company",
        companyPlaceholder: "Company",
        robot: "I'm not a robot",
        submitRequest: "Send Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        otherRequestDetails: "Please provide details about the request",
        correctionDetails: "Please specify what needs to be updated/corrected",
        legalName: "Name and Surname of the Authorized Agent",
        legalEmail: "Authorized Agent's Email",
        legalRelation:
          "Relationship between the Authorized Agent and the person you represent (Data Subject)",
        consumerRelation: "Relationship between the Data Subject and Corteva",
        consumerFirstName: "First Name of data subject",
        consumerLastName: "Surname of the data subject",
        consumerCompany: "Company of the data subject",
        consumerPhone: "Data subject's telephone number",
        consumerEmail: "Data subject's email",
        consumerCountry: "Country of the data subject",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Authorized Agent documentation",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "An Authorized Agent is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves (such as an heir or attorney). Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: proof of identity both for yourself and the Data Subject, as well as a Power of Attorney or a written authorization from the Data Subject.",
        heading:
          "Please upload the appropriate documentation in order to submit this Privacy Request.",
        description2: "",
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
  },
  za: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request on your own behalf, please complete the form below. If you are a Legal Representative and are submitting a privacy request on behalf of an individual other than yourself, please scroll down to the bottom of the screen to select the Legal Representative form to complete.",
        endingText:
          "If you are a Legal Representative submitting a privacy request on someone's behalf, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        restrictProcessingDescription: "Restriction to processing",
        description1:
          "Corteva Agriscience cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.co.za/privacy-policy.html",
        urlText: "https://www.corteva.co.za/privacy-policy.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        cropProtection: "Corteva Crop Protection",
        pannar: "Pannar",
        pioneer: "Pioneer",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone",
        phonePlaceholder: "Phone *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        company: "Company",
        companyPlaceholder: "Company",
        robot: "I'm not a robot",
        submitRequest: "Send Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        customerApplicableBrand: "Please identify the brand",
        cropProtection: "Corteva Crop Protection",
        pannar: "Pannar",
        pioneer: "Pioneer",
        correctionDetails: "What data shall be updated?",
        retrictProcessingDetails:
          "Please explain why you are making this request",
        pleaseSpecify: "Please Specify *",
        otherRequestDetails: "Please provide details about your request",
        legalName: "Name and Surname of the Legal Representative",
        legalEmail: "Legal Representative's Email",
        legalRelation:
          "Relationship between the Legal Representative and the person you represent (Data Subject)",
        consumerRelation: "Relationship between the Data Subject and Corteva",
        consumerFirstName: "Name of data subject",
        consumerLastName: "Last name of the data subject",
        consumerCompany: "Company of the data subject",
        consumerPhone: "Data subject's telephone number",
        consumerEmail: "Data subject's email",
        consumerCountry: "Country of the data subject",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes:
          "As per the POPI Act requirements, we ask you to provide a proof of your identity, thanks to upload a copy of your ID or passport",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    af: {
      intakeForm: {
        title: "Privaatheidsversoek",
        introText:
          "Si envía una solicitud en su propio nombre, complete el siguiente formulario. Si usted es un Representante Legal y está enviando una solicitud de privacidad en nombre de una persona que no sea usted, desplácese hacia abajo hasta la parte inferior de la pantalla para seleccionar el formulario de Representante Legal para completar.",
        endingText:
          "Si usted es un representante legal que presenta una solicitud de privacidad en nombre de alguien,",
        click: "haga clic aquí",
        access: "access",
        accessDescription: "Lig my in oor gestoorde inligting",
        deletion: "deletion",
        deletionDescription:
          "Wis alle persoonlike inligting wat met my verband hou uit",
        correction: "correction",
        correctionDescription: "Dateer my persoonlike inligting op",
        optOut: "optOut",
        optOutDescription: "Teken uit op bemarkingskommunikasie",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription:
          "Retirar el consentimiento para el tratamiento",
        restrictProcessingDescription: "Beperking op verwerking",
        description1:
          "Corteva Agriscience gee om vir jou privaatheid. U persoonlike inligting (naam, e-pos, telefoonnommer en ander kontakdata) sal gestoor word in gekose kliëntstelsels wat hoofsaaklik in die Verenigde State aangebied word. Hierdie inligting sal deur besighede van Corteva Agriscience, sy affiliasies, vennote en geselekteerde derde partye in ander lande gebruik word om jou te voorsien van die produk- of diensinligting wat versoek word. Vir meer inligting, besoek asseblief ",
        description2:
          "Deur jou persoonlike inligting te verskaf, stem jy in tot die bepalings en voorwaardes van hierdie Privaatheidstelling.",
        url: "https://www.corteva.co.za/privacy-policy.html",
        urlText: "https://www.corteva.co.za/privacy-policy.html",
        customer: "Kliënt/Boer/Verspreider",
        employee: "Werknemer/Applikant/Voormalige werknemer/Kontrakteur",
        vendor: "Verskaffer",
      },
      requestForm: {
        subjectDetails: "Por favor, seleccione el asunto de su pregunta",
        email: "E-pos",
        emailPlaceholder: "E-pos *",
        firstName: "Voornaam",
        firstNamePlaceholder: "Voornaam *",
        lastName: "Van",
        lastNamePlaceholder: "Van *",
        phone: "Telefoon",
        phonePlaceholder: "Telefoon *",
        country: "Land",
        countryPlaceholder: "Land *",
        company: "Maatskappy",
        companyPlaceholder: "Maatskappy",
        robot: "Non sono un robot",
        submitRequest: "Enviar solicitud",
        errorText: "Se requiere un número válido",
        relationshipToCorteva: "Verhouding met Corteva",
        customer: "Kliënt/Boer/Verspreider",
        employee: "Werknemer/Applikant/Voormalige werknemer/Kontrakteur",
        vendor: "Verskaffer",
        customerApplicableBrand: "Identifiseer asseblief die handelsmerk",
        cropProtection: "Corteva Gewasbeskerming",
        pannar: "Pannar",
        pioneer: "Pioneer",
        correctionDetails: "Watter data sal opgedateer word?",
        retrictProcessingDetails:
          "Verduidelik asseblief waarom u hierdie versoek rig",
        pleaseSpecify: "Molimo navedite",
        otherRequestDetails: "Verskaf asseblief besonderhede oor jou versoek",
        legalName: "Nombre y Apellidos del Representante Legal",
        legalEmail: "Correo electrónico del representante legal",
        legalRelation:
          "Relación entre el Representante Legal y la persona a la que usted representa (Titular)",
        consumerRelation: "Relación entre el Titular de Los Datos y Corteva",
        consumerFirstName: "Nombre del interesado",
        consumerLastName: "Apellidos del interesado",
        consumerCompany: "Empresa del interesado",
        consumerPhone: "Número de teléfono del interesado",
        consumerEmail: "Correo electrónico del interesado",
        consumerCountry: "País del interesado",
        required: "Hierdie veld word vereis",
        minPhone: "Se requiere un número válido",
        formUploadDes:
          "Volgens die vereistes van die POPI-wet vra ons u om 'n bewys van u identiteit te lewer, danksy die oplaai van 'n afskrif van u ID of paspoort",
      },
      acknowledgement: {
        title: "Confirmación",
        ackText1: "La ID de solicitud de privacidad es",
        ackText2: "Guarde una copia como registro.",
        ackText3: "",
        unsubscribe: "",
        ackText4: "",
        ackText5:
          "Compruebe su bandeja de entrada para verificar su solicitud. Si no ha recibido un correo electrónico de",
        ackText6:
          "compruebe la carpeta de spam o reenvíe su solicitud. Gracias.",
      },
      requestTypes: {
        contactReason: "Kontak Rede",
      },
    },
  },
  ar: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request on your own behalf, please complete the form below. If you are a Legal Representative and are submitting a privacy request on behalf of an individual other than yourself, please scroll down to the bottom of the screen to select the Legal Representative form to complete.",
        endingText:
          "If you are a Legal Representative submitting a privacy request on someone's behalf, please",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.ar/politica-privacidad.html",
        urlText: "https://www.corteva.com.ar/politica-privacidad.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting a privacy request on behalf of a Person residing in Argentina, please complete the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.ar/politica-privacidad.html",
        urlText: "https://www.corteva.com.ar/politica-privacidad.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        subjectDetails: "Please select the subject of your question",
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Surname",
        lastNamePlaceholder: "Surname *",
        phone: "Telephone",
        phonePlaceholder: "Telephone *",
        country: "Location",
        countryPlaceholder: "Select Location *",
        company: "Company",
        companyPlaceholder: "Company",
        robot: "I'm not a robot",
        submitRequest: "Send Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Name and Surname of the Legal Representative",
        legalEmail: "Legal Representative's Email",
        legalRelation:
          "Relationship between the Legal Representative and the person you represent (Data Subject)",
        consumerRelation: "Relationship between the Data Subject and Corteva",
        consumerFirstName: "Name of data subject",
        consumerLastName: "Last name of the data subject",
        consumerCompany: "Company of the data subject",
        consumerPhone: "Data subject's telephone number",
        consumerEmail: "Data subject's email",
        consumerCountry: "Country of the data subject",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal Representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves (such as an heir or attorney). Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject, as well as a Power of Attorney or a written authorization from the Data Subject.  If Legal Representative is an attorney, please attach a Power of Attorney.  Upon submitting the request using this form, please login to the Data Privacy Center by clicking on the “View or manage your request” button in the email with the subject line “Data Subject Request Started” to submit the proof of identity documents. Proof of identity documents for the data subject and representative may include a copy of the national ID cards together with the Legal Representative Affidavit.",

        heading: "Instructions for uploading the document:",
        description2:
          'Log in to the Data Privacy Center by clicking the "View or Manage Your Request" button in the email with the subject line "Data Subject Request Started."',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    es: {
      intakeForm: {
        title: "Solicitud de privacidad",
        introText:
          "Si envía una solicitud en su propio nombre, complete el siguiente formulario. Si usted es un Representante Legal y está enviando una solicitud de privacidad en nombre de una persona que no sea usted, desplácese hacia abajo hasta la parte inferior de la pantalla para seleccionar el formulario de Representante Legal para completar.",
        endingText:
          "Si usted es un representante legal que presenta una solicitud de privacidad en nombre de alguien,",
        click: "haga clic aquí",
        access: "access",
        accessDescription: "Solicitud de acceso/copia de información personal",
        deletion: "deletion",
        deletionDescription: "Eliminar información personal",
        correction: "correction",
        correctionDescription: "Corregir/Actualizar Información Personal",
        optOut: "optOut",
        optOutDescription: "Darse de baja de las comunicaciones de marketing",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription:
          "Retirar el consentimiento para el tratamiento",
        description1:
          "Corteva Agriscience™ se preocupa por su privacidad. Su información personal (nombre, correo electrónico, número de teléfono y otros datos de contacto) se almacenará en sistemas de clientes seleccionados alojados principalmente en los Estados Unidos. Esta información será utilizada por las respectivas empresas afiliadas de Corteva Agriscience, socios y terceros seleccionados en otros países para proporcionarle la información solicitada sobre el producto o servicio. Para obtener más información, visite ",
        description2:
          "Al proporcionar su información personal, usted acepta los términos y condiciones de esta Declaración de privacidad.",
        url: "https://www.corteva.com.ar/politica-privacidad.html",
        urlText: "https://www.corteva.com.ar/politica-privacidad.html",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
      },
      legalRepresentative: {
        title: "Solicitud de privacidad",
        header: "Za korištenje od strane pravnog zastupnika",
        introText:
          "Si usted es un Representante Legal que presenta una solicitud de privacidad en nombre de una Persona que reside en Argentina, complete el siguiente formulario.",
        endingText: "Si está enviando una solicitud para usted mismo,",
        click: "haga clic aquí",
        access: "access",
        accessDescription: "Solicitud de acceso/copia de información personal",
        deletion: "deletion",
        deletionDescription: "Eliminar información personal",
        correction: "correction",
        correctionDescription: "Corregir/Actualizar Información Personal",
        optOut: "optOut",
        optOutDescription: "Darse de baja de las comunicaciones de marketing",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription:
          "Retirar el consentimiento para el tratamiento",
        description1:
          "Corteva Agriscience™ se preocupa por su privacidad. Su información personal (nombre, correo electrónico, número de teléfono y otros datos de contacto) se almacenará en sistemas de clientes seleccionados alojados principalmente en los Estados Unidos. Esta información será utilizada por las respectivas empresas afiliadas de Corteva Agriscience, socios y terceros seleccionados en otros países para proporcionarle la información solicitada sobre el producto o servicio. Para obtener más información, visite ",
        description2:
          "Al proporcionar su información personal, usted acepta los términos y condiciones de esta Declaración de privacidad.",
        url: "https://www.corteva.com.ar/politica-privacidad.html",
        urlText: "https://www.corteva.com.ar/politica-privacidad.html",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
      },
      requestForm: {
        subjectDetails: "Por favor, seleccione el asunto de su pregunta",
        email: "Correo electrónico",
        emailPlaceholder: "Correo electrónico *",
        firstName: "Nombre",
        firstNamePlaceholder: "Nombre *",
        lastName: "Apellido",
        lastNamePlaceholder: "Apellido *",
        phone: "Teléfono",
        phonePlaceholder: "Teléfono *",
        country: "Ubicación",
        countryPlaceholder: "Seleccionar ubicación *",
        robot: "Non sono un robot",
        submitRequest: "Enviar solicitud",
        errorText: "Se requiere un número válido",
        relationshipToCorteva: "Relación con Corteva",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
        pleaseSpecify: "Molimo navedite",
        company: "Compañía",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Navedite pojedinosti o svom zahtjevu",
        legalName: "Nombre y Apellidos del Representante Legal",
        legalEmail: "Correo electrónico del representante legal",
        legalRelation:
          "Relación entre el Representante Legal y la persona a la que usted representa (Titular)",
        consumerRelation: "Relación entre el Titular de Los Datos y Corteva",
        consumerFirstName: "Nombre del interesado",
        consumerLastName: "Apellidos del interesado",
        consumerCompany: "Empresa del interesado",
        consumerPhone: "Número de teléfono del interesado",
        consumerEmail: "Correo electrónico del interesado",
        consumerCountry: "País del interesado",
        required: "Este campo es obligatorio",
        minPhone: "Se requiere un número válido",
        formUploadDes: "Subir documentación de Representante Legal",
      },
      acknowledgement: {
        title: "Confirmación",
        ackText1: "La ID de solicitud de privacidad es",
        ackText2: "Guarde una copia como registro.",
        ackText3: "",
        unsubscribe: "",
        ackText4: "",
        ackText5:
          "Compruebe su bandeja de entrada para verificar su solicitud. Si no ha recibido un correo electrónico de",
        ackText6:
          "compruebe la carpeta de spam o reenvíe su solicitud. Gracias.",
      },
      AADoc: {
        description1:
          'Un representante legal es una persona facultada para presentar una solicitud de privacidad de datos en nombre de un sujeto de datos que no sea él mismo (como un heredero o un abogado). Dichos registros son accesibles a la Persona a la que pertenecen. Para proteger la divulgación no autorizada, se requiere que proporcione: una prueba de identidad tanto para usted como para el Sujeto de datos, así como un poder notarial o una autorización por escrito del Sujeto de datos.  Si el Representante Legal es un abogado, adjunte un Poder Notarial.  Al enviar la solicitud a través de este formulario, inicie sesión en el Centro de privacidad de datos haciendo clic en el botón "Ver o gestionar su solicitud" en el correo electrónico con el asunto "Solicitud del interesado iniciada" para enviar la prueba de documentos de identidad. Los documentos de identidad del titular y del representante podrán incluir una copia de los documentos nacionales de identidad junto con la Declaración Jurada del Representante Legal.',
        heading: "Instrucciones para cargar el documento:",
        description2:
          'Inicie sesión en el Centro de privacidad de datos haciendo clic en el botón "Ver o administrar su solicitud" en el correo electrónico con el asunto "Solicitud del interesado iniciada".',
      },
      requestTypes: {
        contactReason: "Motivo de contacto",
      },
    },
  },
  co: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request on your own behalf, please complete the form below. If you are a Legal Representative and are submitting a privacy request on behalf of an individual other than yourself, please scroll down to the bottom of the screen to select the Legal Representative form to complete.",
        endingText:
          "If you are a Legal Representative submitting a privacy request on someone's behalf, please",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.co/politica-de-privacidad.html",
        urlText: "https://www.corteva.co/politica-de-privacidad.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting a privacy request on behalf of a Person residing in Colombia, please complete the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.co/politica-de-privacidad.html",
        urlText: "https://www.corteva.co/politica-de-privacidad.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        subjectDetails: "Please select the subject of your question",
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Surname",
        lastNamePlaceholder: "Surname *",
        phone: "Telephone",
        phonePlaceholder: "Telephone *",
        country: "Location",
        countryPlaceholder: "Select Location *",
        company: "Company",
        companyPlaceholder: "Company",
        robot: "I'm not a robot",
        submitRequest: "Send Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Name and Surname of the Legal Representative",
        legalEmail: "Legal Representative's Email",
        legalRelation:
          "Relationship between the Legal Representative and the person you represent (Data Subject)",
        consumerRelation: "Relationship between the Data Subject and Corteva",
        consumerFirstName: "Name of data subject",
        consumerLastName: "Last name of the data subject",
        consumerCompany: "Company of the data subject",
        consumerPhone: "Data subject's telephone number",
        consumerEmail: "Data subject's email",
        consumerCountry: "Country of the data subject",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal Representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves (such as an heir or attorney). Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject, as well as a Power of Attorney or a written authorization from the Data Subject.  If Legal Representative is an attorney, please attach a Power of Attorney.  Upon submitting the request using this form, please login to the Data Privacy Center by clicking on the “View or manage your request” button in the email with the subject line “Data Subject Request Started” to submit the proof of identity documents. Proof of identity documents for the data subject and representative may include a copy of the national ID cards together with the Legal Representative Affidavit.",

        heading: "Instructions for uploading the document:",
        description2:
          'Log in to the Data Privacy Center by clicking the "View or Manage Your Request" button in the email with the subject line "Data Subject Request Started."',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    es: {
      intakeForm: {
        title: "Solicitud de privacidad",
        introText:
          "Si envía una solicitud en su propio nombre, complete el siguiente formulario. Si usted es un Representante Legal y está enviando una solicitud de privacidad en nombre de una persona que no sea usted, desplácese hacia abajo hasta la parte inferior de la pantalla para seleccionar el formulario de Representante Legal para completar.",
        endingText:
          "Si usted es un representante legal que presenta una solicitud de privacidad en nombre de alguien,",
        click: "haga clic aquí",
        access: "access",
        accessDescription: "Solicitud de acceso/copia de información personal",
        deletion: "deletion",
        deletionDescription: "Eliminar información personal",
        correction: "correction",
        correctionDescription: "Corregir/Actualizar Información Personal",
        optOut: "optOut",
        optOutDescription: "Darse de baja de las comunicaciones de marketing",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription:
          "Retirar el consentimiento para el tratamiento",
        description1:
          "Corteva Agriscience™ se preocupa por su privacidad. Su información personal (nombre, correo electrónico, número de teléfono y otros datos de contacto) se almacenará en sistemas de clientes seleccionados alojados principalmente en los Estados Unidos. Esta información será utilizada por las respectivas empresas afiliadas de Corteva Agriscience, socios y terceros seleccionados en otros países para proporcionarle la información solicitada sobre el producto o servicio. Para obtener más información, visite ",
        description2:
          "Al proporcionar su información personal, usted acepta los términos y condiciones de esta Declaración de privacidad.",
        url: "https://www.corteva.co/politica-de-privacidad.html",
        urlText: "https://www.corteva.co/politica-de-privacidad.html",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
      },
      legalRepresentative: {
        title: "Solicitud de privacidad",
        header: "Za korištenje od strane pravnog zastupnika",
        introText:
          "Si usted es un Representante Legal que presenta una solicitud de privacidad en nombre de una Persona que reside en Colombia, complete el siguiente formulario.",
        endingText: "Si está enviando una solicitud para usted mismo,",
        click: "haga clic aquí",
        access: "access",
        accessDescription: "Solicitud de acceso/copia de información personal",
        deletion: "deletion",
        deletionDescription: "Eliminar información personal",
        correction: "correction",
        correctionDescription: "Corregir/Actualizar Información Personal",
        optOut: "optOut",
        optOutDescription: "Darse de baja de las comunicaciones de marketing",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription:
          "Retirar el consentimiento para el tratamiento",
        description1:
          "Corteva Agriscience™ se preocupa por su privacidad. Su información personal (nombre, correo electrónico, número de teléfono y otros datos de contacto) se almacenará en sistemas de clientes seleccionados alojados principalmente en los Estados Unidos. Esta información será utilizada por las respectivas empresas afiliadas de Corteva Agriscience, socios y terceros seleccionados en otros países para proporcionarle la información solicitada sobre el producto o servicio. Para obtener más información, visite ",
        description2:
          "Al proporcionar su información personal, usted acepta los términos y condiciones de esta Declaración de privacidad.",
        url: "https://www.corteva.co/politica-de-privacidad.html",
        urlText: "https://www.corteva.co/politica-de-privacidad.html",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
      },
      requestForm: {
        subjectDetails: "Por favor, seleccione el asunto de su pregunta",
        email: "Correo electrónico",
        emailPlaceholder: "Correo electrónico *",
        firstName: "Nombre",
        firstNamePlaceholder: "Nombre *",
        lastName: "Apellido",
        lastNamePlaceholder: "Apellido *",
        phone: "Teléfono",
        phonePlaceholder: "Teléfono *",
        country: "Ubicación",
        countryPlaceholder: "Seleccionar ubicación *",
        robot: "Non sono un robot",
        submitRequest: "Enviar solicitud",
        errorText: "Potreban je važeći broj",
        relationshipToCorteva: "Relación con Corteva",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
        pleaseSpecify: "Molimo navedite",
        company: "Compañía",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Navedite pojedinosti o svom zahtjevu",
        legalName: "Nombre y Apellidos del Representante Legal",
        legalEmail: "Correo electrónico del representante legal",
        legalRelation:
          "Relación entre el Representante Legal y la persona a la que usted representa (Titular)",
        consumerRelation: "Relación entre el Titular de Los Datos y Corteva",
        consumerFirstName: "Nombre del interesado",
        consumerLastName: "Apellidos del interesado",
        consumerCompany: "Empresa del interesado",
        consumerPhone: "Número de teléfono del interesado",
        consumerEmail: "Correo electrónico del interesado",
        consumerCountry: "País del interesado",
        required: "Este campo es obligatorio",
        minPhone: "Se requiere un número válido",
        formUploadDes: "Subir documentación de Representante Legal",
      },
      acknowledgement: {
        title: "Confirmación",
        ackText1: "La ID de solicitud de privacidad es",
        ackText2: "Guarde una copia como registro.",
        ackText3: "",
        unsubscribe: "",
        ackText4: "",
        ackText5:
          "Compruebe su bandeja de entrada para verificar su solicitud. Si no ha recibido un correo electrónico de",
        ackText6:
          "compruebe la carpeta de spam o reenvíe su solicitud. Gracias.",
      },
      AADoc: {
        description1:
          'Un representante legal es una persona facultada para presentar una solicitud de privacidad de datos en nombre de un sujeto de datos que no sea él mismo (como un heredero o un abogado). Dichos registros son accesibles a la Persona a la que pertenecen. Para proteger la divulgación no autorizada, se requiere que proporcione: una prueba de identidad tanto para usted como para el Sujeto de datos, así como un poder notarial o una autorización por escrito del Sujeto de datos.  Si el Representante Legal es un abogado, adjunte un Poder Notarial.  Al enviar la solicitud a través de este formulario, inicie sesión en el Centro de privacidad de datos haciendo clic en el botón "Ver o gestionar su solicitud" en el correo electrónico con el asunto "Solicitud del interesado iniciada" para enviar la prueba de documentos de identidad. Los documentos de identidad del titular y del representante podrán incluir una copia de los documentos nacionales de identidad junto con la Declaración Jurada del Representante Legal.',
        heading: "Instrucciones para cargar el documento:",
        description2:
          'Inicie sesión en el Centro de privacidad de datos haciendo clic en el botón "Ver o administrar su solicitud" en el correo electrónico con el asunto "Solicitud del interesado iniciada".',
      },
      requestTypes: {
        contactReason: "Motivo de contacto",
      },
    },
  },
  mx: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request on your own behalf, please complete the form below. If you are a Legal Representative and are submitting a privacy request on behalf of an individual other than yourself, please scroll down to the bottom of the screen to select the Legal Representative form to complete.",
        endingText:
          "If you are a Legal Representative submitting a privacy request on someone's behalf, please",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.mx/politica-de-privacidad.html",
        urlText: "https://www.corteva.mx/politica-de-privacidad.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting a privacy request on behalf of a Person residing in Mexico, please complete the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Request for Access/Copy of Personal Information",
        deletion: "deletion",
        deletionDescription: "Delete Personal Information",
        correction: "correction",
        correctionDescription: "Correct/Update Personal Information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from Marketing Communications",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription: "Withdraw Consent for Processing",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, telephone number and other contact details) will be stored on selected customer systems hosted primarily in the United States. This information will be used by Corteva Agriscience’s respective affiliated companies, partners and selected third parties in other countries to provide you with the requested product or service information. For more information, visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.mx/politica-de-privacidad.html",
        urlText: "https://www.corteva.mx/politica-de-privacidad.html",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        subjectDetails: "Please select the subject of your question",
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Surname",
        lastNamePlaceholder: "Surname *",
        phone: "Telephone",
        phonePlaceholder: "Telephone *",
        country: "Location",
        countryPlaceholder: "Select Location *",
        company: "Company",
        companyPlaceholder: "Company",
        robot: "I'm not a robot",
        submitRequest: "Send Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Name and Surname of the Legal Representative",
        legalEmail: "Legal Representative's Email",
        legalRelation:
          "Relationship between the Legal Representative and the person you represent (Data Subject)",
        consumerRelation: "Relationship between the Data Subject and Corteva",
        consumerFirstName: "Name of data subject",
        consumerLastName: "Last name of the data subject",
        consumerCompany: "Company of the data subject",
        consumerPhone: "Data subject's telephone number",
        consumerEmail: "Data subject's email",
        consumerCountry: "Country of the data subject",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal Representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves (such as an heir or attorney). Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject, as well as a Power of Attorney or a written authorization from the Data Subject.  If Legal Representative is an attorney, please attach a Power of Attorney.  Upon submitting the request using this form, please login to the Data Privacy Center by clicking on the “View or manage your request” button in the email with the subject line “Data Subject Request Started” to submit the proof of identity documents. Proof of identity documents for the data subject and representative may include a copy of the national ID cards together with the Legal Representative Affidavit.",

        heading: "Instructions for uploading the document:",
        description2:
          'Log in to the Data Privacy Center by clicking the "View or Manage Your Request" button in the email with the subject line "Data Subject Request Started."',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    es: {
      intakeForm: {
        title: "Solicitud de privacidad",
        introText:
          "Si envía una solicitud en su propio nombre, complete el siguiente formulario. Si usted es un Representante Legal y está enviando una solicitud de privacidad en nombre de una persona que no sea usted, desplácese hacia abajo hasta la parte inferior de la pantalla para seleccionar el formulario de Representante Legal para completar.",
        endingText:
          "Si usted es un representante legal que presenta una solicitud de privacidad en nombre de alguien,",
        click: "haga clic aquí",
        access: "access",
        accessDescription: "Solicitud de acceso/copia de información personal",
        deletion: "deletion",
        deletionDescription: "Eliminar información personal",
        correction: "correction",
        correctionDescription: "Corregir/Actualizar Información Personal",
        optOut: "optOut",
        optOutDescription: "Darse de baja de las comunicaciones de marketing",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription:
          "Retirar el consentimiento para el tratamiento",
        description1:
          "Corteva Agriscience™ se preocupa por su privacidad. Su información personal (nombre, correo electrónico, número de teléfono y otros datos de contacto) se almacenará en sistemas de clientes seleccionados alojados principalmente en los Estados Unidos. Esta información será utilizada por las respectivas empresas afiliadas de Corteva Agriscience, socios y terceros seleccionados en otros países para proporcionarle la información solicitada sobre el producto o servicio. Para obtener más información, visite ",
        description2:
          "Al proporcionar su información personal, usted acepta los términos y condiciones de esta Declaración de privacidad.",
        url: "https://www.corteva.mx/politica-de-privacidad.html",
        urlText: "https://www.corteva.mx/politica-de-privacidad.html",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
      },
      legalRepresentative: {
        title: "Solicitud de privacidad",
        header: "Za korištenje od strane pravnog zastupnika",
        introText:
          "Si usted es un Representante Legal que presenta una solicitud de privacidad en nombre de una Persona que reside en Mexico, complete el siguiente formulario.",
        endingText: "Si está enviando una solicitud para usted mismo,",
        click: "haga clic aquí",
        access: "access",
        accessDescription: "Solicitud de acceso/copia de información personal",
        deletion: "deletion",
        deletionDescription: "Eliminar información personal",
        correction: "correction",
        correctionDescription: "Corregir/Actualizar Información Personal",
        optOut: "optOut",
        optOutDescription: "Darse de baja de las comunicaciones de marketing",
        withdrawConsent: "withdraw-consent",
        withdrawConsentDescription:
          "Retirar el consentimiento para el tratamiento",
        description1:
          "Corteva Agriscience™ se preocupa por su privacidad. Su información personal (nombre, correo electrónico, número de teléfono y otros datos de contacto) se almacenará en sistemas de clientes seleccionados alojados principalmente en los Estados Unidos. Esta información será utilizada por las respectivas empresas afiliadas de Corteva Agriscience, socios y terceros seleccionados en otros países para proporcionarle la información solicitada sobre el producto o servicio. Para obtener más información, visite ",
        description2:
          "Al proporcionar su información personal, usted acepta los términos y condiciones de esta Declaración de privacidad.",
        url: "https://www.corteva.mx/politica-de-privacidad.html",
        urlText: "https://www.corteva.mx/politica-de-privacidad.html",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
      },
      requestForm: {
        subjectDetails: "Por favor, seleccione el asunto de su pregunta",
        email: "Correo electrónico",
        emailPlaceholder: "Correo electrónico *",
        firstName: "Nombre",
        firstNamePlaceholder: "Nombre *",
        lastName: "Apellido",
        lastNamePlaceholder: "Apellido *",
        phone: "Teléfono",
        phonePlaceholder: "Teléfono *",
        country: "Ubicación",
        countryPlaceholder: "Seleccionar ubicación *",
        robot: "Non sono un robot",
        submitRequest: "Enviar solicitud",
        errorText: "Potreban je važeći broj",
        relationshipToCorteva: "Relación con Corteva",
        customer: "Cliente/Agricultor/Minorista/Distribuidor",
        employee: "Empleado/Solicitante/Ex empleado/Contratista",
        vendor: "Vendedor",
        pleaseSpecify: "Molimo navedite",
        company: "Compañía",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Navedite pojedinosti o svom zahtjevu",
        legalName: "Nombre y Apellidos del Representante Legal",
        legalEmail: "Correo electrónico del representante legal",
        legalRelation:
          "Relación entre el Representante Legal y la persona a la que usted representa (Titular)",
        consumerRelation: "Relación entre el Titular de Los Datos y Corteva",
        consumerFirstName: "Nombre del interesado",
        consumerLastName: "Apellidos del interesado",
        consumerCompany: "Empresa del interesado",
        consumerPhone: "Número de teléfono del interesado",
        consumerEmail: "Correo electrónico del interesado",
        consumerCountry: "País del interesado",
        required: "Este campo es obligatorio",
        minPhone: "Se requiere un número válido",
        formUploadDes: "Subir documentación de Representante Legal",
      },
      acknowledgement: {
        title: "Confirmación",
        ackText1: "La ID de solicitud de privacidad es",
        ackText2: "Guarde una copia como registro.",
        ackText3: "",
        unsubscribe: "",
        ackText4: "",
        ackText5:
          "Compruebe su bandeja de entrada para verificar su solicitud. Si no ha recibido un correo electrónico de",
        ackText6:
          "compruebe la carpeta de spam o reenvíe su solicitud. Gracias.",
      },
      AADoc: {
        description1:
          'Un representante legal es una persona facultada para presentar una solicitud de privacidad de datos en nombre de un sujeto de datos que no sea él mismo (como un heredero o un abogado). Dichos registros son accesibles a la Persona a la que pertenecen. Para proteger la divulgación no autorizada, se requiere que proporcione: una prueba de identidad tanto para usted como para el Sujeto de datos, así como un poder notarial o una autorización por escrito del Sujeto de datos.  Si el Representante Legal es un abogado, adjunte un Poder Notarial.  Al enviar la solicitud a través de este formulario, inicie sesión en el Centro de privacidad de datos haciendo clic en el botón "Ver o gestionar su solicitud" en el correo electrónico con el asunto "Solicitud del interesado iniciada" para enviar la prueba de documentos de identidad. Los documentos de identidad del titular y del representante podrán incluir una copia de los documentos nacionales de identidad junto con la Declaración Jurada del Representante Legal.',
        heading: "Instrucciones para cargar el documento:",
        description2:
          'Inicie sesión en el Centro de privacidad de datos haciendo clic en el botón "Ver o administrar su solicitud" en el correo electrónico con el asunto "Solicitud del interesado iniciada".',
      },
      requestTypes: {
        contactReason: "Motivo de contacto",
      },
    },
  },
  be: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        deleteMyCortevaAccountDescription: "Delete my Corteva user account",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "www.corteva.be/nl/privacy.html",
        urlText: "www.corteva.be/nl/privacy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        deleteMyCortevaAccountDescription: "Delete my Corteva user account",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "www.corteva.be/nl/privacy.html",
        urlText: "www.corteva.be/nl/privacy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    fr_be: {
      intakeForm: {
        title: "Demande de confidentialité",
        introText:
          "Si vous soumettez une demande pour vous-même, renseignez le formulaire ci-dessous. Si vous êtes un représentant légal, cliquez sur le bouton au bas de l'écran pour accéder au formulaire destiné aux représentants légaux.",
        endingText:
          "Si vous êtes un représentant légal soumettant la demande de confidentialité pour le compte d'une personne,",
        click: "cliquez ici",
        access: "access",
        accessDescription: "Me renseigner sur les informations stockées",
        deletion: "deletion",
        deletionDescription:
          "Supprimer toutes les informations personnelles me concernant",
        correction: "correction",
        correctionDescription: "Mettre à jour mes données personnelles",
        optOut: "optOut",
        optOutDescription: "Se désabonner des communications marketing",
        other: "other",
        otherDescription: "Autres - décrivez ci-dessous",
        deleteMyCortevaAccountDescription:
          "Supprimer mon compte Place Des Agriculteurs",
        description1:
          "Corteva Agriscience™ attache une grande importance à la confidentialité de vos données.  Vos données à caractère personnel (nom, adresse email, numéro de téléphone et autres coordonnées) seront conservées dans des systèmes clients que nous avons sélectionnés et qui sont principalement hébergés aux États-Unis.  Ces informations seront utilisées par Corteva Agriscience™, ses filiales, ses partenaires et des tiers triés sur le volet établis dans d'autres pays, dans le but de répondre à vos demandes d'information sur des produits ou des services.  Pour en savoir plus, rendez-vous sur le site ",
        description2:
          "En fournissant vos données personnelles, vous acceptez les termes et conditions de cette déclaration de confidentialité.",
        url: "https://www.corteva.be/fr/politique-de-confidentialite.html",
        urlText: "https://www.corteva.be/fr/politique-de-confidentialite.html",
        customer: "Client/Agriculteur/Distributeur",
        employee: "Employé/Candidat/Ancien employé/Prestataire",
        vendor: "Fournisseur",
      },
      legalRepresentative: {
        title: "Demande de confidentialité",
        header: "À l'usage du représentant légal",
        introText:
          "Si vous êtes un représentant légal soumettant la demande de confidentialité pour le compte d'une personne, renseignez le formulaire ci-dessous.",
        endingText: "Si vous soumettez une demande pour vous-même,",
        click: "cliquez ici",
        access: "access",
        accessDescription: "Me renseigner sur les informations stockées",
        deletion: "deletion",
        deletionDescription:
          "Supprimer toutes les informations personnelles me concernant",
        correction: "correction",
        correctionDescription: "Mettre à jour mes données personnelles",
        optOut: "optOut",
        optOutDescription: "Se désabonner des communications marketing",
        other: "other",
        otherDescription: "Autres - décrivez ci-dessous",
        deleteMyCortevaAccountDescription:
          "Supprimer mon compte Place Des Agriculteurs",
        description1:
          "Corteva Agriscience™ attache une grande importance à la confidentialité de vos données.  Vos données à caractère personnel (nom, adresse email, numéro de téléphone et autres coordonnées) seront conservées dans des systèmes clients que nous avons sélectionnés et qui sont principalement hébergés aux États-Unis.  Ces informations seront utilisées par Corteva Agriscience™, ses filiales, ses partenaires et des tiers triés sur le volet établis dans d'autres pays, dans le but de répondre à vos demandes d'information sur des produits ou des services.  Pour en savoir plus, rendez-vous sur le site ",
        description2:
          "En fournissant vos données personnelles, vous acceptez les termes et conditions de cette déclaration de confidentialité.",
        url: "https://www.corteva.be/fr/politique-de-confidentialite.html",
        urlText: "https://www.corteva.be/fr/politique-de-confidentialite.html",
        customer: "Client/Agriculteur/Distributeur",
        employee: "Employé/Candidat/Ancien employé/Prestataire",
        vendor: "Fournisseur",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Имейл *",
        firstName: "Prénom",
        firstNamePlaceholder: "Nome *",
        lastName: "Nom de famille",
        lastNamePlaceholder: "Cognome *",
        phone: "Téléphone",
        phonePlaceholder: "Телефон *",
        country: "Pays",
        countryPlaceholder: "Sélectionner Pays *",
        robot: "Non sono un robot",
        submitRequest: "Soumettre une demande",
        errorText: "Un numéro valide est requis",
        relationshipToCorteva: "Relation avec Corteva",
        customer: "Client/Agriculteur/Distributeur",
        employee: "Employé/Candidat/Ancien employé/Prestataire",
        vendor: "Fournisseur",
        pleaseSpecify: "Veuillez préciser *",
        company: "Entreprise",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Veuillez fournir des détails sur votre demande",
        legalName: "Prénom et nom du représentant légal",
        legalEmail: "E-mail du représentant légal",
        legalRelation:
          "Lien entre le représentant légal et la personne représentée (personne concernée)",
        consumerRelation: "Lien entre la personne concernée et Corteva:",
        consumerFirstName: "Prénom de la personne concernée",
        consumerLastName: "Nom de la personne concernée",
        consumerCompany: "Société de la personne concernée",
        consumerPhone: "Téléphone de la personne concernée",
        consumerEmail: "E-mail de la personne concernée",
        consumerCountry: "Pays de la personne concernée",
        required: "Ce champ est requis",
        minPhone: "Un numéro valide est requis",
        formUploadDes: "Télécharger la documentation du représentant légal",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Votre ID de demande de confidentialité est",
        ackText2: "Conservez-en une copie dans vos archives.",
        ackText3:
          "Un lien de vérification sera envoyé à l’adresse e-mail indiquée pour les demandes de tous types à l’exception des demandes",
        unsubscribe: "de désabonnement",
        ackText4: "",
        ackText5:
          "Consultez votre boîte de réception pour vérifier votre demande. Si vous n’avez reçu aucun e-mail de",
        ackText6:
          "vérifiez votre dossier de spam ou soumettez une nouvelle demande. Merci.",
      },
      AADoc: {
        description1:
          "Un représentant légal est une personne habilitée à soumettre une demande de confidentialité des données pour le compte d'une personne concernée autre qu'elle-même. Ces données sont accessibles à la personne à laquelle elles se rapportent. Pour éviter toute divulgation non autorisée, il est nécessaire de fournir une preuve d'identité pour vous-même et la personne concernée que vous représentez, ainsi qu'une procuration ou une autorisation écrite de la personne concernée que vous représentez.",
        heading: "Instructions pour télécharger le document:",
        description2:
          "\"Connectez-vous au centre de confidentialité des données en cliquant sur le bouton Visualiser ou gérer votre demande dans l'e-mail dont l'objet est Début de la demande d’accès de la personne concernée.\"",
      },
      requestTypes: {
        contactReason: "Objet de la demande",
      },
    },
    de: {
      intakeForm: {
        title: "Datenschutzanfrage",
        introText:
          "Wenn Sie einen Antrag für sich selbst stellen möchten, füllen Sie bitte das untenstehende Formular aus. Wenn Sie ein gesetzlicher Vertreter sind, klicken Sie bitte auf die Schaltfläche am unteren Rand des Bildschirms, um das Formular für gesetzliche Vertreter aufzurufen.",
        endingText:
          "Wenn Sie ein gesetzlicher Vertreter sind, der den Antrag auf Datenschutz im Namen einer Person stellt,",
        click: "klicken Sie bitte hier",
        accessDescription: "Mich über die gespeicherten Angaben informieren",
        deletionDescription:
          "Meine sämtlichen personenbezogenen Informationen löschen",
        correctionDescription:
          "Meine personenbezogenen Informationen aktualisieren",
        optOutDescription: "Abmeldung von werblicher Kommunikation",
        otherDescription: "Sonstiges – Nachstehend beschreiben",
        description1:
          "Corteva Agriscience, kümmert sich um Ihren Datenschutz.  Ihre personenbezogenen Informationen (Name, e-Mail-Adresse, Telefonnummer sowie weitere Kontaktdaten) werden in ausgewählten Kundensystemen gespeichert, die im Wesentlichen in den Vereinigten Staaten gehostet sind. Diese Informationen werden von Unternehmen von Corteva Agriscience,  ihren Tochtergesellschaften, Partnerunternehmen und von ausgewählten Dritten in anderen Ländern verwendet, um Ihnen die angeforderten Produkt- oder Serviceinformationen zu liefern.  Weitere Informationen finden Sie auf der folgenden Website: ",
        description2:
          "Durch die Angabe Ihrer personenbezogenen Daten erklären Sie sich mit den Bedingungen und Konditionen dieser Datenschutzerklärung einverstanden.",
        url: "https://www.corteva.be/de/datenschutzerklarung.html",
        urlText: "https://www.corteva.be/de/datenschutzerklarung.html",
        customer: "Kunde/Bauer/Verteiler",
        employee: "Mitarbeiter/Bewerber/Ehemaliger Mitarbeiter/Auftragnehmer",
        vendor: "Lieferant",
      },
      legalRepresentative: {
        title: "Datenschutzanfrage",
        header: "Zur Verwendung durch den gesetzlichen Vertreter",
        introText:
          "Wenn Sie ein gesetzlicher Vertreter sind, der den Antrag auf Datenschutz im Namen einer Person stellt, füllen Sie bitte das nachstehende Formular aus.",
        endingText: "Wenn Sie einen Antrag für sich selbst stellen möchten,",
        click: "klicken Sie bitte hier",
        accessDescription: "Mich über die gespeicherten Angaben informieren",
        deletionDescription:
          "Meine sämtlichen personenbezogenen Informationen löschen",
        correctionDescription:
          "Meine personenbezogenen Informationen aktualisieren",
        optOutDescription: "Abmeldung von werblicher Kommunikation",
        otherDescription: "Sonstiges – Nachstehend beschreiben",
        description1:
          "Corteva Agriscience, kümmert sich um Ihren Datenschutz.  Ihre personenbezogenen Informationen (Name, e-Mail-Adresse, Telefonnummer sowie weitere Kontaktdaten) werden in ausgewählten Kundensystemen gespeichert, die im Wesentlichen in den Vereinigten Staaten gehostet sind. Diese Informationen werden von Unternehmen von Corteva Agriscience,  ihren Tochtergesellschaften, Partnerunternehmen und von ausgewählten Dritten in anderen Ländern verwendet, um Ihnen die angeforderten Produkt- oder Serviceinformationen zu liefern.  Weitere Informationen finden Sie auf der folgenden Website: ",
        description2:
          "Durch die Angabe Ihrer personenbezogenen Daten erklären Sie sich mit den Bedingungen und Konditionen dieser Datenschutzerklärung einverstanden.",
        url: "https://www.corteva.be/de/datenschutzerklarung.html",
        urlText: "https://www.corteva.be/de/datenschutzerklarung.html",
        customer: "Kunde/Bauer/Verteiler",
        employee: "Mitarbeiter/Bewerber/Ehemaliger Mitarbeiter/Auftragnehmer",
        vendor: "Lieferant",
      },
      requestForm: {
        email: "e-Mail-Adresse",
        emailPlaceholder: "E-mail *",
        firstName: "Vorname",
        lastName: "Nachname",
        phone: "Telefonnummer",
        country: "Standort",
        countryPlaceholder: "Auswählen Standort *",
        robot: "eu não sou um robô",
        submitRequest: "Antrag einreichen",
        errorText: "Eine gültige Nummer ist erforderlich",
        relationshipToCorteva: "Beziehung zu Corteva",
        customer: "Kunde/Bauer/Verteiler",
        employee: "Mitarbeiter/Bewerber/Ehemaliger Mitarbeiter/Auftragnehmer",
        vendor: "Lieferant",
        pleaseSpecify: "Bitte angeben *",
        company: "Firma",
        otherRequestDetails: "Bitte geben Sie Einzelheiten zu Ihrer Anfrage an",
        legalName: "Vorname und Nachname des gesetzlichen Vertreters",
        legalEmail: "E-Mail des gesetzlichen Vertreters",
        legalRelation:
          "Beziehung des gesetzlichen Vertreters zu der Person, die Sie vertreten (betroffene Person)",
        consumerRelation: "Beziehung der betroffenen Person zu Corteva:",
        consumerFirstName: "Vorname der betroffenen Person",
        consumerLastName: "Nachname der betroffenen Person",
        consumerCompany: "Unternehmen der betroffenen Person",
        consumerPhone: "Telefonnummer der betroffenen Person",
        consumerEmail: "E-Mail-Adresse der betroffenen Person",
        consumerCountry: "Land der der betroffenen Person",
        required: "Dieses Feld ist erforderlich",
        minPhone: "Eine gültige Nummer ist erforderlich",
        formUploadDes: "Hochladen von Dokumenten des gesetzlichen Vertreters",
      },
      acknowledgement: {
        title: "Bestätigung:",
        ackText1: "Die ID Ihres Datenschutzantrags lautet",
        ackText2: "Bitte bewahren Sie eine Kopie für Ihre Unterlagen auf.",
        ackText3: "Für alle Antragsarten außer ",
        unsubscribe: "Abbestellungen",
        ackText4:
          "wird ein Bestätigungslink an die von Ihnen angegebene E-Mail-Adresse gesendet.",
        ackText5:
          "Bitte überprüfen Sie Ihren Posteingang, um Ihren Antrag zu bestätigen. Wenn Sie keine E-Mail von",
        ackText6:
          "erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner oder senden Sie Ihren Antrag erneut. Herzlichen Dank.",
      },
      AADoc: {
        description1:
          "Ein gesetzlicher Vertreter ist eine Person, die befugt ist, einen Datenschutzantrag im Namen einer betroffenen Person zu stellen, die nicht sie selbst ist. Diese Aufzeichnungen sind für die Person zugänglich, auf die sie sich beziehen. Zum Schutz vor unbefugter Weitergabe müssen Sie Folgendes vorlegen: einen Identitätsnachweis sowohl für sich selbst als auch für die betroffene Person, die Sie vertreten, sowie eine Vollmacht oder eine schriftliche Genehmigung der betroffenen Person, die Sie vertreten.",
        heading: "Anweisungen zum Hochladen des Dokuments:",
        description2:
          "Bitte loggen Sie sich in das Datenschutz-Center ein, indem Sie auf die Schaltfläche „Ihre Anfrage anzeigen bzw. verwalten“ in der E-Mail mit der Betreffzeile „Datenschutzanfrage gestartet“ klicken.",
      },
      requestTypes: {
        contactReason: "Grund Ihrer Kontaktaufnahme",
      },
    },
    nl_be: {
      intakeForm: {
        title: "Privacyverzoek",
        introText:
          "Als u een verzoek voor uzelf indient, vul dan onderstaand formulier in. Als u een Wettelijke vertegenwoordiger bent, klik dan op de knop onderaan het scherm om naar het formulier voor Wettelijke vertegenwoordigers te gaan.",
        endingText:
          "Als u een Wettelijke vertegenwoordiger bent die het privacyverzoek namens iemand anders indient,",
        click: "klik dan hier",
        access: "access",
        accessDescription: "Informeer mij",
        deletion: "deletion",
        deletionDescription: "Verwijder mijn personlijke informatie",
        correction: "correction",
        correctionDescription: "Persoonlijke informatie aanpassen",
        optOut: "optOut",
        optOutDescription: "Uitschrijven uit marketingcommunicatie",
        other: "other",
        otherDescription:
          "Andere – omschrijf uw vraag bij ‘Omschrijf’ uw vraag",
        description1:
          "Corteva Agriscience™ hecht veel belang aan uw privacy. Uw persoonlijke gegevens (naam, adres, email, telefoonnummer en andere gegevens) zullen opgeslagen worden in klantensystemen die door ons gekozen werden en die voornamelijk gehost zijn in de VS. Deze informatie zal gebruikt worden door Corteva Agriscience™, zijn filialen, partners en gekozen third parties in andere landen – met als doel u van de juiste productinformatie of service te voorzien. Ga voor meer informatie naar ",
        description2:
          "Door persoonsgegevens te verstrekken, accepteert u de voorwaarden van dit privacybeleid.",
        url: "https://www.corteva.be/nl/privacy.html",
        urlText: "https://www.corteva.be/nl/privacy.html",
        customer: "Klant/Boer/Verdeler",
        employee: "Werknemer/Sollicitant/Voormalig medewerker/Aannemer",
        vendor: "Verkoper",
      },
      legalRepresentative: {
        title: "Privacyverzoek",
        header: "Voor gebruik door wettelijke vertegenwoordiger",
        introText:
          "Als u een Wettelijke vertegenwoordiger bent die het privacyverzoek namens iemand anders indient, vul dan onderstaand formulier in.",
        endingText: "Als u een verzoek voor uzelf indient,",
        click: "klik dan hier",
        access: "access",
        accessDescription: "Informeer mij",
        deletion: "deletion",
        deletionDescription: "Verwijder mijn personlijke informatie",
        correction: "correction",
        correctionDescription: "Persoonlijke informatie aanpassen",
        optOut: "optOut",
        optOutDescription: "Uitschrijven uit marketingcommunicatie",
        other: "other",
        otherDescription:
          "Andere – omschrijf uw vraag bij ‘Omschrijf’ uw vraag",
        description1:
          "Corteva Agriscience™ hecht veel belang aan uw privacy. Uw persoonlijke gegevens (naam, adres, email, telefoonnummer en andere gegevens) zullen opgeslagen worden in klantensystemen die door ons gekozen werden en die voornamelijk gehost zijn in de VS. Deze informatie zal gebruikt worden door Corteva Agriscience™, zijn filialen, partners en gekozen third parties in andere landen – met als doel u van de juiste productinformatie of service te voorzien. Ga voor meer informatie naar ",
        description2:
          "Door persoonsgegevens te verstrekken, accepteert u de voorwaarden van dit privacybeleid.",
        url: "https://www.corteva.be/nl/privacy.html",
        urlText: "https://www.corteva.be/nl/privacy.html",
        customer: "Klant/Boer/Verdeler",
        employee: "Werknemer/Sollicitant/Voormalig medewerker/Aannemer",
        vendor: "Verkoper",
      },
      requestForm: {
        email: "E-mail",
        emailPlaceholder: "E-mail *",
        firstName: "Voornaam",
        firstNamePlaceholder: "Nome *",
        lastName: "Achternaam",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefoon",
        phonePlaceholder: "Telefono *",
        country: "Plaats",
        countryPlaceholder: "Selecteer Plaats *",
        robot: "Non sono un robot",
        submitRequest: "Verzoek indienen",
        errorText: "Een geldig nummer is vereist",
        relationshipToCorteva: "Relatie met Corteva",
        customer: "Klant/Boer/Verdeler",
        employee: "Werknemer/Sollicitant/Voormalig medewerker/Aannemer",
        vendor: "Verkoper",
        pleaseSpecify: "Gelieve te specificeren",
        company: "Bedrijf",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Omschrijf uw vraag",
        legalName: "Voornaam en achternaam van Wettelijke vertegenwoordiger",
        legalEmail: "E-mailadres van Wettelijke vertegenwoordiger",
        legalRelation:
          "Relatie van de Wettelijk vertegenwoordiger met degene die u vertegenwoordigt (Betrokkene)",
        consumerRelation: "Relatie tussen Betrokkene en Corteva:",
        consumerFirstName: "Voornaam van Betrokkene",
        consumerLastName: "Achternaam van Betrokkene",
        consumerCompany: "Bedrijf van Betrokkene",
        consumerPhone: "Telefoonnummer van Betrokkene",
        consumerEmail: "E-mailadres van Betrokkene",
        consumerCountry: "Land van Betrokkene",
        required: "dit veld is verplicht",
        minPhone: "Een geldig nummer is vereist",
        formUploadDes:
          "Documentatie van wettelijke vertegenwoordigers uploaden",
      },
      acknowledgement: {
        title: "Bevestiging",
        ackText1: "Uw Privacyverzoek-id is",
        ackText2: "Bewaar een kopie voor uw administratie.",
        ackText3: "Voor alle aanvragen, behalve voor",
        unsubscribe: "afmeldingen,",
        ackText4:
          "wordt een verificatiekoppeling verzonden naar het door u opgegeven e-mailadres",
        ackText5:
          "Ga naar uw Postvak IN om uw aanvraag te verifiëren. Als u geen e-mail hebt ontvangen van",
        ackText6:
          "controleer dan uw map met ongewenste berichten of dien uw verzoek opnieuw in. Bedankt.",
      },
      AADoc: {
        description1:
          "Een Wettelijke vertegenwoordiger is iemand die gemachtigd is om namens een andere Betrokkene dan zichzelf een verzoek om gegevensbescherming in te dienen. Deze dossiers zijn toegankelijk voor de Persoon op wie deze betrekking hebben. Om onbevoegde openbaarmaking te verhinderen, moet u het volgende verstrekken: een identiteitsbewijs van uzelf en de Betrokkene die u vertegenwoordigt, evenals een Volmacht of een schriftelijke machtiging van de Betrokkene die u vertegenwoordigt.",
        heading: "Instructies voor het uploaden van het document:",
        description2:
          "Meld u aan bij het Centrum voor gegevensprivacy door te klikken op de knop 'Uw verzoek bekijken of beheren' in de e-mail met als onderwerp \"Verzoek door een betrokkene gestart\".",
      },
      requestTypes: {
        contactReason: "Uw vraag",
      },
    },
  },
  bg: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.bg/politika-za-poveritelnost.html",
        urlText: "https://www.corteva.bg/politika-za-poveritelnost.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.bg/politika-za-poveritelnost.html",
        urlText: "https://www.corteva.bg/politika-za-poveritelnost.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    bg: {
      intakeForm: {
        title: "Заявка за поверителност",
        introText:
          "Ако подавате заявка за себе си, моля, попълнете формуляра по-долу. Ако сте законен представител, моля, кликнете върху бутона в долната част на екрана, за да преминете към формуляра за законни представители.",
        endingText:
          "Ако сте законен представител, който подава искането за поверителност от името на дадено лице, моля,",
        click: "кликнете тук",
        access: "access",
        accessDescription: "Информирайте ме за съхраняваната информация",
        deletion: "deletion",
        deletionDescription: "Изтрийте цялата лична информация за мен",
        correction: "correction",
        correctionDescription: "Обновeте моята лична информация",
        optOut: "optOut",
        optOutDescription: "Отпишете ме от маркетинг комуникациите",
        other: "other",
        otherDescription: "Друго – опишете по-долу",
        description1:
          "Corteva Agriscience™ се грижи за вашата поверителност. Вашата лична информация (име, имейл, телефонен номер и други данни за контакт) ще се съхранява в избрани клиентски системи, хоствани основно в Съединените щати. Тази информация ще бъде използвана от фирмите на Corteva Agriscience™, нейните филиали, партньори и избрани трети страни в други държави, за да ви предоставят исканата информация за продукта или услугата. За да научите повече, моля посетете ",
        description2:
          "Предоставяйки вашата лична информация, вие се съгласявате с правилата и условията на тази Декларация за поверителност.",
        url: "https://www.corteva.bg/politika-za-poveritelnost.html",
        urlText: "https://www.corteva.bg/politika-za-poveritelnost.html",
        customer: "клиент/земеделец/Дистрибутор",
        employee: "служител/Кандидат/Бивш служител/Изпълнител",
        vendor: "доставчик",
      },
      legalRepresentative: {
        title: "Заявка за поверителност",
        header: "За използване от законен представител",
        introText:
          "Ако сте законен представител, който подава искането за поверителност от името на дадено лице, моля, попълнете формуляра по-долу.",
        endingText: "Ако подавате заявка за себе си, моля,",
        click: "кликнете тук",
        access: "access",
        accessDescription: "Информирайте ме за съхраняваната информация",
        deletion: "deletion",
        deletionDescription: "Изтрийте цялата лична информация за мен",
        correction: "correction",
        correctionDescription: "Обновeте моята лична информация",
        optOut: "optOut",
        optOutDescription: "Отпишете ме от маркетинг комуникациите",
        other: "other",
        otherDescription: "Друго – опишете по-долу",
        description1:
          "Corteva Agriscience™ се грижи за вашата поверителност. Вашата лична информация (име, имейл, телефонен номер и други данни за контакт) ще се съхранява в избрани клиентски системи, хоствани основно в Съединените щати. Тази информация ще бъде използвана от фирмите на Corteva Agriscience™, нейните филиали, партньори и избрани трети страни в други държави, за да ви предоставят исканата информация за продукта или услугата. За да научите повече, моля посетете ",
        description2:
          "Предоставяйки вашата лична информация, вие се съгласявате с правилата и условията на тази Декларация за поверителност.",
        url: "https://www.corteva.bg/politika-za-poveritelnost.html",
        urlText: "https://www.corteva.bg/politika-za-poveritelnost.html",
        customer: "клиент/земеделец/Дистрибутор",
        employee: "служител/Кандидат/Бивш служител/Изпълнител",
        vendor: "доставчик",
      },
      requestForm: {
        email: "Имейл",
        emailPlaceholder: "Имейл *",
        firstName: "Име",
        firstNamePlaceholder: "Nome *",
        lastName: "Фамилия",
        lastNamePlaceholder: "Cognome *",
        phone: "Телефон",
        phonePlaceholder: "Телефон *",
        country: "Локация",
        countryPlaceholder: "Изберете Локация *",
        robot: "Non sono un robot",
        submitRequest: "Подаване на заявка",
        errorText: "Изисква се валиден номер",
        relationshipToCorteva: "връзка с Corteva",
        customer: "клиент/земеделец/Дистрибутор",
        employee: "служител/Кандидат/Бивш служител/Изпълнител",
        vendor: "доставчик",
        pleaseSpecify: "Моля, пояснете *",
        company: "Компания",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Моля да предоставите детайли за своята заявка",
        legalName: "Име и фамилия на законния представител",
        legalEmail: "Имейл на законния представител",
        legalRelation:
          "Връзка на законния представител с лицето, което представлявате (субект на данни)",
        consumerRelation: "Връзка на субекта на данни с Corteva",
        consumerFirstName: "Име на субекта на данни",
        consumerLastName: "Фамилно име на субекта на данни",
        consumerCompany: "Фирма на субекта на данни",
        consumerPhone: "Телефон на субекта на данни",
        consumerEmail: "Имейл на субекта на данни",
        consumerCountry: "Държава на субекта на данни",
        required: "Това поле е задължително",
        minPhone: "Изисква се валиден номер",
        formUploadDes: "Качване на документация за законен представител",
      },
      acknowledgement: {
        title: "Потвърждение",
        ackText1:
          "Вашият идентификационен номер на заявката за поверителност е",
        ackText2: "Моля, запазете копие за архив.",
        ackText3:
          "На посочения от Вас имейл адрес ще бъде изпратена връзка за потвърждение за всички типове заявки, с изключение на заявка за",
        unsubscribe: "отписване",
        ackText4: "",
        ackText5:
          "Моля, проверете входящата си кутия, за да потвърдите заявката си. Ако не сте получили имейл от",
        ackText6:
          "моля, проверете папката си със спам или изпратете отново заявката си. Благодарим Ви.",
      },
      AADoc: {
        description1:
          "Законният представител е лице, упълномощено да подаде искане за защита на личните данни от името на субект на данни, различен от него самия. Тези записи са достъпни за лицето, за което се отнасят. За да се предпазите от неразрешено разкриване, е необходимо да предоставите: документ за самоличност както за себе си, така и за субекта на данни, когото представлявате, както и пълномощно или писмено разрешение от субекта на данни, когото представлявате.",
        heading: "Инструкции за качване на документа:",
        description2:
          'Моля, влезте в Центъра за защита на личните данни, като кликнете върху бутона "Преглед или управление на заявката Ви” в имейла с тема "Започната заявка за субект на данни”.',
      },
      requestTypes: {
        contactReason: "Причина за заявката",
      },
    },
  },
  hr: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.hr/zastita-privatnosti.html",
        urlText: "https://www.corteva.hr/zastita-privatnosti.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.hr/zastita-privatnosti.html",
        urlText: "https://www.corteva.hr/zastita-privatnosti.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    hr: {
      intakeForm: {
        title: "Zahtjev za privatnost",
        introText:
          "Ako zahtjev šaljete za sebe, ispunite obrazac u nastavku. Ako ste Pravni zastupnik, kliknite tipku pri dnu zaslona da biste došli do obrasca za Pravne zastupnike.",
        endingText:
          "Ako ste Pravni zastupnik koji podnosi zahtjev za privatnost u ime osobe,",
        click: "kliknite ovdje",
        access: "access",
        accessDescription: "Informirajte me o pohranjenim podacima",
        deletion: "deletion",
        deletionDescription: "Izbrišite moje osobne podatke",
        correction: "correction",
        correctionDescription: "Ažurirajte moje osobne podatke",
        optOut: "optOut",
        optOutDescription: "Izbrišite me s liste za marketinšku komunikaciju",
        other: "other",
        otherDescription: "Ostalo - opišite u nastavku",
        description1:
          "Corteva Agriscience ™ brine za vašu privatnost. Vaši osobni podaci (ime, adresa e-pošte, telefonski broj i drugi kontaktni podaci) bit će pohranjeni u odabranim sustavima korisnika koji su primarno smješteni u SAD-u. Ti. Ove informacije će koristiti tvrtke Corteva Agriscience™, njezine podružnice, partneri i odabrane treće strane u drugim zemljama kako bi vam pružili tražene informacije o proizvodu ili usluzi. Da biste saznali više, posjetite ",
        description2:
          "Davanjem svojih osobnih podataka slažete se s odredbama i uvjetima ove Izjave o privatnosti.",
        url: "https://www.corteva.hr/zastita-privatnosti.html",
        urlText: "https://www.corteva.hr/zastita-privatnosti.html",
        customer: "Kupac/Poljoprivrednik/Distributer",
        employee: "Zaposlenik/Kandidat/Bivši zaposlenik/Izvođač",
        vendor: "Prodavač",
      },
      legalRepresentative: {
        title: "Zahtjev za privatnost",
        header: "Za korištenje od strane pravnog zastupnika",
        introText:
          "Ako ste Pravni zastupnik koji podnosi zahtjev za privatnost u ime osobe, ispunite obrazac u nastavku.",
        endingText: "Ako zahtjev šaljete za sebe,",
        click: "kliknite ovdje",
        access: "access",
        accessDescription: "Informirajte me o pohranjenim podacima",
        deletion: "deletion",
        deletionDescription: "Izbrišite moje osobne podatke",
        correction: "correction",
        correctionDescription: "Ažurirajte moje osobne podatke",
        optOut: "optOut",
        optOutDescription: "Izbrišite me s liste za marketinšku komunikaciju",
        other: "other",
        otherDescription: "Ostalo - opišite u nastavku",
        description1:
          "Corteva Agriscience ™ brine za vašu privatnost. Vaši osobni podaci (ime, adresa e-pošte, telefonski broj i drugi kontaktni podaci) bit će pohranjeni u odabranim sustavima korisnika koji su primarno smješteni u SAD-u. Ti. Ove informacije će koristiti tvrtke Corteva Agriscience™, njezine podružnice, partneri i odabrane treće strane u drugim zemljama kako bi vam pružili tražene informacije o proizvodu ili usluzi. Da biste saznali više, posjetite ",
        description2:
          "Davanjem svojih osobnih podataka slažete se s odredbama i uvjetima ove Izjave o privatnosti.",
        url: "https://www.corteva.hr/zastita-privatnosti.html",
        urlText: "https://www.corteva.hr/zastita-privatnosti.html",
        customer: "Kupac/Poljoprivrednik/Distributer",
        employee: "Zaposlenik/Kandidat/Bivši zaposlenik/Izvođač",
        vendor: "Prodavač",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "E-mail *",
        firstName: "Ime",
        firstNamePlaceholder: "Nome *",
        lastName: "Prezime",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefon",
        phonePlaceholder: "Telefono *",
        country: "Država",
        countryPlaceholder: "Odaberi Država *",
        robot: "Non sono un robot",
        submitRequest: "Pošalji zahtjev",
        errorText: "Potreban je važeći broj",
        relationshipToCorteva: "Odnos prema Cortevi",
        customer: "Kupac/Poljoprivrednik/Distributer",
        employee: "Zaposlenik/Kandidat/Bivši zaposlenik/Izvođač",
        vendor: "Prodavač",
        pleaseSpecify: "Molimo navedite",
        company: "Tvrtka",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Navedite pojedinosti o svom zahtjevu",
        legalName: "Ime i prezime Pravnog zastupnika",
        legalEmail: "E-pošta Pravnog zastupnika",
        legalRelation:
          "Odnos Pravnog zastupnika s osobom koju zastupate (Subjekt podataka)",
        consumerRelation: "Odnos Subjekta podataka prema tvrtki Corteva",
        consumerFirstName: "Ime Subjekta podataka",
        consumerLastName: "Prezime Subjekta podataka",
        consumerCompany: "Tvrtka Subjekta podataka",
        consumerPhone: "Telefon Subjekta podataka",
        consumerEmail: "E-pošta Subjekta podataka",
        consumerCountry: "Zemlja Subjekta podataka",
        required: "ovo polje je obavezno",
        minPhone: "Potreban je važeći broj",
        formUploadDes: "Prenesi dokumentaciju pravnog zastupnika",
      },
      acknowledgement: {
        title: "Potvrda",
        ackText1: "Vaš ID zahtjeva za privatnost je",
        ackText2: "Sačuvajte kopiju za potrebe vaše evidencije.",
        ackText3:
          "Link za potvrdu valjanosti bit će poslan na adresu e-maila za sve vrste zahtjeva, osim zahtjeva za",
        unsubscribe: "prekid pretplate",
        ackText4: "",
        ackText5:
          "Provjerite svoju ulaznu poštu da biste potvrdili svoj zahtjev. Ako niste primili e-mail od",
        ackText6:
          "provjerite vašu mapu neželjene pošte ili ponovno pošaljite svoj zahtjev. Zahvaljujemo Vam.",
      },
      AADoc: {
        description1:
          "Pravni zastupnik je osoba ovlaštena za podnošenje zahtjeva za privatnost podataka u ime Subjekta podataka, a koji nisu oni sami. Takvi su zapisi dostupni osobi na koju se odnose. Da biste zaštitili neovlašteno otkrivanje, dužni ste dostaviti: dokaz o identitetu i za sebe i za Subjekt podataka kojeg zastupate, kao i punomoć ili pisano ovlaštenje od Subjekta podataka kojeg zastupate.",
        heading: "Upute za slanje dokumenta:",
        description2:
          'Prijavite se u Središte za privatnost podataka klikom na tipku "Pregledajte svoj zahtjev ili upravljajte njime" u e-pošti s predmetnim retkom "Započeo je zahtjev za podatkovnim predmetom".',
      },
      requestTypes: {
        contactReason: "Razlog",
      },
    },
  },
  cs: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.cz/soukromi.html",
        urlText: "https://www.corteva.cz/soukromi.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.cz/soukromi.html",
        urlText: "https://www.corteva.cz/soukromi.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    cs: {
      intakeForm: {
        title: "Žádost o ochranu osobních údajů",
        introText:
          "Pokud podáváte žádost sami za sebe, vyplňte níže uvedený formulář. Pokud jste zákonný zástupce, klikněte na tlačítko v dolní části obrazovky, abyste se dostali na formulář pro zákonného zástupce.",
        endingText:
          "Pokud jste zákonný zástupce a podáváte žádost o ochranu osobních údajů jménem osoby,",
        click: "klikněte zde",
        access: "access",
        accessDescription: "Informujte me o uložených informacích",
        deletion: "deletion",
        deletionDescription: "Smazat všechny osobní údaje, které se me týkají",
        correction: "correction",
        correctionDescription: "Aktualizace osobních údaju",
        optOut: "optOut",
        optOutDescription: "Odhlásit odber marketingových sdelení",
        other: "other",
        otherDescription: "statní - popište níže",
        description1:
          "Corteva Agriscience™ se stará o vaše soukromí. Vaše osobní údaje (jméno, e-mail, telefonní číslo a další kontaktní údaje) budou uloženy ve vybraných zákaznických systémech primárně hostovaných ve Spojených státech. Tyto informace budou použity podniky společnosti Corteva Agriscience™, jejími přidruženými společnostmi, partnery a vybranými třetími stranami v jiných zemích, aby vám poskytly požadované informace o produktech nebo službách. Chcete-li se dozvědět více, navštivte ",
        description2:
          "Poskytnutím svých osobních údajů souhlasíte s podmínkami tohoto prohlášení o ochraně osobních údajů.",
        url: "https://www.corteva.cz/soukromi.html",
        urlText: "https://www.corteva.cz/soukromi.html",
      },
      legalRepresentative: {
        title: "Žádost o ochranu osobních údajů",
        header: "Pro použití právním zástupcem",
        introText:
          "Pokud jste zákonný zástupce a podáváte žádost o ochranu osobních údajů jménem osoby, vyplňte níže uvedený formulář.",
        endingText: "Pokud podáváte žádost sami za sebe, ",
        click: "klikněte zde",
        access: "access",
        accessDescription: "Informujte me o uložených informacích",
        deletion: "deletion",
        deletionDescription: "Smazat všechny osobní údaje, které se me týkají",
        correction: "correction",
        correctionDescription: "Aktualizace osobních údaju",
        optOut: "optOut",
        optOutDescription: "Odhlásit odber marketingových sdelení",
        other: "other",
        otherDescription: "statní - popište níže",
        description1:
          "Per Corteva Agriscience™ la privacy dei clienti è importante. I dati personali (nome, e-mail, telefono e altre informazioni di contatto) saranno archiviati su sistemi informatici selezionati, situati principalmente negli Stati Uniti. Questi dati verranno utilizzati da Corteva Agriscience™, dai suoi affiliati e partner, oltre che da terze parti selezionate in altri paesi, per fornire le informazioni richieste su prodotti o servizi. Per maggiori informazioni, visitate il sito ",
        description2:
          "Poskytnutím svých osobních údajů souhlasíte s podmínkami tohoto prohlášení o ochraně osobních údajů.",
        url: "https://www.corteva.cz/soukromi.html",
        urlText: "https://www.corteva.cz/soukromi.html",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "E-mail *",
        firstName: "Krestní jméno",
        firstNamePlaceholder: "Nome *",
        lastName: "Príjmení",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefon",
        phonePlaceholder: "Telefono *",
        country: "Vaše poloha",
        countryPlaceholder: "Vybrat Vaše poloha*",
        robot: "Non sono un robot",
        submitRequest: "Odeslat žádost",
        errorText: "Je vyžadováno platné číslo",
        relationshipToCorteva: "Vztah ke spolecnosti Corteva ",
        customer: "Zákazník/Zemědělec/Distributor",
        employee: "Zamestnane/Kandidát/Bývalý zaměstnanec/Dodavatel",
        vendor: "Prodejce",
        pleaseSpecify: "Per favore specificare",
        company: "Spolecnost",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Uvedte prosím podrobnosti svého požadavku",
        legalName: "Jméno a příjmení zákonného zástupce",
        legalEmail: "E-mail zákonného zástupce",
        legalRelation:
          "Vztah zákonného zástupce k osobě, kterou zastupujete (subjekt údajů)",
        consumerRelation: "Vztah subjektu údajů ke společnosti Corteva:",
        consumerFirstName: "Jméno subjektu údajů",
        consumerLastName: "Příjmení subjektu údajů",
        consumerCompany: "Společnost subjektu údajů",
        consumerPhone: "Telefon subjektu údajů",
        consumerEmail: "E-mail subjektu údajů",
        consumerCountry: "Země subjektu údajů",
        required: "Toto pole je povinné",
        minPhone: "Je vyžadováno platné číslo",
        formUploadDes: "Nahrání dokumentace právního zástupce",
      },
      acknowledgement: {
        title: "Potvrzení",
        ackText1: "Vaše ID žádosti o ochranu osobních údajů je",
        ackText2: "Kopii si uložte pro své záznamy.",
        ackText3:
          "Na zadanou e-mailovou adresu bude zaslán ověřovací odkaz pro všechny typy žádostí s výjimkou",
        unsubscribe: "odhlášení",
        ackText4: "žádosti o",
        ackText5:
          "Zkontrolujte svou doručenou poštu a ověřte si svůj požadavek. Pokud jste neobdrželi e-mail z adresy",
        ackText6:
          "zkontrolujte složku se spamem nebo žádost odešlete znovu. Děkujeme vám.",
      },
      AADoc: {
        description1:
          "Zákonný zástupce je osoba oprávněná podat žádost o ochranu osobních údajů jménem jiného subjektu údajů, než je on sám. Tyto záznamy jsou přístupné osobě, které se týkají. Pro ochranu před neoprávněným zveřejněním je nutné, abyste předložili: doklad totožnosti své i subjektu údajů, který zastupujete, a také plnou moc nebo písemné pověření od subjektu údajů, který zastupujete.",
        heading: "Pokyny pro nahrání dokumentu:",
        description2:
          "Do Centra pro ochranu osobních údajů se přihlaste kliknutím na tlačítko „Zobrazit nebo spravovat žádosti“ v e-mailu s předmětem „Požadavek datového subjektu vytvořen“.",
      },
      requestTypes: {
        contactReason: "Vyberte jednu z možností",
      },
    },
  },
  br: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.br/privacy-policy.html",
        urlText: "https://www.corteva.com.br/privacy-policy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person residing in Brazil, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.br/privacy-policy.html",
        urlText: "https://www.corteva.com.br/privacy-policy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        firstName: "First Name",
        lastName: "Last Name",
        phone: "Phone Number",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        otherRequestDetails: "Please provide details about your request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    pt_BR: {
      intakeForm: {
        title: "Solicitação de privacidade",
        introText:
          "Se você estiver enviando uma solicitação em seu nome, preencha o formulário abaixo. Se você é um Representante Legal, clique no botão na parte inferior da tela para chegar ao formulário para Representantes Legais.",
        endingText:
          "Se você é um Representante Legal que envia o pedido de privacidade em nome de uma pessoa,",
        click: "clique aqui",
        accessDescription:
          "Informar-me quais são as minhas informações pessoais armazenadas",
        deletionDescription: "Apagar todas as minhas informações pessoais",
        correctionDescription: "Atualizar as minhas informações pessoais",
        optOutDescription: "Anular subscrição de comunicações de marketing",
        otherDescription: "Outro - Descrever abaixo",
        description1:
          "A Corteva Agriscience ™ se importa com sua privacidade.  As suas informações pessoais (nome, e-mail, número de telefone e outros dados de contato) serão armazenadas em sistemas de clientes selecionados e hospedados essencialmente nos Estados Unidos. Estas informações serão utilizadas pela Corteva Agriscience ™ respectivas empresas afiliadas, parceiros e terceiros selecionados em outros países para lhe fornecer as informações de produto ou serviço solicitadas.  Para obter mais informações, visite ",
        description2:
          "Ao fornecer as suas informações pessoais, você concorda com os termos e condições da presente Declaração de Privacidade.",
        url: "https://www.corteva.com.br/privacy-policy.html",
        urlText: "https://www.corteva.com.br/privacy-policy.html",
        customer: "Cliente/ Agricultor / Distribuidor",
        employee:
          "Funcionário/Candidato/Ex-funcionário/Empregado terceirizado.",
        vendor: "Fornecedor",
      },
      legalRepresentative: {
        title: "Solicitação de privacidade",
        header: "Para uso do Representante Legal",
        introText:
          "Se você é um Representante Legal enviando o pedido de privacidade em nome de uma Pessoa residente no Brasil, por favor preencha o formulário abaixo.",
        endingText: "Se você está enviando uma solicitação para si mesmo,",
        click: "clique aqui",
        accessDescription:
          "Informar-me quais são as minhas informações pessoais armazenadas",
        deletionDescription: "Apagar todas as minhas informações pessoais",
        correctionDescription: "Atualizar as minhas informações pessoais",
        optOutDescription: "Anular subscrição de comunicações de marketing",
        otherDescription: "Outro - Descrever abaixo",
        description1:
          "A Corteva Agriscience ™ se importa com sua privacidade.  As suas informações pessoais (nome, e-mail, número de telefone e outros dados de contato) serão armazenadas em sistemas de clientes selecionados e hospedados essencialmente nos Estados Unidos. Estas informações serão utilizadas pela Corteva Agriscience ™ respectivas empresas afiliadas, parceiros e terceiros selecionados em outros países para lhe fornecer as informações de produto ou serviço solicitadas.  Para obter mais informações, visite ",
        description2:
          "Ao fornecer as suas informações pessoais, você concorda com os termos e condições da presente Declaração de Privacidade.",
        url: "https://www.corteva.com.br/privacy-policy.html",
        urlText: "https://www.corteva.com.br/privacy-policy.html",
        customer: "Cliente/ Agricultor / Distribuidor",
        employee:
          "Funcionário/Candidato/Ex-funcionário/Empregado terceirizado.",
        vendor: "Fornecedor",
      },
      requestForm: {
        email: "E-mail",
        emailPlaceholder: "E-mail *",
        firstName: "Primeiro nome",
        lastName: "Sobrenome",
        phone: "Telefone",
        country: "Localização",
        countryPlaceholder: "Selecione Localização *",
        robot: "eu não sou um robô",
        submitRequest: "Enviar solicitação",
        errorText: "É necessário um número válido",
        relationshipToCorteva: "Relação com Corteva",
        customer: "Cliente/ Agricultor / Distribuidor",
        employee:
          "Funcionário/Candidato/Ex-funcionário/Empregado terceirizado.",
        vendor: "Fornecedor",
        pleaseSpecify: "Por favor especifique *",
        company: "Empresa",
        otherRequestDetails: "Por favor, selecione o assunto da sua pergunta",
        legalName: "Nome e Sobrenome do Representante Legal",
        legalEmail: "E-mail do Representante Legal",
        legalRelation:
          "Grau de relação do Representante Legal com a pessoa que você representa (Titular dos dados)",
        consumerRelation:
          "Grau de relacionamento do Titular dos dados com Corteva",
        consumerFirstName: "Nome do titular dos dados",
        consumerLastName: "Sobrenome do titular dos dados",
        consumerCompany: "Empresa do titular dos dados",
        consumerPhone: "Número de telefone do titular dos dados",
        consumerEmail: "E-mail do titular dos dados",
        consumerCountry: "País do titular dos dados",
        required: "Este campo é obrigatório",
        minPhone: "É necessário um número válido",
        formUploadDes: "Carregar documentação do Representante Legal",
      },
      acknowledgement: {
        title: "Confirmação:",
        ackText1: "Seu ID de Solicitação de Privacidade é",
        ackText2: "Por favor, guarde uma cópia para seus registros.",
        ackText3:
          "Um link de verificação será enviado para o endereço de e-mail fornecido para todos os tipos de solicitação, exceto para uma",
        unsubscribe: "cancelamento de inscrição",
        ackText4: "solicitação de",
        ackText5:
          "Por favor, verifique sua caixa de entrada para verificar sua solicitação. Se você não recebeu um e-mail de",
        ackText6:
          "verifique sua pasta de spam ou reenviar sua solicitação. Obrigada.",
      },
      AADoc: {
        description1:
          "Um representante legal é um indivíduo com poderes para enviar um pedido de privacidade de dados em nome de um Sujeito de Dados diferente de si mesmos. Tais registros são acessíveis à Pessoa a quem eles pertencer. Para proteger a divulgação não autorizada, é necessário que você forneça: uma prova de identidade tanto para você e para o Sujeito de Dados que você representa, bem como uma procuração ou um escrito autorização do Data Subject que você representa.",
        heading: "Instruções para carregar o documento:",
        description2:
          'Faça login no Data Privacy Center clicando no botão "Exibir ou gerenciar sua solicitação" no e-mail com a linha de assunto "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Motivo de Contato",
      },
    },
  },
  da: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.dk/fortrolighedspolitik.html",
        urlText: "https://www.corteva.dk/fortrolighedspolitik.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.dk/fortrolighedspolitik.html",
        urlText: "https://www.corteva.dk/fortrolighedspolitik.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    da: {
      intakeForm: {
        title: "Privatliv Anmodning",
        introText:
          "Hvis du sender en anmodning på egne vegne, skal du udfylde nedenstående formular. Hvis du er en juridisk repræsentant, skal du klikke på knappen nederst på skærmen for at komme til formularen for juridiske repræsentanter.",
        endingText:
          "Hvis du er en juridisk repræsentant, der indsender anmodningen om beskyttelse af personlige oplysninger på vegne af en person, skal du",
        click: "klikke her",
        access: "access",
        accessDescription:
          "Informer mig om, hvilke personlige informationer I har gemt om mig",
        deletion: "deletion",
        deletionDescription:
          "Slet alle personlige oplysninger relateret til mig",
        correction: "correction",
        correctionDescription: "Opdater mine personlige informationer",
        optOut: "optOut",
        optOutDescription: "Afmeld nyhedsbreve",
        other: "other",
        otherDescription: "Andet - uddyb nedenfor",
        description1:
          "Corteva Agriscience forpligter sig til at beskytte dine personlige oplysninger. Dine personlige data (navn, email, adresse, telefonnummer og andre informationer) vil blive gemt i kundesystemer som vi har valgt og bliver overvejende gemt i USA. Informationen vil blive brugt af Corteva Agriscience, dets partnere og tredjeparter etableret i andre lande, med henblik på at opfylde dine anmodninger. For yderligere information kan du besøge ",
        description2:
          "Ved at få adgang til vores hjemmeside og dele personlige data med os, accepterer du vilkårene i denne fortrolighedserklæring.",
        url: "https://www.corteva.dk/fortrolighedspolitik.html",
        urlText: "https://www.corteva.dk/fortrolighedspolitik.html",
      },
      legalRepresentative: {
        title: "Privatliv Anmodning",
        header: "Til brug af den juridiske repræsentant",
        introText:
          "Hvis du er en juridisk repræsentant, der indsender anmodningen om beskyttelse af personlige oplysninger på vegne af en person, skal du udfylde nedenstående formular.",
        endingText: "Hvis du sender en anmodning på egne vegne, skal du",
        click: "klikke her",
        access: "access",
        accessDescription:
          "Informer mig om, hvilke personlige informationer I har gemt om mig",
        deletion: "deletion",
        deletionDescription:
          "Slet alle personlige oplysninger relateret til mig",
        correction: "correction",
        correctionDescription: "Opdater mine personlige informationer",
        optOut: "optOut",
        optOutDescription: "Afmeld nyhedsbreve",
        other: "other",
        otherDescription: "Andet - uddyb nedenfor",
        description1:
          "Corteva Agriscience forpligter sig til at beskytte dine personlige oplysninger. Dine personlige data (navn, email, adresse, telefonnummer og andre informationer) vil blive gemt i kundesystemer som vi har valgt og bliver overvejende gemt i USA. Informationen vil blive brugt af Corteva Agriscience, dets partnere og tredjeparter etableret i andre lande, med henblik på at opfylde dine anmodninger. For yderligere information kan du besøge ",
        description2:
          "Ved at få adgang til vores hjemmeside og dele personlige data med os, accepterer du vilkårene i denne fortrolighedserklæring.",
        url: "https://www.corteva.dk/fortrolighedspolitik.html",
        urlText: "https://www.corteva.dk/fortrolighedspolitik.html",
      },
      requestForm: {
        email: "E-mail",
        emailPlaceholder: "E-mail *",
        firstName: "Fornavn",
        firstNamePlaceholder: "Nome *",
        lastName: "Efternavn",
        lastNamePlaceholder: "Cognome *",
        phone: "Mobilnummer",
        phonePlaceholder: "Telefono *",
        country: "Land",
        countryPlaceholder: "Vælg Land *",
        robot: "Non sono un robot",
        submitRequest: "Send anmodning",
        errorText: "Der kræves et gyldigt nummer",
        relationshipToCorteva: "Forhold til Corteva",
        customer: "Kunde/Landmand/Distributør",
        employee: "Medarbejder/Ansøger/Tidligere medarbejder/Kontrahent",
        vendor: "Udbyder",
        pleaseSpecify: "Vær venlig at uddybe",
        company: "Bedrift/Firma",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails:
          "Angiv venligst, hvad din henvendelse drejer sig om",
        legalName: "Juridisk repræsentant Fornavn og efternavn",
        legalEmail: "Juridisk repræsentant – E-mailadresse",
        legalRelation:
          "Forholdet mellem den juridiske repræsentant og den person, du repræsenterer (den registrerede)",
        consumerRelation: "Den registreredes forhold til Corteva",
        consumerFirstName: "Den registreredes fornavn",
        consumerLastName: "Den registreredes efternavn",
        consumerCompany: "Den registreredes virksomhed",
        consumerPhone: "Den registreredes telefonnummer",
        consumerEmail: "Den registreredes e-mailadresse",
        consumerCountry: "Den registreredes land",
        required: "dette felt er påkrævet",
        minPhone: "Der kræves et gyldigt nummer",
        formUploadDes: "Upload dokumentation til juridisk repræsentant",
      },
      acknowledgement: {
        title: "Bekræftelse:",
        ackText1:
          "Dit id for anmodning om beskyttelse af personlige oplysninger er",
        ackText2: "Gem venligst en kopi til dig selv.",
        ackText3:
          "Der sendes et verificeringslink til den e-mailadresse, du opgav, for alle anmodningstyper bortset fra en",
        unsubscribe: "opsigelse af abonnement",
        ackText4: "anmodning om",
        ackText5:
          "Kig i din indbakke for at verificere din anmodning. Hvis du ikke modtog en e-mail fra",
        ackText6:
          "bedes du kigge i din mappe med uønsket post eller sende din anmodning igen. Tak.",
      },
      AADoc: {
        description1:
          "En juridisk repræsentant er en person, der er bemyndiget til at indgive en anmodning om beskyttelse af personlige oplysninger på vegne af en anden registreret person end den registrerede selv. Sådanne optegnelser er tilgængelige for den person, som de vedrører. Som beskyttelse mod uautoriseret videregivelse er det påkrævet, at du fremlægger: Et identitetsbevis for både dig selv og den registrerede, du repræsenterer, samt en fuldmagt eller en skriftlig tilladelse fra den registrerede, du repræsenterer.",
        heading: "Anvisninger til overførsel af dokumentet:",
        description2:
          'Log på Data Privacy Center ved at klikke på knappen "Vis eller administrer din anmodning" i e-mailen med emnefeltet "Anmodning om dataemne startet".',
      },
      requestTypes: {
        contactReason: "Årsag til henvendelse",
      },
    },
  },
  et: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.ee/Privaatsuspoliitika.html",
        urlText: "https://www.corteva.ee/Privaatsuspoliitika.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.ee/Privaatsuspoliitika.html",
        urlText: "https://www.corteva.ee/Privaatsuspoliitika.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    et: {
      intakeForm: {
        title: "Privaatsustaotlus",
        introText:
          "Kui esitate taotluse enda kohta, täitke palun allolev vorm. Kui olete seaduslik esindaja, klõpsake ekraani allosas oleval nupul, et avada seaduslikele esindajatele mõeldud vorm.",
        endingText:
          "Kui olete seaduslik esindaja, kes esitab andmekaitse taotluse teise isiku nimel,",
        click: "klõpsake palun siin",
        access: "access",
        accessDescription: "Andke mulle teada salvestatud teabest",
        deletion: "deletion",
        deletionDescription: "Kustutage kogu minuga seotud isiklik teave",
        correction: "correction",
        correctionDescription: "Värskendage minu isikuandmeid",
        optOut: "optOut",
        optOutDescription: "Loobuge turunduskommunikatsioonist",
        other: "other",
        otherDescription: "Muu - kirjeldage allpool",
        description1:
          "Corteva Agriscience™ hoolib teie privaatsusest. Teie isikuandmed (nimi, e-posti aadress, telefoninumber ja muud kontaktandmed) salvestatakse valitud kliendisüsteemidesse, mida majutatakse peamiselt Ameerika Ühendriikides. Seda teavet kasutavad ettevõtte Corteva Agriscience™ ettevõtted, selle sidusettevõtted, partnerid ja valitud kolmandad osapooled teistes riikides, et pakkuda teile nõutud toote- või teenuseteavet. Lisateabe saamiseks külastage veebisaiti ",
        description2:
          "Isikuandmete esitamisega nõustute käesoleva privaatsusavalduse tingimustega.",
        url: "https://www.corteva.ee/Privaatsuspoliitika.html",
        urlText: "https://www.corteva.ee/Privaatsuspoliitika.html",
        customer: "Klient/Talunik/Turustaja",
        employee: "Töötaja/Taotleja/Endine töötaja/Kontrahent",
        vendor: "Müüja",
      },
      legalRepresentative: {
        title: "Privaatsustaotlus",
        header: "Kasutamiseks seaduslikule esindajale",
        introText:
          "Kui olete seaduslik esindaja, kes esitab andmekaitse taotluse teise isiku nimel, täitke palun allolev vorm.",
        endingText: "Kui esitate taotluse enda kohta,",
        click: "klõpsake palun siin",
        access: "access",
        accessDescription: "Andke mulle teada salvestatud teabest",
        deletion: "deletion",
        deletionDescription: "Kustutage kogu minuga seotud isiklik teave",
        correction: "correction",
        correctionDescription: "Värskendage minu isikuandmeid",
        optOut: "optOut",
        optOutDescription: "Loobuge turunduskommunikatsioonist",
        other: "other",
        otherDescription: "Muu - kirjeldage allpool",
        description1:
          "Per Corteva Agriscience™ la privacy dei clienti è importante. I dati personali (nome, e-mail, telefono e altre informazioni di contatto) saranno archiviati su sistemi informatici selezionati, situati principalmente negli Stati Uniti. Questi dati verranno utilizzati da Corteva Agriscience™, dai suoi affiliati e partner, oltre che da terze parti selezionate in altri paesi, per fornire le informazioni richieste su prodotti o servizi. Per maggiori informazioni, visitate il sito ",
        description2:
          "Isikuandmete esitamisega nõustute käesoleva privaatsusavalduse tingimustega.",
        url: "https://www.corteva.ee/Privaatsuspoliitika.html",
        urlText: "https://www.corteva.ee/Privaatsuspoliitika.html",
        customer: "Klient/Talunik/Turustaja",
        employee: "Töötaja/Taotleja/Endine töötaja/Kontrahent",
        vendor: "Müüja",
      },
      requestForm: {
        email: "Meil",
        emailPlaceholder: "E-mail *",
        firstName: "Eesnimi",
        firstNamePlaceholder: "Nome *",
        lastName: "Perekonnanimi",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefon",
        phonePlaceholder: "Telefono *",
        country: "Riik",
        countryPlaceholder: "Vali riik *",
        robot: "Non sono un robot",
        submitRequest: "Taotluse esitamine",
        errorText: "Nõutav on kehtiv number",
        relationshipToCorteva: "Suhe Cortevaga",
        customer: "Klient/Talunik/Turustaja",
        employee: "Töötaja/Taotleja/Endine töötaja/Kontrahent",
        vendor: "Müüja",
        pleaseSpecify: "Palun täpsusta *",
        company: "Ettevõte",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Esitage oma taotluse üksikasjad",
        legalName: "Seadusliku esindaja ees- ja perekonnanimi",
        legalEmail: "Seadusliku esindaja e-posti aadress",
        legalRelation:
          "Seadusliku esindaja suhe esindatava isikuga (andmesubjektiga)",
        consumerRelation: "Andmesubjekti suhe Cortevaga:",
        consumerFirstName: "Andmesubjekti eesnimi",
        consumerLastName: "Andmesubjekti perekonnanimi",
        consumerCompany: "Andmesubjekti ettevõte",
        consumerPhone: "Andmesubjekti telefoninumber",
        consumerEmail: "Andmesubjekt e-posti aadress",
        consumerCountry: "Andmesubjekti riik",
        required: "Selle välja täitmine on kohustuslik",
        minPhone: "Nõutav on kehtiv number",
        formUploadDes: "Laadige üles juriidilise esindaja dokumentatsioon",
      },
      acknowledgement: {
        title: "Kinnitus",
        ackText1: "Teie andmekaitse taotluse ID on",
        ackText2: "Palun salvestage koopia oma dokumentide jaoks.",
        ackText3: "",
        unsubscribe: "",
        ackText4: "saadetakse teie edastatud meiliaadressile kinnituslink.",
        ackText5:
          "Taotluse kinnitamiseks vaadake oma postkasti. Kui te ei saanud meili aadressilt",
        ackText6:
          "siis vaadake rämpsposti kausta või saatke taotlus uuesti. Täname!",
      },
      AADoc: {
        description1:
          "Seaduslik esindaja on isik, kellel on volitus esitada andmesubjekti nimel andmekaitse taotlus, kuid kes pole ise andmesubjekt. Sellised andmed on kättesaadavad isikule, keda need puudutavad. Loata avalikustamise vältimiseks peate esitama: tõendid enda ja esindatava andmesubjekti identifitseerimiseks, samuti volikirja või esindatava andmesubjekti kirjaliku volituse.",
        heading: "Juhised dokumendi üleslaadimiseks:",
        description2:
          'Palun logige sisse andmekaitse keskusesse, klõpsates meilis oleval nupul "Taotluse vaatamine või haldamine", mille teema on "Andmesubjekti taotlus on algatatud".',
      },
      requestTypes: {
        contactReason: "Kontakti põhjus",
      },
    },
  },
  fi: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.fi/yksityisyyden-suoja.html",
        urlText: "https://www.corteva.fi/yksityisyyden-suoja.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.fi/yksityisyyden-suoja.html",
        urlText: "https://www.corteva.fi/yksityisyyden-suoja.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    fi: {
      intakeForm: {
        title: "Tietosuojapyyntö",
        introText:
          "Jos lähetät pyynnön itsesi puolesta, täytä alla oleva lomake. Jos olet laillinen edustaja, napsauta näytön alareunassa olevaa painiketta, joka vie laillisille edustajille tarkoitettuun lomakkeeseen.",
        endingText:
          "Jos olet laillinen edustaja ja lähetät tietosuojapyynnön jonkun toisen henkilön puolesta,",
        click: "napsauta tässä",
        access: "access",
        accessDescription: "Ilmoita minulle tallennetuista tiedoista",
        deletion: "deletion",
        deletionDescription:
          "Poista kaikki minuun liittyvät henkilökohtaiset tiedot",
        correction: "correction",
        correctionDescription: "Päivitä henkilötietoni",
        optOut: "optOut",
        optOutDescription: "Peruuta markkinointiviestinnän tilaus",
        other: "other",
        otherDescription: "Muu - kuvaile alla",
        description1:
          "Corteva Agriscience™ välittää yksityisyydestäsi. Henkilötietosi (nimi, sähköpostiosoite, puhelinnumero ja muut yhteystiedot) tallennetaan valittuihin asiakasjärjestelmiin, joita isännöidään ensisijaisesti Yhdysvalloissa. Corteva Agriscience™:n yritykset, sen tytäryhtiöt, kumppanit ja valitut kolmannet osapuolet muissa maissa käyttävät näitä tietoja toimittaakseen sinulle pyydetyt tuote- tai palvelutiedot. Lisätietoja on osoitteessa ",
        description2:
          "Antamalla henkilötietosi hyväksyt tämän tietosuojaselosteen ehdot.",
        url: "https://www.corteva.fi/yksityisyyden-suoja.html",
        urlText: "https://www.corteva.fi/yksityisyyden-suoja.html",
        customer: "Asiakas/Viljelijä/Jakelija",
        employee: "Työntekijä/Ehdokas/Entinen työntekijä/Urakoitsija",
        vendor: "Myyjä",
      },
      legalRepresentative: {
        title: "Tietosuojapyyntö",
        header: "Laillisen edustajan käyttöön",
        introText:
          "Jos olet laillinen edustaja ja lähetät tietosuojapyynnön jonkun toisen henkilön puolesta, täytä alla oleva lomake.",
        endingText: "Jos lähetät pyynnön itsesi puolesta,",
        click: "napsauta tässä",
        access: "access",
        accessDescription: "Ilmoita minulle tallennetuista tiedoista",
        deletion: "deletion",
        deletionDescription:
          "Poista kaikki minuun liittyvät henkilökohtaiset tiedot",
        correction: "correction",
        correctionDescription: "Päivitä henkilötietoni",
        optOut: "optOut",
        optOutDescription: "Peruuta markkinointiviestinnän tilaus",
        other: "other",
        otherDescription: "Muu - kuvaile alla",
        description1:
          "Corteva Agriscience™ välittää yksityisyydestäsi. Henkilötietosi (nimi, sähköpostiosoite, puhelinnumero ja muut yhteystiedot) tallennetaan valittuihin asiakasjärjestelmiin, joita isännöidään ensisijaisesti Yhdysvalloissa. Corteva Agriscience™:n yritykset, sen tytäryhtiöt, kumppanit ja valitut kolmannet osapuolet muissa maissa käyttävät näitä tietoja toimittaakseen sinulle pyydetyt tuote- tai palvelutiedot. Lisätietoja on osoitteessa ",
        description2:
          "Antamalla henkilötietosi hyväksyt tämän tietosuojaselosteen ehdot.",
        url: "https://www.corteva.fi/yksityisyyden-suoja.html",
        urlText: "https://www.corteva.fi/yksityisyyden-suoja.html",
        customer: "Asiakas/Viljelijä/Jakelija",
        employee: "Työntekijä/Ehdokas/Entinen työntekijä/Urakoitsija",
        vendor: "Myyjä",
      },
      requestForm: {
        email: "Sähköposti",
        emailPlaceholder: "Sähköposti *",
        firstName: "Etunimi",
        lastName: "Sukunimi",
        phone: "Puhelin",
        phonePlaceholder: "Puhelin *",
        country: "Maa",
        countryPlaceholder: "Valitse maa *",
        robot: "Non sono un robot",
        submitRequest: "Lähetä pyyntö",
        errorText: "Kelvollinen numero vaaditaan",
        relationshipToCorteva: "Suhde Cortevaan",
        customer: "Asiakas/Viljelijä/Jakelija",
        employee: "Työntekijä/Ehdokas/Entinen työntekijä/Urakoitsija",
        vendor: "Myyjä",
        pleaseSpecify: "Ole hyvä ja täsmennä",
        company: "Yhtiö",
        otherRequestDetails: "Anna pyyntösi tiedot",
        legalName: "Laillisen edustajan etunimi ja sukunimi",
        legalEmail: "Laillisen edustajan sähköpostiosoite",
        legalRelation:
          "Laillisen edustajan suhde edustamaasi henkilöön (rekisteröityyn)",
        consumerRelation: "Rekisteröidyn suhde Cortevaan",
        consumerFirstName: "Rekisteröidyn etunimi",
        consumerLastName: "Rekisteröidyn sukunimi",
        consumerCompany: "Rekisteröidyn yritys",
        consumerPhone: "Rekisteröidyn puhelinnumero",
        consumerEmail: "Rekisteröidyn sähköpostiosoite",
        consumerCountry: "Rekisteröidyn maa",
        required: "Tämä kenttä pitää täyttää",
        minPhone: "Kelvollinen numero vaaditaan",
        formUploadDes: "Lataa laillisen edustajan dokumentaatio",
      },
      acknowledgement: {
        title: "Vahvistus",
        ackText1: "Tietosuojapyyntösi tunnus on",
        ackText2: "Säilytä kopio itselläsi.",
        ackText3:
          "Vahvistuslinkki lähetetään ilmoittamaasi sähköpostiosoitteeseen kaikenlaisten pyyntöjen tapauksessa, paitsi tilauksen",
        unsubscribe: "perumispyynnön tapauksessa",
        ackText4: "",
        ackText5:
          "Lue saamasi sähköposti saapuneista viesteistäsi ja vahvista pyyntösi. Jos et saa sähköpostia osoitteesta",
        ackText6:
          "tarkista roskapostikansiosi tai lähetä pyyntösi uudelleen. Kiitos.",
      },
      AADoc: {
        description1:
          "Laillinen edustaja on henkilö, jolla on valtuudet lähettää tietosuojapyyntö sellaisen rekisteröidyn puolesta, joka ei ole itse kyseinen laillinen edustaja. Nämä tiedot ovat sen henkilön saatavilla, jota ne koskevat. Luvattoman luovuttamisen estämiseksi sinun on toimitettava seuraavat asiakirjat: sekä itsesi että edustamastasi rekisteröidyn henkilöllisyystodistus ja lisäksi edustamasi rekisteröidyn antama valtakirja tai kirjallinen valtuutus.",
        heading: "Ohjeet asiakirjan lataamiseen:",
        description2:
          "Kirjaudu sisään tietosuojakeskukseen napsauttamalla sähköpostiviestissä ”Rekisteröidyn pyyntö aloitettu” olevaa ”Tarkastele tai hallinnoi pyyntöäsi” -painiketta.",
      },
      requestTypes: {
        contactReason: "Yhteyden syy",
      },
    },
  },
  fr: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        deleteMyCortevaAccountDescription: "Delete my Corteva user account",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.fr/politique-de-confidentialite.html",
        urlText: "https://www.corteva.fr/politique-de-confidentialite.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        deleteMyCortevaAccountDescription: "Delete my Corteva user account",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.fr/politique-de-confidentialite.html",
        urlText: "https://www.corteva.fr/politique-de-confidentialite.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    fr: {
      intakeForm: {
        title: "Demande de confidentialité",
        introText:
          "Si vous soumettez une demande pour vous-même, renseignez le formulaire ci-dessous. Si vous êtes un représentant légal, cliquez sur le bouton au bas de l'écran pour accéder au formulaire destiné aux représentants légaux.",
        endingText:
          "Si vous êtes un représentant légal soumettant la demande de confidentialité pour le compte d'une personne,",
        click: "cliquez ici",
        access: "access",
        accessDescription: "Me renseigner sur les informations stockées",
        deletion: "deletion",
        deletionDescription:
          "Supprimer toutes les informations personnelles me concernant",
        correction: "correction",
        correctionDescription: "Mettre à jour mes données personnelles",
        optOut: "optOut",
        optOutDescription: "Se désabonner des communications marketing",
        other: "other",
        otherDescription: "Autres - décrivez ci-dessous",
        deleteMyCortevaAccountDescription:
          "Supprimer mon compte Place Des Agriculteurs",
        description1:
          "Corteva Agriscience™ attache une grande importance à la confidentialité de vos données.  Vos données à caractère personnel (nom, adresse email, numéro de téléphone et autres coordonnées) seront conservées dans des systèmes clients que nous avons sélectionnés et qui sont principalement hébergés aux États-Unis.  Ces informations seront utilisées par Corteva Agriscience™, ses filiales, ses partenaires et des tiers triés sur le volet établis dans d'autres pays, dans le but de répondre à vos demandes d'information sur des produits ou des services.  Pour en savoir plus, rendez-vous sur le site ",
        description2:
          "En fournissant vos données personnelles, vous acceptez les termes et conditions de cette déclaration de confidentialité.",
        url: "https://www.corteva.fr/politique-de-confidentialite.html",
        urlText: "https://www.corteva.fr/politique-de-confidentialite.html",
        customer: "Client/Agriculteur/Distributeur",
        employee: "Employé/Candidat/Ancien employé/Prestataire",
        vendor: "Fournisseur",
      },
      legalRepresentative: {
        title: "Demande de confidentialité",
        header: "À l'usage du représentant légal",
        introText:
          "Si vous êtes un représentant légal soumettant la demande de confidentialité pour le compte d'une personne, renseignez le formulaire ci-dessous.",
        endingText: "Si vous soumettez une demande pour vous-même,",
        click: "cliquez ici",
        access: "access",
        accessDescription: "Me renseigner sur les informations stockées",
        deletion: "deletion",
        deletionDescription:
          "Supprimer toutes les informations personnelles me concernant",
        correction: "correction",
        correctionDescription: "Mettre à jour mes données personnelles",
        optOut: "optOut",
        optOutDescription: "Se désabonner des communications marketing",
        other: "other",
        otherDescription: "Autres - décrivez ci-dessous",
        deleteMyCortevaAccountDescription:
          "Supprimer mon compte Place Des Agriculteurs",
        description1:
          "Corteva Agriscience™ attache une grande importance à la confidentialité de vos données.  Vos données à caractère personnel (nom, adresse email, numéro de téléphone et autres coordonnées) seront conservées dans des systèmes clients que nous avons sélectionnés et qui sont principalement hébergés aux États-Unis.  Ces informations seront utilisées par Corteva Agriscience™, ses filiales, ses partenaires et des tiers triés sur le volet établis dans d'autres pays, dans le but de répondre à vos demandes d'information sur des produits ou des services.  Pour en savoir plus, rendez-vous sur le site ",
        description2:
          "En fournissant vos données personnelles, vous acceptez les termes et conditions de cette déclaration de confidentialité.",
        url: "https://www.corteva.fr/politique-de-confidentialite.html",
        urlText: "https://www.corteva.fr/politique-de-confidentialite.html",
        customer: "Client/Agriculteur/Distributeur",
        employee: "Employé/Candidat/Ancien employé/Prestataire",
        vendor: "Fournisseur",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Имейл *",
        firstName: "Prénom",
        firstNamePlaceholder: "Nome *",
        lastName: "Nom de famille",
        lastNamePlaceholder: "Cognome *",
        phone: "Téléphone",
        phonePlaceholder: "Телефон *",
        country: "Pays",
        countryPlaceholder: "Sélectionner Pays *",
        robot: "Non sono un robot",
        submitRequest: "Soumettre une demande",
        errorText: "Un numéro valide est requis",
        relationshipToCorteva: "Relation avec Corteva",
        customer: "Client/Agriculteur/Distributeur",
        employee: "Employé/Candidat/Ancien employé/Prestataire",
        vendor: "Fournisseur",
        pleaseSpecify: "Veuillez préciser *",
        company: "Entreprise",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Veuillez fournir des détails sur votre demande",
        legalName: "Prénom et nom du représentant légal",
        legalEmail: "E-mail du représentant légal",
        legalRelation:
          "Lien entre le représentant légal et la personne représentée (personne concernée)",
        consumerRelation: "Lien entre la personne concernée et Corteva:",
        consumerFirstName: "Prénom de la personne concernée",
        consumerLastName: "Nom de la personne concernée",
        consumerCompany: "Société de la personne concernée",
        consumerPhone: "Téléphone de la personne concernée",
        consumerEmail: "E-mail de la personne concernée",
        consumerCountry: "Pays de la personne concernée",
        required: "Ce champ est requis",
        minPhone: "Un numéro valide est requis",
        formUploadDes: "Télécharger la documentation du représentant légal",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Votre ID de demande de confidentialité est",
        ackText2: "Conservez-en une copie dans vos archives.",
        ackText3:
          "Un lien de vérification sera envoyé à l’adresse e-mail indiquée pour les demandes de tous types à l’exception des demandes",
        unsubscribe: "de désabonnement",
        ackText4: "",
        ackText5:
          "Consultez votre boîte de réception pour vérifier votre demande. Si vous n’avez reçu aucun e-mail de",
        ackText6:
          "vérifiez votre dossier de spam ou soumettez une nouvelle demande. Merci.",
      },
      AADoc: {
        description1:
          "Un représentant légal est une personne habilitée à soumettre une demande de confidentialité des données pour le compte d'une personne concernée autre qu'elle-même. Ces données sont accessibles à la personne à laquelle elles se rapportent. Pour éviter toute divulgation non autorisée, il est nécessaire de fournir une preuve d'identité pour vous-même et la personne concernée que vous représentez, ainsi qu'une procuration ou une autorisation écrite de la personne concernée que vous représentez.",
        heading: "Instructions pour télécharger le document:",
        description2:
          "\"Connectez-vous au centre de confidentialité des données en cliquant sur le bouton Visualiser ou gérer votre demande dans l'e-mail dont l'objet est Début de la demande d’accès de la personne concernée.\"",
      },
      requestTypes: {
        contactReason: "Objet de la demande",
      },
    },
  },
  de: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        deleteMyCortevaAccountDescription: "Delete my Corteva user account",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.de/datenschutz-richtlinien.html",
        urlText: "https://www.corteva.de/datenschutz-richtlinien.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        deleteMyCortevaAccountDescription: "Delete my Corteva user account",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.de/datenschutz-richtlinien.html",
        urlText: "https://www.corteva.de/datenschutz-richtlinien.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        firstName: "First Name",
        lastName: "Last Name",
        phone: "Phone Number",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        otherRequestDetails: "Please provide details about your request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    de: {
      intakeForm: {
        title: "Datenschutzanfrage",
        introText:
          "Wenn Sie eine Anfrage für sich selbst stellen, füllen Sie bitte das folgende Formular aus. Wenn Sie ein gesetzlicher Vertreter sind, klicken Sie bitte auf die Schaltfläche unten auf dem Bildschirm, um zum Formular für gesetzliche Vertreter zu gelangen.",
        endingText:
          "Wenn Sie ein gesetzlicher Vertreter sind, der die Datenschutzanfrage im Namen einer Person einreicht, bitte",
        click: "klicken Sie hier",
        accessDescription: "Mich über die gespeicherten Angaben informieren",
        deletionDescription:
          "Meine sämtlichen personenbezogenen Informationen löschen",
        correctionDescription:
          "Meine personenbezogenen Informationen aktualisieren",
        optOutDescription: "Abmeldung von werblicher Kommunikation",
        otherDescription: "Sonstiges – Nachstehend beschreiben",
        deleteMyCortevaAccountDescription: "Mein Corteva-Konto löschen",
        description1:
          "Corteva Agriscience, kümmert sich um Ihren Datenschutz.  Ihre personenbezogenen Informationen (Name, e-Mail-Adresse, Telefonnummer sowie weitere Kontaktdaten) werden in ausgewählten Kundensystemen gespeichert, die im Wesentlichen in den Vereinigten Staaten gehostet sind. Diese Informationen werden von Unternehmen von Corteva Agriscience,  ihren Tochtergesellschaften, Partnerunternehmen und von ausgewählten Dritten in anderen Ländern verwendet, um Ihnen die angeforderten Produkt- oder Serviceinformationen zu liefern.  Weitere Informationen finden Sie auf der folgenden Website: ",
        description2:
          "Durch die Angabe Ihrer personenbezogenen Daten erklären Sie sich mit den Bedingungen und Konditionen dieser Datenschutzerklärung einverstanden.",
        url: " https://www.corteva.de/datenschutz-richtlinien.html",
        urlText: " https://www.corteva.de/datenschutz-richtlinien.html",
        customer: "Kunde/Bauer/Verteiler",
        employee: "Mitarbeiter/Bewerber/Ehemaliger Mitarbeiter/Auftragnehmer",
        vendor: "Lieferant",
      },
      legalRepresentative: {
        title: "Datenschutzanfrage",
        header: "Zur Verwendung durch den gesetzlichen Vertreter",
        introText:
          "Wenn Sie ein gesetzlicher Vertreter sind, der die Datenschutzanfrage im Namen einer Person einreicht, füllen Sie bitte das folgende Formular aus.",
        endingText: "Wenn Sie eine Anfrage für sich selbst stellen, bitte",
        click: "klicken Sie hier",
        accessDescription: "Mich über die gespeicherten Angaben informieren",
        deletionDescription:
          "Meine sämtlichen personenbezogenen Informationen löschen",
        correctionDescription:
          "Meine personenbezogenen Informationen aktualisieren",
        optOutDescription: "Abmeldung von werblicher Kommunikation",
        otherDescription: "Sonstiges – Nachstehend beschreiben",
        deleteMyCortevaAccountDescription: "Mein Corteva-Konto löschen",
        description1:
          "Corteva Agriscience, kümmert sich um Ihren Datenschutz.  Ihre personenbezogenen Informationen (Name, e-Mail-Adresse, Telefonnummer sowie weitere Kontaktdaten) werden in ausgewählten Kundensystemen gespeichert, die im Wesentlichen in den Vereinigten Staaten gehostet sind. Diese Informationen werden von Unternehmen von Corteva Agriscience,  ihren Tochtergesellschaften, Partnerunternehmen und von ausgewählten Dritten in anderen Ländern verwendet, um Ihnen die angeforderten Produkt- oder Serviceinformationen zu liefern.  Weitere Informationen finden Sie auf der folgenden Website: ",
        description2:
          "Durch die Angabe Ihrer personenbezogenen Daten erklären Sie sich mit den Bedingungen und Konditionen dieser Datenschutzerklärung einverstanden.",
        url: " https://www.corteva.de/datenschutz-richtlinien.html",
        urlText: " https://www.corteva.de/datenschutz-richtlinien.html",
        customer: "Kunde/Bauer/Verteiler",
        employee: "Mitarbeiter/Bewerber/Ehemaliger Mitarbeiter/Auftragnehmer",
        vendor: "Lieferant",
      },
      requestForm: {
        email: "e-Mail-Adresse",
        emailPlaceholder: "E-mail *",
        firstName: "Vorname",
        lastName: "Nachname",
        phone: "Telefonnummer",
        country: "Standort",
        countryPlaceholder: "Auswählen Standort *",
        robot: "eu não sou um robô",
        submitRequest: "Anfrage einreichen",
        errorText: "Eine gültige Nummer ist erforderlich",
        relationshipToCorteva: "Beziehung zu Corteva",
        customer: "Kunde/Bauer/Verteiler",
        employee: "Mitarbeiter/Bewerber/Ehemaliger Mitarbeiter/Auftragnehmer",
        vendor: "Lieferant",
        pleaseSpecify: "Bitte angeben *",
        company: "Firma",
        otherRequestDetails: "Bitte geben Sie Einzelheiten zu Ihrer Anfrage an",
        legalName: "Gesetzlicher Vertreter Vorname und Nachname",
        legalEmail: "Gesetzlicher Vertreter e-Mail-Adresse",
        legalRelation:
          "Gesetzlicher Vertreter Beziehung zu der von Ihnen vertretenen Person (Betroffene Person)",
        consumerRelation: "Betroffene Person Beziehung zu Corteva",
        consumerFirstName: "Betroffene Person Vorname",
        consumerLastName: "Betroffene Person Nachname",
        consumerCompany: "Betroffene Person Firma",
        consumerPhone: "Betroffene Person Telefonnummer",
        consumerEmail: "Betroffene Person e-Mail-Adresse",
        consumerCountry: "Betroffene Person Standort",
        required: "Dieses Feld ist erforderlich",
        minPhone: "Eine gültige Nummer ist erforderlich",
        formUploadDes: "Hochladen von Dokumenten des gesetzlichen Vertreters",
      },
      acknowledgement: {
        title: "Bestätigung:",
        ackText1: "Ihre Datenschutzanforderungs-ID lautet",
        ackText2: "Bitte bewahren Sie eine Kopie für Ihre Unterlagen auf.",
        ackText3:
          "Ein Bestätigungslink wird an die von Ihnen angegebene E-Mail-Adresse für alle Anfragetypen gesendet, außer für eine",
        unsubscribe: "Abmeldeanfrage",
        ackText4: "",
        ackText5:
          "Bitte überprüfen Sie Ihren Posteingang, um Ihre Anfrage zu bestätigen. Wenn Sie keine E-Mail von",
        ackText6:
          "erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner oder senden Sie Ihre Anfrage erneut. Vielen Dank.",
      },
      AADoc: {
        description1:
          "Ein gesetzlicher Vertreter ist eine Person, die befugt ist, eine Datenschutzanfrage im Namen einer anderen betroffenen Person als sich selbst einzureichen. Solche Aufzeichnungen sind für die Person zugänglich, auf die sie sich beziehen. Zum Schutz vor unbefugter Offenlegung müssen Sie Folgendes vorlegen: einen Identitätsnachweis sowohl für sich selbst als auch für die von Ihnen vertretene betroffene Person sowie eine Vollmacht oder eine schriftliche Vollmacht der von Ihnen vertretenen betroffenen Person.",
        heading: "Anweisungen zum Hochladen des Dokuments:",
        description2:
          "Bitte loggen Sie sich in das Datenschutz-Center ein, indem Sie auf die Schaltfläche „Ihre Anfrage anzeigen bzw. verwalten“ in der E-Mail mit der Betreffzeile „Datenschutzanfrage gestartet“ klicken.",
      },
      requestTypes: {
        contactReason: "Grund Ihrer Kontaktaufnahme",
      },
    },
  },
  el: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.gr/privacy-policy.html",
        urlText: "https://www.corteva.gr/privacy-policy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.gr/privacy-policy.html",
        urlText: "https://www.corteva.gr/privacy-policy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    el: {
      intakeForm: {
        title: "Αίτημα απορρήτου",
        introText:
          "Εάν υποβάλλετε αίτημα για τον εαυτό σας, παρακαλούμε συμπληρώστε την παρακάτω φόρμα. Εάν είστε νόμιμος εκπρόσωπος, κάντε κλικ στο κουμπί στο κάτω μέρος της οθόνης για να μεταβείτε στη φόρμα για τους νόμιμους εκπροσώπους.",
        endingText:
          "Εάν είστε νόμιμος εκπρόσωπος που υποβάλλει το αίτημα απορρήτου για λογαριασμό ενός προσώπου,",
        click: "κάντε κλικ εδώ",
        access: "access",
        accessDescription: "Ενημερώστε με για τις αποθηκευμένες πληροφορίες",
        deletion: "deletion",
        deletionDescription:
          "Διαγράψτε όλες τις προσωπικές πληροφορίες που σχετίζονται με εμένα",
        correction: "correction",
        correctionDescription: "Ενημερώστε τα προσωπικά μου στοιχεία",
        optOut: "optOut",
        optOutDescription: "Απεγραφή από επικοινωνίες μάρκετινγκ",
        other: "other",
        otherDescription: "Άλλο - Περιγράψτε παρακάτω<",
        description1:
          "Η Corteva Agriscince™ ενδιαφέρεται για την ιδιωτικότητά σας. Τα προσωπικά σας στοιχεία (όνομα, email, αριθμός τηλεφώνου και άλλα δεδομένα επικοινωνίας) θα αποθηκευτούν σε επιλεγμένα συστήματα πελατών που φιλοξενούνται κυρίως στις Ηνωμένες Πολιτείες. Αυτές οι πληροφορίες θα χρησιμοποιηθούν από τις επιχειρήσεις της Corteva Agriscience™, τις θυγατρικές της, τους συνεργάτες και επιλεγμένα τρίτα μέρη σε άλλες χώρες για να σας παρέχουν τις πληροφορίες για το προϊόν ή την υπηρεσία που ζητήσατε. Για να μάθετε περισσότερα, επισκεφθείτε τη διεύθυνση ",
        description2:
          "Με την παροχή των προσωπικών σας στοιχείων, συμφωνείτε με τους όρους και τις προϋποθέσεις αυτής της Δήλωσης Απορρήτου.",
        url: "https://www.corteva.gr/privacy-policy.html",
        urlText: "https://www.corteva.gr/privacy-policy.html",
        customer: "Πελάτης/Αγρότης/Διανομέας",
        employee: "Εργοδότης/Υποψήφιος/Πρώην υπάλληλος/εργολάβος",
        vendor: "Προμηθευτής",
      },
      legalRepresentative: {
        title: "Αίτημα απορρήτου",
        header: "Για χρήση από Νόμιμο Εκπρόσωπο",
        introText:
          "Εάν είστε νόμιμος εκπρόσωπος που υποβάλλει το αίτημα απορρήτου για λογαριασμό ενός προσώπου, παρακαλούμε συμπληρώστε την παρακάτω φόρμα.",
        endingText: "Εάν υποβάλλετε αίτημα για τον εαυτό σας, ",
        click: "κάντε κλικ εδώ",
        access: "access",
        accessDescription: "Ενημερώστε με για τις αποθηκευμένες πληροφορίες",
        deletion: "deletion",
        deletionDescription:
          "Διαγράψτε όλες τις προσωπικές πληροφορίες που σχετίζονται με εμένα",
        correction: "correction",
        correctionDescription: "Ενημερώστε τα προσωπικά μου στοιχεία",
        optOut: "optOut",
        optOutDescription: "Απεγραφή από επικοινωνίες μάρκετινγκ",
        other: "other",
        otherDescription: "Άλλο - Περιγράψτε παρακάτω<",
        description1:
          "Η Corteva Agriscince™ ενδιαφέρεται για την ιδιωτικότητά σας. Τα προσωπικά σας στοιχεία (όνομα, email, αριθμός τηλεφώνου και άλλα δεδομένα επικοινωνίας) θα αποθηκευτούν σε επιλεγμένα συστήματα πελατών που φιλοξενούνται κυρίως στις Ηνωμένες Πολιτείες. Αυτές οι πληροφορίες θα χρησιμοποιηθούν από τις επιχειρήσεις της Corteva Agriscience™, τις θυγατρικές της, τους συνεργάτες και επιλεγμένα τρίτα μέρη σε άλλες χώρες για να σας παρέχουν τις πληροφορίες για το προϊόν ή την υπηρεσία που ζητήσατε. Για να μάθετε περισσότερα, επισκεφθείτε τη διεύθυνση ",
        description2:
          "Με την παροχή των προσωπικών σας στοιχείων, συμφωνείτε με τους όρους και τις προϋποθέσεις αυτής της Δήλωσης Απορρήτου.",
        url: "https://www.corteva.gr/privacy-policy.html",
        urlText: "https://www.corteva.gr/privacy-policy.html",
        customer: "Πελάτης/Αγρότης/Διανομέας",
        employee: "Εργοδότης/Υποψήφιος/Πρώην υπάλληλος/εργολάβος",
        vendor: "Προμηθευτής",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Имейл *",
        firstName: "Όνομα",
        firstNamePlaceholder: "Nome *",
        lastName: "Επίθετο",
        lastNamePlaceholder: "Cognome *",
        phone: "Τηλέφωνο",
        phonePlaceholder: "Телефон *",
        country: "Τοποθεσία",
        countryPlaceholder: "Επιλέγω Локация *",
        robot: "Non sono un robot",
        submitRequest: "Υποβολή αιτήματος",
        errorText: "Απαιτείται έγκυρος αριθμός",
        relationshipToCorteva: "Σχέση με την Corteva",
        customer: "Πελάτης/Αγρότης/Διανομέας",
        employee: "Εργοδότης/Υποψήφιος/Πρώην υπάλληλος/εργολάβος",
        vendor: "Προμηθευτής",
        pleaseSpecify: "Διευκρινίστε παρακαλώ *",
        company: "Εταιρεία",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails:
          "Παρακαλούμε δώστε λεπτομέρειες σχετικά με το αίτημά σας",
        legalName: "Ονοματεπώνυμο νόμιμου εκπροσώπου",
        legalEmail: "Email νόμιμου εκπροσώπου",
        legalRelation:
          "Σχέση νόμιμου εκπροσώπου με το πρόσωπο που εκπροσωπείτε (Υποκείμενο των δεδομένων)",
        consumerRelation: "Σχέση του υποκειμένου των δεδομένων με την Corteva:",
        consumerFirstName: "Όνομα υποκειμένου των δεδομένων",
        consumerLastName: "Επώνυμο υποκειμένου των δεδομένων",
        consumerCompany: "Εταιρεία υποκειμένου των δεδομένων",
        consumerPhone: "Τηλέφωνο υποκειμένου των δεδομένων",
        consumerEmail: "Email υποκειμένου των δεδομένων",
        consumerCountry: "Χώρα υποκειμένου των δεδομένων",
        required: "Αυτό το πεδίο είναι υποχρεωτικό",
        minPhone: "Απαιτείται έγκυρος αριθμός",
        formUploadDes: "Μεταφόρτωση εγγράφων νόμιμου εκπροσώπου",
      },
      acknowledgement: {
        title: "Επιβεβαίωση",
        ackText1: "Το αναγνωριστικό (ID) της αίτησης απορρήτου σας είναι",
        ackText2: "Παρακαλούμε φυλάξτε ένα αντίγραφο για το αρχείο σας.",
        ackText3:
          "На посочения от Вас имейл адрес ще бъде изпратена връзка за потвърждение за всички типове заявки, с изключение на заявка за",
        unsubscribe: "отписване",
        ackText4: "",
        ackText5:
          "Ελέγξτε τα εισερχόμενά σας για να επαληθεύσετε το αίτημά σας. Αν δεν λάβατε email από τη διεύθυνση",
        ackText6:
          "ελέγξτε τον φάκελο ανεπιθύμητων ή υποβάλλετε ξανά το αίτημά σας. Σας ευχαριστούμε.",
      },
      AADoc: {
        description1:
          "Ο νόμιμος εκπρόσωπος είναι ένα άτομο που έχει εξουσιοδοτηθεί να υποβάλει αίτημα προστασίας δεδομένων για λογαριασμό ενός υποκειμένου δεδομένων, εκτός από τον ίδιο. Τα σχετικά αρχεία είναι προσβάσιμα στο πρόσωπο το οποίο αφορούν. Για προστασία από μη εξουσιοδοτημένη αποκάλυψη, απαιτείται η προσκόμιση: αποδεικτικού ταυτότητας τόσο για εσάς όσο και για το Υποκείμενο των δεδομένων που εκπροσωπείτε, καθώς και Πληρεξούσιο ή γραπτή εξουσιοδότηση από το Υποκείμενο των δεδομένων που εκπροσωπείτε.",
        heading: "Οδηγίες για τη μεταφόρτωση του εγγράφου:",
        description2:
          '"Συνδεθείτε στο Κέντρο Προστασίας Δεδομένων κάνοντας κλικ στο κουμπί «Προβολή ή διαχείριση του αιτήματος σας» στο μήνυμα ηλεκτρονικού ταχυδρομείου με θέμα «Έναρξη Αιτήματος Υποκειμένου Δεδομένων»."',
      },
      requestTypes: {
        contactReason: "Λόγος επικοινωνίας",
      },
    },
  },
  hu: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.hu/adatvedelem.html",
        urlText: "https://www.corteva.hu/adatvedelem.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.hu/adatvedelem.html",
        urlText: "https://www.corteva.hu/adatvedelem.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    hu: {
      intakeForm: {
        title: "Adatvédelmi kérés",
        introText:
          "Amennyiben a saját nevében nyújtja be a kérelmet, kérjük, töltse ki az alábbi űrlapot. Amennyiben Ön jogi képviselő, kérjük, kattintson a képernyő alján található gombra a jogi képviselők által kitöltendő űrlap eléréséhez.",
        endingText:
          "Amennyiben Ön az adatvédelmi kérelmet egy másik személy nevében benyújtó jogi képviselő, kérjük,",
        click: "kattintson ide",
        access: "access",
        accessDescription: "Tájékoztatást kérek a tárolt adatokról",
        deletion: "deletion",
        deletionDescription: "Valamennyi személyi adatom törlése",
        correction: "correction",
        correctionDescription: "Személyi adataim frissítése",
        optOut: "optOut",
        optOutDescription: "Leiratkozás további marketing kommunikációról",
        other: "other",
        otherDescription: "Egyéb – kérjük, lentebb részletezze",
        description1:
          "A Corteva Agriscience™ számára fontos adatai védelme. Személyes adatait (nevét, e-mail címét, telefonszámát és egyéb elérhetőségi adatait) elsősorban az Amerikai Egyesült Államokban található ügyfélrendszerben tároljuk.  Az adatokat a Corteva Agriscience ™, annak leányvállalatai, partnerei, illetve más országokban lévő, kijelölt harmadik felek használják fel a kért termékkel vagy szolgáltatással kapcsolatos tájékoztatás biztosításához. További információ a ",
        description2:
          "webhelyen található. Személyes adatainak rendelkezésre bocsátásával elfogadja az adatvédelmi nyilatkozat feltételeit.",
        url: "https://www.corteva.hu/adatvedelem.html",
        urlText: "https://www.corteva.hu/adatvedelem.html",
        customer: "Vevő/Gazda/Elosztó",
        employee: "Munkavállaló/Jelölt/Korábbi alkalmazott/Vállalkozó",
        vendor: "Eladó",
      },
      legalRepresentative: {
        title: "Adatvédelmi kérés",
        header: "For use by Legal Representative",
        introText:
          "Amennyiben Ön az adatvédelmi kérelmet egy másik személy nevében benyújtó jogi képviselő, kérjük, töltse ki az alábbi űrlapot.",
        endingText: "Amennyiben a saját nevében nyújtja be a kérelmet, kérjük,",
        click: "kattintson ide",
        access: "access",
        accessDescription: "Tájékoztatást kérek a tárolt adatokról",
        deletion: "deletion",
        deletionDescription: "Valamennyi személyi adatom törlése",
        correction: "correction",
        correctionDescription: "Személyi adataim frissítése",
        optOut: "optOut",
        optOutDescription: "Leiratkozás további marketing kommunikációról",
        other: "other",
        otherDescription: "Egyéb – kérjük, lentebb részletezze",
        description1:
          "A Corteva Agriscience™ számára fontos adatai védelme. Személyes adatait (nevét, e-mail címét, telefonszámát és egyéb elérhetőségi adatait) elsősorban az Amerikai Egyesült Államokban található ügyfélrendszerben tároljuk.  Az adatokat a Corteva Agriscience ™, annak leányvállalatai, partnerei, illetve más országokban lévő, kijelölt harmadik felek használják fel a kért termékkel vagy szolgáltatással kapcsolatos tájékoztatás biztosításához. További információ a ",
        description2:
          "webhelyen található. Személyes adatainak rendelkezésre bocsátásával elfogadja az adatvédelmi nyilatkozat feltételeit.",
        url: "https://www.corteva.hu/adatvedelem.html",
        urlText: "https://www.corteva.hu/adatvedelem.html",
        customer: "Vevő/Gazda/Elosztó",
        employee: "Munkavállaló/Jelölt/Korábbi alkalmazott/Vállalkozó",
        vendor: "Eladó",
      },
      requestForm: {
        email: "E-mail cím",
        emailPlaceholder: "Имейл *",
        firstName: "Utónév",
        firstNamePlaceholder: "Nome *",
        lastName: "Vezetéknév",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefonszám",
        phonePlaceholder: "Телефон *",
        country: "Hely",
        countryPlaceholder: "Válassz Hely *",
        robot: "Non sono un robot",
        submitRequest: "Kérelem elküldése",
        errorText: "Érvényes szám szükséges",
        relationshipToCorteva: "Kapcsolat Cortevával",
        customer: "Vevő/Gazda/Elosztó",
        employee: "Munkavállaló/Jelölt/Korábbi alkalmazott/Vállalkozó",
        vendor: "Eladó",
        pleaseSpecify: "Kérem részletezze *",
        company: "Vállalat",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Ismertesse részletesen kérelmét",
        legalName: "A jogi képviselő vezetékneve és utóneve",
        legalEmail: "Jogi képviselő e-mail-címe",
        legalRelation:
          "A jogi képviselő képviselt személyhez (érintetthez) fűződő kapcsolata",
        consumerRelation: "Az érintett Cortevához fűződő kapcsolata",
        consumerFirstName: "Az érintett utóneve",
        consumerLastName: "Az érintett vezetékneve",
        consumerCompany: "Az érintett vállalata",
        consumerPhone: "Az érintett telefonszáma",
        consumerEmail: "Az érintett e-mail-címe",
        consumerCountry: "Az érintett országa",
        required: "Ez a mező kötelező",
        minPhone: "Érvényes szám szükséges",
        formUploadDes: "Jogi képviselő dokumentációjának feltöltése",
      },
      acknowledgement: {
        title: "Visszaigazolás",
        ackText1: "Az adatvédelmi kérelmének azonosítója",
        ackText2: "Kérjük, mentsen le egy példányt saját maga számára.",
        ackText3:
          "На посочения от Вас имейл адрес ще бъде изпратена връзка за потвърждение за всички типове заявки, с изключение на заявка за",
        unsubscribe: "отписване",
        ackText4: "",
        ackText5:
          "A kérelme megerősítéséhez, kérjük, ellenőrizze beérkező üzeneteit. Ha nem kapott e-mailt a",
        ackText6:
          "címről, kérjük, ellenőrizze a spam mappát vagy küldje el újra a kérelmet. Köszönjük!",
      },
      AADoc: {
        description1:
          "A jogi képviselő olyan személy, aki felhatalmazással rendelkezik arra, hogy adatvédelmi kérelmet nyújtson be egy saját magán kívüli érintett nevében. Ezek a nyilvántartások azon személy számára férhetők hozzá, akire vonatkoznak. A jogosulatlan nyilvánosságra hozatal elleni védelem érdekében Önnek a következőket szükséges benyújtania: az Ön és az Ön által képviselt érintett személyazonosságát igazoló dokumentum, valamint az Ön által képviselt érintett által adott képviseleti meghatalmazás vagy írásos meghatalmazás.",
        heading: "Útmutató a dokumentum feltöltéséhez:",
        description2:
          "Kérjük, jelentkezzen be az Adatvédelmi Központba. Ehhez kattintson az „Érintett kérelme megkezdve” tárgyú e-mailben található „Kérelme megtekintése vagy kezelése” gombra.",
      },
      requestTypes: {
        contactReason: "Kapcsolatfelvétel oka",
      },
    },
  },
  ie: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.co.uk/privacy.html",
        urlText: "https://www.corteva.co.uk/privacy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.co.uk/privacy.html",
        urlText: "https://www.corteva.co.uk/privacy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
  },
  it: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.it/privacy-policy.html",
        urlText: "https://www.corteva.it/privacy-policy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.it/privacy-policy.html",
        urlText: "https://www.corteva.it/privacy-policy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    it: {
      intakeForm: {
        title: "Diritti sulla privacy",
        introText:
          "Se state inviando una richiesta per voi stessi, compilare il modulo sottostante. Se siete un Rappresentante legale, fate clic sul pulsante in fondo allo schermo per accedere al modulo per i Rappresentanti legali.",
        endingText:
          "Se siete un Rappresentante Legale che invia la richiesta di privacy per conto di una persona, fate",
        click: "clic qui",
        access: "access",
        accessDescription: "Informazione sui dati archiviati",
        deletion: "deletion",
        deletionDescription: "Cancellazione dei dati personali",
        correction: "correction",
        correctionDescription: "Aggiornamento dei dati personali",
        optOut: "optOut",
        optOutDescription:
          "Annulla l'iscrizione alle comunicazioni di marketing",
        other: "other",
        otherDescription: "Altro - specificare sotto",
        description1:
          "Per Corteva Agriscience™ la privacy dei clienti è importante. I dati personali (nome, e-mail, telefono e altre informazioni di contatto) saranno archiviati su sistemi informatici selezionati, situati principalmente negli Stati Uniti. Questi dati verranno utilizzati da Corteva Agriscience™, dai suoi affiliati e partner, oltre che da terze parti selezionate in altri paesi, per fornire le informazioni richieste su prodotti o servizi. Per maggiori informazioni, visitate il sito ",
        description2:
          "Fornendo i dati personali, si accettano i termini e le condizioni della presente informativa sulla privacy.",
        url: "https://www.corteva.it/privacy-policy.html",
        urlText: "https://www.corteva.it/privacy-policy.html",
        customer: "Cliente/Agricoltore/Distributore",
        employee: "Dipendente/Candidato/Ex Dipendente/Terzista",
        vendor: "Fornitore",
      },
      legalRepresentative: {
        title: "Diritti sulla privacy",
        header: "Ad uso del rappresentante legale",
        introText:
          "Se siete un Rappresentante Legale che presenta la richiesta di privacy per conto di una persona, compilate il modulo sottostante.",
        endingText: "Se state presentando una richiesta per voi stessi, fate",
        click: "clic qui",
        access: "access",
        accessDescription: "Informazione sui dati archiviati",
        deletion: "deletion",
        deletionDescription: "Cancellazione dei dati personali",
        correction: "correction",
        correctionDescription: "Aggiornamento dei dati personali",
        optOut: "optOut",
        optOutDescription:
          "Annulla l'iscrizione alle comunicazioni di marketing",
        other: "other",
        otherDescription: "Altro - specificare sotto",
        description1:
          "Per Corteva Agriscience™ la privacy dei clienti è importante. I dati personali (nome, e-mail, telefono e altre informazioni di contatto) saranno archiviati su sistemi informatici selezionati, situati principalmente negli Stati Uniti. Questi dati verranno utilizzati da Corteva Agriscience™, dai suoi affiliati e partner, oltre che da terze parti selezionate in altri paesi, per fornire le informazioni richieste su prodotti o servizi. Per maggiori informazioni, visitate il sito ",
        description2:
          "Fornendo i dati personali, si accettano i termini e le condizioni della presente informativa sulla privacy.",
        url: "https://www.corteva.it/privacy-policy.html",
        urlText: "https://www.corteva.it/privacy-policy.html",
        customer: "Cliente/Agricoltore/Distributore",
        employee: "Dipendente/Candidato/Ex Dipendente/Terzista",
        vendor: "Fornitore",
      },
      requestForm: {
        email: "E-mail",
        emailPlaceholder: "E-mail *",
        firstName: "Nome",
        firstNamePlaceholder: "Nome *",
        lastName: "Cognome",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefono",
        phonePlaceholder: "Telefono *",
        country: "Paese",
        countryPlaceholder: "Selezionare Paese *",
        robot: "Non sono un robot",
        submitRequest: "Invia la richiesta",
        errorText: "È richiesto un numero valido",
        relationshipToCorteva: "Relazione con Corteva",
        customer: "Cliente/Agricoltore/Distributore",
        employee: "Dipendente/Candidato/Ex Dipendente/Terzista",
        vendor: "Fornitore",
        pleaseSpecify: "Per favore specificare",
        company: "Azienda (ragione sociale)",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Fornire i dettagli della richiesta",
        legalName: "Nome e cognome del rappresentante legale",
        legalEmail: "E-mail del rappresentante legale",
        legalRelation:
          "Rapporto del Rappresentante Legale con la persona rappresentata (Interessato)",
        consumerRelation: "Rapporto dell'interessato con Corteva",
        consumerFirstName: "Nome dell'interessato",
        consumerLastName: "Cognome dell'interessato",
        consumerCompany: "Società interessato",
        consumerPhone: "Telefono dell'interessato",
        consumerEmail: "E-mail interessato",
        consumerCountry: "Paese interessato",
        required: "Questo campo è obbligatorio",
        minPhone: "È richiesto un numero valido",
        formUploadDes: "Carica la documentazione del rappresentante legale",
      },
      acknowledgement: {
        title: "Conferma",
        ackText1: "L'ID della richiesta di privacy è",
        ackText2: "Conservare una copia per il proprio archivio.",
        ackText3:
          "Per tutti i tipi di richiesta verrà inviato un link di verifica all'indirizzo e-mail fornito, ad eccezione di una",
        unsubscribe: "annullamento dell'iscrizione",
        ackText4: "richiesta di",
        ackText5:
          "Controlla la tua casella di posta per verificare la richiesta. Se non hai ricevuto un'e-mail da",
        ackText6:
          "controlla la cartella spam o invia di nuovo la richiesta. Grazie.",
      },
      AADoc: {
        description1:
          "Un rappresentante legale è una persona autorizzata a presentare una richiesta di privacy per conto di un soggetto interessato diverso da sé. Tali registrazioni sono accessibili alla persona a cui si riferiscono. Per tutelare la divulgazione non autorizzata, è necessario fornire: un documento d'identità per sé e per l'interessato che si rappresenta e una procura o un'autorizzazione scritta dell'interessato che si rappresenta.",
        heading: "Istruzioni per il caricamento del documento:",
        description2:
          'accedere al Data Privacy Center facendo clic sul pulsante "Visualizza o gestisci la tua richiesta" contenuto nell\'e-mail con oggetto "Richiesta dell\'interessato avviata"',
      },
      requestTypes: {
        contactReason: "Motivo del contatto",
      },
    },
  },
  lt: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.lt/privatumo-politika.html",
        urlText: "https://www.corteva.lt/privatumo-politika.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.lt/privatumo-politika.html",
        urlText: "https://www.corteva.lt/privatumo-politika.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    lt: {
      intakeForm: {
        title: "Privatumo užklausa",
        introText:
          "Jeigu paraišką teikiate savo vardu, užpildykite toliau pateikiamą formą. Jeigu esate teisinis atstovas, ekrano apačioje spustelėdami mygtuką pereikite į teisiniams atstovams skirtą formą.",
        endingText:
          "Jeigu esate teisinis atstovas, asmens vardu teikiantis privatumo paraišką,",
        click: "spustelėkite čia",
        access: "access",
        accessDescription: "Informuokite mane apie saugomus duomenis",
        deletion: "deletion",
        deletionDescription: "Ištrinkite visus su manimi susijusius duomenis",
        correction: "correction",
        correctionDescription: "Atnaujinkite mano asmeninę informaciją",
        optOut: "optOut",
        optOutDescription: "Atsisakyti prenumeratos",
        other: "other",
        otherDescription: "Kita, apibūdinkite žemiau",
        description1:
          "Bendrovė „Corteva Agriscience“ rūpinasi Jūsų privatumu. Jūsų asmeninė informacija (vardas, pavardė, el. paštas, telefono numeris ir kiti kontaktiniai duomenys) bus saugoma pasirinktose klientų sistemose, kurių pagrindinė vieta yra Jungtinėse Amerikos Valstijose. Šią informaciją naudos bendrovės „Corteva Agriscience“ padaliniai, jos filialai, partneriai ir pasirinktos trečiosios šalys kitose šalyse, kad galėtų Jums suteikti prašomą informaciją apie produktus ar paslaugas. Daugiau informacijos rasite adresu ",
        description2:
          "Suteikdami savo asmeninę informaciją, Jūs sutinkate su šio Privatumo pranešimo sąlygomis ir nuostatomis.",
        url: "https://www.corteva.lt/privatumo-politika.html",
        urlText: "https://www.corteva.lt/privatumo-politika.html",
        customer: "Klientas/ūkininkas/Platintojas",
        employee: "Darbuotojas/Kandidatas/Buvęs darbuotojas/Rangovas",
        vendor: "Pardavėjas",
      },
      legalRepresentative: {
        title: "Privatumo užklausa",
        header: "Skirta naudoti teisiniam atstovui",
        introText:
          "Jeigu esate teisinis atstovas, privatumo paraišką teikiantis asmens vardu, užpildykite toliau pateikiamą formą.",
        endingText: "Jeigu paraišką teikiate savo vardu,",
        click: "spustelėkite čia",
        access: "access",
        accessDescription: "Informuokite mane apie saugomus duomenis",
        deletion: "deletion",
        deletionDescription: "Ištrinkite visus su manimi susijusius duomenis",
        correction: "correction",
        correctionDescription: "Atnaujinkite mano asmeninę informaciją",
        optOut: "optOut",
        optOutDescription: "Atsisakyti prenumeratos",
        other: "other",
        otherDescription: "Kita, apibūdinkite žemiau",
        description1:
          "Bendrovė „Corteva Agriscience“ rūpinasi Jūsų privatumu. Jūsų asmeninė informacija (vardas, pavardė, el. paštas, telefono numeris ir kiti kontaktiniai duomenys) bus saugoma pasirinktose klientų sistemose, kurių pagrindinė vieta yra Jungtinėse Amerikos Valstijose. Šią informaciją naudos bendrovės „Corteva Agriscience“ padaliniai, jos filialai, partneriai ir pasirinktos trečiosios šalys kitose šalyse, kad galėtų Jums suteikti prašomą informaciją apie produktus ar paslaugas. Daugiau informacijos rasite adresu  ",
        description2:
          "Suteikdami savo asmeninę informaciją, Jūs sutinkate su šio Privatumo pranešimo sąlygomis ir nuostatomis.",
        url: "https://www.corteva.lt/privatumo-politika.html",
        urlText: "https://www.corteva.lt/privatumo-politika.html",
        customer: "Klientas/ūkininkas/Platintojas",
        employee: "Darbuotojas/Kandidatas/Buvęs darbuotojas/Rangovas",
        vendor: "Pardavėjas",
      },
      requestForm: {
        email: "El.pašto adresas",
        emailPlaceholder: "Имейл *",
        firstName: "Vardas",
        firstNamePlaceholder: "Nome *",
        lastName: "Pavardė",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefonas",
        phonePlaceholder: "Телефон *",
        country: "Šalis",
        countryPlaceholder: "Pasirinkite šalį *",
        robot: "Non sono un robot",
        submitRequest: "Pateikti paraišką",
        errorText: "Reikalingas galiojantis numeris",
        relationshipToCorteva: "Santykiai su Corteva",
        customer: "Klientas/ūkininkas/Platintojas",
        employee: "Darbuotojas/Kandidatas/Buvęs darbuotojas/Rangovas",
        vendor: "Pardavėjas",
        pleaseSpecify: "Prašome nurodyti *",
        company: "Įmonė",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Prašau, pateikite informaciją apie savo užklausą",
        legalName: "Teisinio atstovo vardas ir pavardė",
        legalEmail: "Teisinio atstovo el. pašto adresas",
        legalRelation:
          "Teisinio atstovo ryšys su atstovaujamuoju asmeniu (duomenų subjektu)",
        consumerRelation: "Duomenų subjekto ryšys su Corteva",
        consumerFirstName: "Duomenų subjekto vardas",
        consumerLastName: "Duomenų subjekto pavardė",
        consumerCompany: "Duomenų subjekto įmonė",
        consumerPhone: "Duomenų subjekto telefono numeris",
        consumerEmail: "Duomenų subjekto el. pašto adresas",
        consumerCountry: "Duomenų subjekto šalis",
        required: "Šį lauką būtina užpildyti",
        minPhone: "Reikalingas galiojantis numeris",
        formUploadDes: "Teisinio atstovo dokumentų įkėlimas",
      },
      acknowledgement: {
        title: "Patvirtinimas",
        ackText1: "jūsų privatumo paraiškos identifikatorius yra",
        ackText2: "Saugokite kopiją savo reikmėms.",
        ackText3: "",
        unsubscribe: "",
        ackText4: "",
        ackText5:
          "Kad patvirtintumėte paraišką, patikrinkite savo pašto dėžutę. Jeigu negavote el. laiško iš",
        ackText6:
          "patikrinkite brukalų aplanką arba iš naujo pateikite paraišką. Dėkojame.",
      },
      AADoc: {
        description1:
          "Teisinis atstovas yra asmuo, įgaliotas ne savo, o duomenų subjekto vardu pateikti paraišką dėl duomenų privatumo. Tokie įrašai prieinami su jais susijusiam asmeniui. Siekiant apsisaugoti nuo neteisėto atskleidimo, privalote pateikti: savo ir jūsų atstovaujamo duomenų subjekto tapatybę patvirtinantį dokumentą ir įgaliojimą arba jūsų atstovaujamo duomenų subjekto raštišką įgaliojimą.",
        heading: "Dokumento įkėlimo nurodymai:",
        description2:
          "el. laiške, kurio tema „Pradėta duomenų subjekto paraiška“, spustelėdami mygtuką „Peržiūrėti arba tvarkyti paraišką“ prisijunkite prie duomenų privatumo centro.",
      },
      requestTypes: {
        contactReason: "Susisiekimo tikslas",
      },
    },
  },
  lv: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.lv/privatums.html",
        urlText: "https://www.corteva.lv/privatums.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.lv/privatums.html",
        urlText: "https://www.corteva.lv/privatums.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    lv: {
      intakeForm: {
        title: "Privātuma pieprasījums",
        introText:
          "Ja iesniedzat pieprasījumu par sevi, lūdzu, aizpildiet tālāk norādīto veidlapu. Ja esat pilnvarotais pārstāvis, noklikšķiniet uz pogas ekrāna apakšā, lai atvērtu pilnvarotā pārstāvju veidlapu.",
        endingText:
          "Ja esat pilnvarotais pārstāvis, kas iesniedz privātuma pieprasījumu personas vārdā, lūdzu,",
        click: "noklikšķiniet šeit",
        access: "access",
        accessDescription: "Informējiet mani par glabāto informāciju",
        deletion: "deletion",
        deletionDescription:
          "Dzēsiet visu ar mani saistīto personisko informāciju",
        correction: "correction",
        correctionDescription: "Atjauniniet manu personisko informāciju",
        optOut: "optOut",
        optOutDescription: "Anulējiet mārketinga informācijas abonementu",
        other: "other",
        otherDescription: "Cits – aprakstīt zemāk",
        description1:
          "Corteva Agriscience™ rūpējas par jūsu privātumu. Jūsu personiskā informācija (vārds, e-pasta adrese, tālruņa numurs un cita kontaktinformācija) tiks glabāta izvēlētajās klientu sistēmās, kas galvenokārt tiek uzturētas ASV. Šo informāciju izmantos Corteva Agriscience™ , tās saistītie uzņēmumi, partneri un atsevišķas trešās puses citās valstīs, lai nodrošinātu jums pieprasīto produktu vai pakalpojumu informāciju. Lai uzzinātu vairāk, lūdzu, apmeklējiet vietni ",
        description2:
          "Sniedzot savu personisko informāciju, jūs piekrītat šī privātuma paziņojuma noteikumiem un nosacījumiem.",
        url: "https://www.corteva.lv/privatums.html",
        urlText: "https://www.corteva.lv/privatums.html",
        customer: "Klients/Zemnieks/Izplatītājs",
        employee: "Darbinieks/Kandidāts/Bijušais darbinieks/Darbuzņēmējs",
        vendor: "Pārdevējs",
      },
      legalRepresentative: {
        title: "Privātuma pieprasījums",
        header: "Izmantošanai juridiskajam pārstāvim",
        introText:
          "Ja esat pilnvarotais pārstāvis, kas iesniedz privātuma pieprasījumu personas vārdā, lūdzu, aizpildiet tālāk norādīto veidlapu.",
        endingText: "Ja iesniedzat pieprasījumu par sevi, lūdzu,",
        click: "noklikšķiniet šeit",
        access: "access",
        accessDescription: "Informējiet mani par glabāto informāciju",
        deletion: "deletion",
        deletionDescription:
          "Dzēsiet visu ar mani saistīto personisko informāciju",
        correction: "correction",
        correctionDescription: "Atjauniniet manu personisko informāciju",
        optOut: "optOut",
        optOutDescription: "Anulējiet mārketinga informācijas abonementu",
        other: "other",
        otherDescription: "Cits – aprakstīt zemāk",
        description1:
          "Corteva Agriscience™ rūpējas par jūsu privātumu. Jūsu personiskā informācija (vārds, e-pasta adrese, tālruņa numurs un cita kontaktinformācija) tiks glabāta izvēlētajās klientu sistēmās, kas galvenokārt tiek uzturētas ASV. Šo informāciju izmantos Corteva Agriscience™ , tās saistītie uzņēmumi, partneri un atsevišķas trešās puses citās valstīs, lai nodrošinātu jums pieprasīto produktu vai pakalpojumu informāciju. Lai uzzinātu vairāk, lūdzu, apmeklējiet vietni ",
        description2:
          "Sniedzot savu personisko informāciju, jūs piekrītat šī privātuma paziņojuma noteikumiem un nosacījumiem.",
        url: "https://www.corteva.lv/privatums.html",
        urlText: "https://www.corteva.lv/privatums.html",
        customer: "Klients/Zemnieks/Izplatītājs",
        employee: "Darbinieks/Kandidāts/Bijušais darbinieks/Darbuzņēmējs",
        vendor: "Pārdevējs",
      },
      requestForm: {
        email: "E-pasts",
        emailPlaceholder: "Имейл *",
        firstName: "Vārds",
        firstNamePlaceholder: "Nome *",
        lastName: "Uzvārds",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefons",
        phonePlaceholder: "Телефон *",
        country: "valsts",
        countryPlaceholder: "Izvēlies valsti *",
        robot: "Non sono un robot",
        submitRequest: "Iesniegt pieprasījumu",
        errorText: "Nepieciešams derīgs numurs",
        relationshipToCorteva: "Attiecības ar Corteva",
        customer: "Klients/Zemnieks/Izplatītājs",
        employee: "Darbinieks/Kandidāts/Bijušais darbinieks/Darbuzņēmējs",
        vendor: "Pārdevējs",
        pleaseSpecify: "Lūdzu norādiet *",
        company: "Uzņēmums",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails:
          "Lūdzu, norādiet informāciju par savu pieprasījumu",
        legalName: "Pilnvarotā pārstāvja vārds un uzvārds",
        legalEmail: "Pilnvarotā pārstāvja e-pasts",
        legalRelation:
          "Pilnvarotā pārstāvja attiecības ar personu, kuru jūs pārstāvat (datu subjekts)",
        consumerRelation: "Datu subjekta saistība ar Corteva",
        consumerFirstName: "Datu subjekta vārds",
        consumerLastName: "Datu subjekta uzvārds",
        consumerCompany: "Datu subjekta uzņēmums",
        consumerPhone: "Datu subjekta tālrunis",
        consumerEmail: "Datu subjekts e-pasts",
        consumerCountry: "Datu subjekta valsts",
        required: "Šis lauks ir obligāts",
        minPhone: "Nepieciešams derīgs numurs",
        formUploadDes: "Juridiskā pārstāvja dokumentu augšupielāde",
      },
      acknowledgement: {
        title: "Apstiprinājums",
        ackText1: "Jūsu privātuma pieprasījuma ID ir",
        ackText2: "Lūdzu, saglabājiet kopiju savā lietvedībā.",
        ackText3: "",
        unsubscribe: "",
        ackText4: "",
        ackText5:
          "Lūdzu, pārbaudiet iesūtni, lai apstiprinātu savu pieprasījumu. Ja nesaņēmāt e-pasta ziņojumu no",
        ackText6:
          "lūdzu, pārbaudiet surogātpasta mapi vai atkārtoti iesniedziet pieprasījumu. Paldies.",
      },
      AADoc: {
        description1:
          "Pilnvarotais pārstāvis ir persona, kas ir pilnvarota iesniegt datu privātuma pieprasījumu datu subjekta vārdā, kas nav viņš pats. Šādi ieraksti ir pieejami personai, uz kuru tie attiecas. Lai aizsargātu pret neatļautu izpaušanu, jums ir jāiesniedz jūsu un jūsu pārstāvētā datu subjekta identitāti apliecinošs dokuments, kā arī pilnvara vai jūsu pārstāvētā datu subjekta rakstiska atļauja.",
        heading: "Norādījumi par dokumenta augšupielādi:",
        description2:
          'Lūdzu, piesakieties Datu privātuma centrā, noklikšķinot uz pogas "Skatīt vai pārvaldīt pieprasījumu", e-pastā ar temata rindiņu "Datu subjekta pieprasījuma sākšana".',
      },
      requestTypes: {
        contactReason: "Sazināšanās iemesls",
      },
    },
  },
  mld: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.ro/politica-de-confidentialitate.html",
        urlText: "https://www.corteva.ro/politica-de-confidentialitate.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.ro/politica-de-confidentialitate.html",
        urlText: "https://www.corteva.ro/politica-de-confidentialitate.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name And Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship With The Person You Represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    mld: {
      intakeForm: {
        title: "Solicitare de confidențialitate",
        introText:
          "Dacă depuneți o cerere în nume propriu, completați formularul de mai jos. Dacă sunteți un reprezentant legal, faceți clic pe butonul din partea de jos a ecranului pentru a accesa formularul pentru reprezentanții legali.",
        endingText:
          "Dacă sunteți un reprezentant legal care depune cererea privind confidențialitatea în numele unei persoane,",
        click: "faceți clic aici",
        access: "access",
        accessDescription: "Aduceți-mi la cunoștință informațiile stocate",
        deletion: "deletion",
        deletionDescription: "Ștergeți toate informațiile personale",
        correction: "correction",
        correctionDescription: "Actualizați datele personale",
        optOut: "optOut",
        optOutDescription: "Dezabonare de la comunicarile de marketing",
        other: "other",
        otherDescription: "Altă variantă - Descrieți mai jos",
        description1:
          "Corteva Agriscience™ îi pasă de confidențialitatea dumneavoastră. Informațiile dumneavoastră personale (nume, e-mail, număr de telefon și alte date de contact) vor fi stocate în sisteme ale clienților găzduite în principal în Statele Unite. Aceste informații vor fi folosite de companiile Corteva Agriscience™, afiliații săi, partenerii și terții selectați din alte țări pentru a vă furniza informațiile despre produse sau servicii solicitate. Pentru a afla mai multe, vă rugăm să vizitați ",
        description2:
          "Prin furnizarea informațiilor dumneavoastră personale, sunteți de acord cu termenii și condițiile acestei Declarații de confidențialitate.",
        url: "https://www.corteva.ro/politica-de-confidentialitate.html",
        urlText: "https://www.corteva.ro/politica-de-confidentialitate.html",
        customer: "Client/Agricultor/Distribuitor",
        employee: "Angajat/Solicitant/Fost angajat/Contractant",
        vendor: "Vânzător",
      },
      legalRepresentative: {
        title: "Solicitare de confidențialitate",
        header: "Pentru utilizare de către Reprezentantul Legal",
        introText:
          "Dacă sunteți un reprezentant legal care depune cererea privind confidențialitatea în numele unei persoane, completați formularul de mai jos.",
        endingText: "Dacă depuneți o cerere în nume propriu,",
        click: "faceți clic aici",
        access: "access",
        accessDescription: "Aduceți-mi la cunoștință informațiile stocate",
        deletion: "deletion",
        deletionDescription: "Ștergeți toate informațiile personale",
        correction: "correction",
        correctionDescription: "Actualizați datele personale",
        optOut: "optOut",
        optOutDescription: "Dezabonare de la comunicarile de marketing",
        other: "other",
        otherDescription: "Altă variantă - Descrieți mai jos",
        description1:
          "Corteva Agriscience™ îi pasă de confidențialitatea dumneavoastră. Informațiile dumneavoastră personale (nume, e-mail, număr de telefon și alte date de contact) vor fi stocate în sisteme ale clienților găzduite în principal în Statele Unite. Aceste informații vor fi folosite de companiile Corteva Agriscience™, afiliații săi, partenerii și terții selectați din alte țări pentru a vă furniza informațiile despre produse sau servicii solicitate. Pentru a afla mai multe, vă rugăm să vizitați ",
        description2:
          "Prin furnizarea informațiilor dumneavoastră personale, sunteți de acord cu termenii și condițiile acestei Declarații de confidențialitate.",
        url: "https://www.corteva.ro/politica-de-confidentialitate.html",
        urlText: "https://www.corteva.ro/politica-de-confidentialitate.html",
        customer: "Client/Agricultor/Distribuitor",
        employee: "Angajat/Solicitant/Fost angajat/Contractant",
        vendor: "Vânzător",
      },
      requestForm: {
        email: "E-mail",
        emailPlaceholder: "E-mail *",
        firstName: "Nume",
        firstNamePlaceholder: "Nome *",
        lastName: "Prenume",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefon",
        phonePlaceholder: "Telefono *",
        country: "Locație",
        countryPlaceholder: "Selectați Locație *",
        robot: "Non sono un robot",
        submitRequest: "Depunere cerere",
        errorText: "Este necesar un număr valid",
        relationshipToCorteva: "Relația cu Corteva",
        customer: "Client/Agricultor/Distribuitor",
        employee: "Angajat/Solicitant/Fost angajat/Contractant",
        vendor: "Vânzător",
        pleaseSpecify: "Vă rugăm să specificați",
        company: "Companie",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails:
          "Vă rugăm să ne furnizați detalii despre solicitarea dvs",
        legalName: "Numele și prenumele reprezentantului legal",
        legalEmail: "E-mailul reprezentantului legal",
        legalRelation:
          "Relația reprezentantului legal cu persoana pe care o reprezentați (persoana vizată)",
        consumerRelation: "Relația persoanei vizate cu Corteva",
        consumerFirstName: "Prenumele persoanei vizate",
        consumerLastName: "Numele persoanei vizate",
        consumerCompany: "Compania persoanei vizate",
        consumerPhone: "Numărul de telefon al persoanei vizate",
        consumerEmail: "E-mailul persoanei vizate",
        consumerCountry: "Țara persoanei vizate",
        required: "Acest câmp este obligatoriu",
        minPhone: "Este necesar un număr valid",
        formUploadDes: "Încărcați documentația Reprezentantului legal",
      },
      acknowledgement: {
        title: "Confirmare",
        ackText1: "ID-ul cererii dvs. privind confidențialitatea este",
        ackText2: "Păstrați o copie pentru arhiva dvs.",
        ackText3:
          "Se va trimite un link de verificare pe adresa de e-mail pe care ne-ați furnizat-o, pentru toate tipurile de solicitări, cu excepția",
        unsubscribe: "dezabonare",
        ackText4: "cererii de",
        ackText5:
          "Accesați-vă inboxul pentru a verifica cererea. Dacă nu ați primit un e-mail de la",
        ackText6:
          "verificați folderul spam sau retrimiteți cererea. Vă mulțumim.",
      },
      AADoc: {
        description1:
          "Un reprezentant legal este o persoană împuternicită să depună o cerere privind confidențialitatea datelor în numele unei persoane vizate, alta decât propria persoană. Aceste înregistrări sunt accesibile persoanei la care se referă. Pentru a proteja divulgarea neautorizată, este necesar să furnizați: o dovadă a identității atât pentru dumneavoastră, cât și pentru persoana vizată pe care o reprezentați, precum și o împuternicire sau o autorizație scrisă din partea persoanei vizate pe care o reprezentați.",
        heading: "Instrucțiuni pentru încărcarea documentului",
        description2:
          "Conectați-vă la Centrul pentru confidențialitatea datelor făcând clic pe butonul „Vizualizați sau gestionați-vă cererea” din e-mailul cu subiectul „Cererea privind persoana vizată a fost inițiată”.",
      },
      requestTypes: {
        contactReason: "Motivul solicitării",
      },
    },
  },
  nl: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.nl/privacy.html",
        urlText: "https://www.corteva.nl/privacy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.nl/privacy.html",
        urlText: "https://www.corteva.nl/privacy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    nl: {
      intakeForm: {
        title: "Privacyverzoek",
        introText:
          "Als u een verzoek voor uzelf indient, vul dan onderstaand formulier in. Als u een Wettelijke vertegenwoordiger bent, klik dan op de knop onderaan het scherm om naar het formulier voor Wettelijke vertegenwoordigers te gaan.",
        endingText:
          "Als u een Wettelijke vertegenwoordiger bent die het privacyverzoek namens iemand anders indient,",
        click: "klik dan hier",
        access: "access",
        accessDescription: "Informeer mij",
        deletion: "deletion",
        deletionDescription: "Verwijder mijn personlijke informatie",
        correction: "correction",
        correctionDescription: "Persoonlijke informatie aanpassen",
        optOut: "optOut",
        optOutDescription: "Uitschrijven uit marketingcommunicatie",
        other: "other",
        otherDescription:
          "Andere – omschrijf uw vraag bij ‘Omschrijf’ uw vraag",
        description1:
          "Corteva Agriscience™ hecht veel belang aan uw privacy. Uw persoonlijke gegevens (naam, adres, email, telefoonnummer en andere gegevens) zullen opgeslagen worden in klantensystemen die door ons gekozen werden en die voornamelijk gehost zijn in de VS. Deze informatie zal gebruikt worden door Corteva Agriscience™, zijn filialen, partners en gekozen third parties in andere landen – met als doel u van de juiste productinformatie of service te voorzien. Ga voor meer informatie naar ",
        description2:
          "Door persoonsgegevens te verstrekken, accepteert u de voorwaarden van dit privacybeleid.",
        url: "https://www.corteva.nl/privacy.html",
        urlText: "https://www.corteva.nl/privacy.html",
        customer: "Klant/Boer/Verdeler",
        employee: "Werknemer/Sollicitant/Voormalig medewerker/Aannemer",
        vendor: "Verkoper",
      },
      legalRepresentative: {
        title: "Privacyverzoek",
        header: "Voor gebruik door wettelijke vertegenwoordiger",
        introText:
          "Als u een Wettelijke vertegenwoordiger bent die het privacyverzoek namens iemand anders indient, vul dan onderstaand formulier in.",
        endingText: "Als u een verzoek voor uzelf indient,",
        click: "klik dan hier",
        access: "access",
        accessDescription: "Informeer mij",
        deletion: "deletion",
        deletionDescription: "Verwijder mijn personlijke informatie",
        correction: "correction",
        correctionDescription: "Persoonlijke informatie aanpassen",
        optOut: "optOut",
        optOutDescription: "Uitschrijven uit marketingcommunicatie",
        other: "other",
        otherDescription:
          "Andere – omschrijf uw vraag bij ‘Omschrijf’ uw vraag",
        description1:
          "Corteva Agriscience™ hecht veel belang aan uw privacy. Uw persoonlijke gegevens (naam, adres, email, telefoonnummer en andere gegevens) zullen opgeslagen worden in klantensystemen die door ons gekozen werden en die voornamelijk gehost zijn in de VS. Deze informatie zal gebruikt worden door Corteva Agriscience™, zijn filialen, partners en gekozen third parties in andere landen – met als doel u van de juiste productinformatie of service te voorzien. Ga voor meer informatie naar ",
        description2:
          "Door persoonsgegevens te verstrekken, accepteert u de voorwaarden van dit privacybeleid.",
        url: "https://www.corteva.nl/privacy.html",
        urlText: "https://www.corteva.nl/privacy.html",
        customer: "Klant/Boer/Verdeler",
        employee: "Werknemer/Sollicitant/Voormalig medewerker/Aannemer",
        vendor: "Verkoper",
      },
      requestForm: {
        email: "E-mail",
        emailPlaceholder: "E-mail *",
        firstName: "Voornaam",
        firstNamePlaceholder: "Nome *",
        lastName: "Achternaam",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefoon",
        phonePlaceholder: "Telefono *",
        country: "Plaats",
        countryPlaceholder: "Selecteer Plaats *",
        robot: "Non sono un robot",
        submitRequest: "Verzoek indienen",
        errorText: "Een geldig nummer is vereist",
        relationshipToCorteva: "Relatie met Corteva",
        customer: "Klant/Boer/Verdeler",
        employee: "Werknemer/Sollicitant/Voormalig medewerker/Aannemer",
        vendor: "Verkoper",
        pleaseSpecify: "Gelieve te specificeren",
        company: "Bedrijf",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Omschrijf uw vraag",
        legalName: "Voornaam en achternaam van Wettelijke vertegenwoordiger",
        legalEmail: "E-mailadres van Wettelijke vertegenwoordiger",
        legalRelation:
          "Relatie van de Wettelijk vertegenwoordiger met degene die u vertegenwoordigt (Betrokkene)",
        consumerRelation: "Relatie tussen Betrokkene en Corteva:",
        consumerFirstName: "Voornaam van Betrokkene",
        consumerLastName: "Achternaam van Betrokkene",
        consumerCompany: "Bedrijf van Betrokkene",
        consumerPhone: "Telefoonnummer van Betrokkene",
        consumerEmail: "E-mailadres van Betrokkene",
        consumerCountry: "Land van Betrokkene",
        required: "dit veld is verplicht",
        minPhone: "Een geldig nummer is vereist",
        formUploadDes:
          "Documentatie van wettelijke vertegenwoordigers uploaden",
      },
      acknowledgement: {
        title: "Bevestiging",
        ackText1: "Uw Privacyverzoek-id is",
        ackText2: "Bewaar een kopie voor uw administratie.",
        ackText3: "Voor alle aanvragen, behalve voor",
        unsubscribe: "afmeldingen,",
        ackText4:
          "wordt een verificatiekoppeling verzonden naar het door u opgegeven e-mailadres",
        ackText5:
          "Ga naar uw Postvak IN om uw aanvraag te verifiëren. Als u geen e-mail hebt ontvangen van",
        ackText6:
          "controleer dan uw map met ongewenste berichten of dien uw verzoek opnieuw in. Bedankt.",
      },
      AADoc: {
        description1:
          "Een Wettelijke vertegenwoordiger is iemand die gemachtigd is om namens een andere Betrokkene dan zichzelf een verzoek om gegevensbescherming in te dienen. Deze dossiers zijn toegankelijk voor de Persoon op wie deze betrekking hebben. Om onbevoegde openbaarmaking te verhinderen, moet u het volgende verstrekken: een identiteitsbewijs van uzelf en de Betrokkene die u vertegenwoordigt, evenals een Volmacht of een schriftelijke machtiging van de Betrokkene die u vertegenwoordigt.",
        heading: "Instructies voor het uploaden van het document:",
        description2:
          "Meld u aan bij het Centrum voor gegevensprivacy door te klikken op de knop 'Uw verzoek bekijken of beheren' in de e-mail met als onderwerp \"Verzoek door een betrokkene gestart\".",
      },
      requestTypes: {
        contactReason: "Uw vraag",
      },
    },
  },
  pl: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.pl/polityka-prywatnosci.html",
        urlText: "https://www.corteva.pl/polityka-prywatnosci.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.pl/polityka-prywatnosci.html",
        urlText: "https://www.corteva.pl/polityka-prywatnosci.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    pl: {
      intakeForm: {
        title: "Prośba o prywatność",
        introText:
          "Pełnomocnik, który w imieniu własnym przesyła wniosek dotyczący prywatności, wypełnia poniższy formularz. Aby pobrać wniosek dla Pełnomocników, należy kliknąć przycisk na dole ekranu.",
        endingText:
          "Pełnomocnik, który w imieniu mocodawcy składa wniosek dotyczący prywatności,",
        click: "klika tutaj",
        access: "access",
        accessDescription: "Informacja o miejscu przechowywania informacji",
        deletion: "deletion",
        deletionDescription: "Usunięcie wszystkich moich danych osobowych",
        correction: "correction",
        correctionDescription: "Zmiana moich danych osobowych",
        optOut: "optOut",
        optOutDescription: "Wypisz się z komunikacji marketingowej",
        other: "other",
        otherDescription: "Inna — opisz poniżej",
        description1:
          "Corteva Agriscience™ dba o Państwa prywatność. Państwa dane osobowe (imię i nazwisko, adres e-mail, numer telefonu i inne informacje kontaktowebędą przechowywane w wybranych miejscach znajdujących się przede wszystkim na terytorium Stanów Zjednoczonych. Informacje te będą używane przez firmy Corteva Agriscience™, jej podmioty powiązane, jej partnerów oraz wybrane osoby trzecie w innych krajach do przekazywania Państwu informacji na temat produktów lub usług. Aby dowiedzieć się więcej, odwiedź stronę ",
        description2:
          "Podając swoje dane osobowe, zgadzasz się na warunki niniejszego Oświadczenia o ochronie prywatności.",
        url: "https://www.corteva.pl/polityka-prywatnosci.html",
        urlText: "https://www.corteva.pl/polityka-prywatnosci.html",
        customer: "Klient/Rolnik/Dystrybutor",
        employee: "Pracownik/Kandydat/Były pracownik/Kontrahent",
        vendor: "Dostawca",
      },
      legalRepresentative: {
        title: "Prośba o prywatność",
        header: "Do użytku przez przedstawiciela prawnego",
        introText:
          "Pełnomocnik, który w imieniu mocodawcy składa wniosek dotyczący prywatności, wypełnia poniższy formularz.",
        endingText: "Aby złożyć wniosek we własnym imieniu, należy",
        click: "klikać tutaj",
        access: "access",
        accessDescription: "Informacja o miejscu przechowywania informacji",
        deletion: "deletion",
        deletionDescription: "Usunięcie wszystkich moich danych osobowych",
        correction: "correction",
        correctionDescription: "Zmiana moich danych osobowych",
        optOut: "optOut",
        optOutDescription: "Wypisz się z komunikacji marketingowej",
        other: "other",
        otherDescription: "Inna — opisz poniżej",
        description1:
          "Corteva Agriscience™ dba o Państwa prywatność. Państwa dane osobowe (imię i nazwisko, adres e-mail, numer telefonu i inne informacje kontaktowebędą przechowywane w wybranych miejscach znajdujących się przede wszystkim na terytorium Stanów Zjednoczonych. Informacje te będą używane przez firmy Corteva Agriscience™, jej podmioty powiązane, jej partnerów oraz wybrane osoby trzecie w innych krajach do przekazywania Państwu informacji na temat produktów lub usług. Aby dowiedzieć się więcej, odwiedź stronę ",
        description2:
          "Podając swoje dane osobowe, zgadzasz się na warunki niniejszego Oświadczenia o ochronie prywatności.",
        url: "https://www.corteva.pl/polityka-prywatnosci.html",
        urlText: "https://www.corteva.pl/polityka-prywatnosci.html",
        customer: "Klient/Rolnik/Dystrybutor",
        employee: "Pracownik/Kandydat/Były pracownik/Kontrahent",
        vendor: "Dostawca",
      },
      requestForm: {
        email: "Adres e-mail",
        emailPlaceholder: "E-mail *",
        firstName: "Imię",
        firstNamePlaceholder: "Nome *",
        lastName: "Nazwisko",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefon",
        phonePlaceholder: "Telefono *",
        country: "Lokalizacja",
        countryPlaceholder: "Wybierz Lokalizacja *",
        robot: "Non sono un robot",
        submitRequest: "Wniosek należy przesłać",
        errorText: "Wymagany jest prawidłowy numer",
        relationshipToCorteva: "Związek z Corteva",
        customer: "Klient/Rolnik/Dystrybutor",
        employee: "Pracownik/Kandydat/Były pracownik/Kontrahent",
        vendor: "Dostawca",
        pleaseSpecify: "Per favore specificare",
        company: "Firma",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Podaj informacje dotyczące zapytania",
        legalName: "Imię i nazwisko Pełnomocnika",
        legalEmail: "Adres e-mail Pełnomocnika",
        legalRelation:
          "Stosunek prawny łączący Pełnomocnika z mocodawcą (Osoba, Której Dane Dotyczą)",
        consumerRelation:
          "Stosunek łączący Osobę, Której Dane Dotyczą z Corteva",
        consumerFirstName: "Imię Osoby, Której Dane Dotyczą",
        consumerLastName: "Nazwisko Osoby, Której Dane Dotyczą",
        consumerCompany: "Przedsiębiorstwo Osoby, Której Dane Dotyczą",
        consumerPhone: "Numer telefonu Osoby, Której Dane Dotyczą",
        consumerEmail: "Adres e-mail Osoby, Której Dane Dotyczą",
        consumerCountry: "Lokalizacja Osoby, Której Dane Dotyczą",
        required: "To pole jest wymagane",
        minPhone: "Wymagany jest prawidłowy numer",
        formUploadDes: "Prześlij dokumentację przedstawiciela prawnego",
      },
      acknowledgement: {
        title: "Potwierdzenie",
        ackText1: "Identyfikator przesłanego wniosku o ochronę prywatności to",
        ackText2: "Prosimy o zachowanie egzemplarza wniosku.",
        ackText3:
          "Na podany adres e-mail zostanie wysłana wiadomość z łączem do weryfikacji dla wszystkich typów wniosków z wyjątkiem wniosku o",
        unsubscribe: "rezygnację z subskrypcji",
        ackText4: "",
        ackText5:
          "Należy zatem znaleźć w skrzynce odbiorczej taką wiadomość i zweryfikować swój wniosek. Jeśli ani w skrzynce odbiorczej, ani w folderze ze spamem nie ma wiadomości z adresu",
        ackText6: "należy ponownie przesłać swój wniosek. Dziękujemy.",
      },
      AADoc: {
        description1:
          "Pełnomocnik to osoba fizyczna upoważniona do złożenia w imieniu Osoby, Której Dane Dotyczą (innej niż Pełnomocnik) wniosku dotyczącego prywatności danych. Do takich danych ma dostęp osoba, której te dane dotyczą. Aby zapobiec nieuprawnionemu ujawnieniu danych, Pełnomocnik przedstawia dowód tożsamości swój i reprezentowanej przez siebie Osoby, Której Dane Dotyczą, a także pełnomocnictwo lub pisemne upoważnienie od Osoby, Której Dane Dotyczą.",
        heading: "Instrukcja przesłania dokumentów",
        description2:
          "aby zalogować się do Centrum Prywatności Danych, należy kliknąć przycisk „Wyświetl lub zarządzaj swoim żądaniem” znajdujący się w wiadomości e-mail zatytułowanej „Rozpoczęto żądanie podmiotu danych”.",
      },
      requestTypes: {
        contactReason: "Przyczyna kontaktu",
      },
    },
  },
  pt: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: " https://www.corteva.pt/politica-de-privacidade.html",
        urlText: " https://www.corteva.pt/politica-de-privacidade.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: " https://www.corteva.pt/politica-de-privacidade.html",
        urlText: " https://www.corteva.pt/politica-de-privacidade.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    pt: {
      intakeForm: {
        title: "Solicitação de privacidade",
        introText:
          "Se estiver a submeter um pedido para si, por favor preencha o formulário abaixo. Se for um Representante Legal, por favor clique no botão na parte inferior do ecrã para aceder ao formulário destinado a Representantes Legais.",
        endingText:
          "Se é um Representante Legal que submete o pedido de privacidade em nome de uma pessoa, por favor",
        click: "clique aqui",
        access: "access",
        accessDescription: "Informar-me sobre informações armazenadas",
        deletion: "deletion",
        deletionDescription:
          "Excluir todas as informações pessoais relacionadas a mim",
        correction: "correction",
        correctionDescription: "Atualizar minhas informações pessoais",
        optOut: "optOut",
        optOutDescription: "Cancelar inscrição de comunicações de marketing",
        other: "other",
        otherDescription: "Outro - Descreva abaixo",
        description1:
          "A Corteva Agriscience™ se preocupa com sua privacidade. Suas informações pessoais (nome, e-mail, número de telefone e outros dados de contato) serão armazenadas em sistemas de clientes escolhidos, hospedados principalmente nos Estados Unidos. Essas informações serão usadas pelas empresas da Corteva Agriscience™, suas afiliadas, parceiros e terceiros selecionados em outros países para fornecer a você as informações de produtos ou serviços solicitadas. Para saber mais, visite ",
        description2:
          "Ao fornecer suas informações pessoais, você concorda com os termos e condições desta Declaração de Privacidade.",
        url: "https://www.corteva.pt/politica-de-privacidade.html",
        urlText: "https://www.corteva.pt/politica-de-privacidade.html",
        customer: "Cliente/ Agricultor / Distribuidor",
        employee: "Empregado/Candidato/Ex-empregado/Empregado terceirizado.",
        vendor: "Fornecedor",
      },
      legalRepresentative: {
        title: "Solicitação de privacidade",
        header: "Para uso do Representante Legal",
        introText:
          "Se é um Representante Legal que pretende submeter o pedido de privacidade em nome de uma pessoa, por favor preencha o formulário abaixo.",
        endingText: "Se estiver a submeter um pedido para si, por favor",
        click: "clique aqui",
        access: "access",
        accessDescription: "Informar-me sobre informações armazenadas",
        deletion: "deletion",
        deletionDescription:
          "Excluir todas as informações pessoais relacionadas a mim",
        correction: "correction",
        correctionDescription: "Atualizar minhas informações pessoais",
        optOut: "optOut",
        optOutDescription: "Cancelar inscrição de comunicações de marketing",
        other: "other",
        otherDescription: "Outro - Descreva abaixo",
        description1:
          "A Corteva Agriscience™ se preocupa com sua privacidade. Suas informações pessoais (nome, e-mail, número de telefone e outros dados de contato) serão armazenadas em sistemas de clientes escolhidos, hospedados principalmente nos Estados Unidos. Essas informações serão usadas pelas empresas da Corteva Agriscience™, suas afiliadas, parceiros e terceiros selecionados em outros países para fornecer a você as informações de produtos ou serviços solicitadas. Para saber mais, visite ",
        description2:
          "Ao fornecer suas informações pessoais, você concorda com os termos e condições desta Declaração de Privacidade.",
        url: "https://www.corteva.pt/politica-de-privacidade.html",
        urlText: "https://www.corteva.pt/politica-de-privacidade.html",
        customer: "Cliente/ Agricultor / Distribuidor",
        employee: "Empregado/Candidato/Ex-empregado/Empregado terceirizado.",
        vendor: "Fornecedor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "Primeiro nome",
        firstNamePlaceholder: "Nome *",
        lastName: "Sobrenome",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefone",
        phonePlaceholder: "Telefono *",
        country: "Localização",
        countryPlaceholder: "Selecionar Localização *",
        robot: "Non sono un robot",
        submitRequest: "Submeter Pedido",
        errorText: "É necessário um número válido",
        relationshipToCorteva: "Relação com Corteva",
        customer: "Cliente/ Agricultor / Distribuidor",
        employee: "Empregado/Candidato/Ex-empregado/Empregado terceirizado.",
        vendor: "Fornecedor",
        pleaseSpecify: "Por favor especifique",
        company: "Empreza",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Por favor, forneça detalhes sobre o seu pedido",
        legalName: "Nome e Apelido do Representante Legal",
        legalEmail: "E-mail do Representante Legal",
        legalRelation:
          "Relação do Representante Legal com a pessoa que representa (Titular dos Dados)",
        consumerRelation: "Relação do Titular dos Dados com a Corteva:",
        consumerFirstName: "Nome do Titular dos Dados",
        consumerLastName: "Apelido do Titular dos Dados",
        consumerCompany: "Empresa do Titular dos Dados",
        consumerPhone: "Telefone do Titular dos Dados",
        consumerEmail: "E-mail do Titular dos dados",
        consumerCountry: "País do Titular dos Dados",
        required: "Este campo é obrigatório",
        minPhone: "É necessário um número válido",
        formUploadDes: "Carregar documentação do Representante Legal",
      },
      acknowledgement: {
        title: "Confirmação",
        ackText1: "A ID do seu Pedido de Privacidade é",
        ackText2: "Por favor, guarde uma cópia nos seus registos.",
        ackText3:
          "Um link de verificação será enviado para o endereço de e-mail que forneceu para todos os tipos de pedidos à exceção de um pedido de",
        unsubscribe: "anulação da subscrição",
        ackText4: "",
        ackText5:
          "Por favor, verifique a sua caixa de correio para verificar o seu pedido. Se não recebeu um e-mail do endereço",
        ackText6:
          "verifique a sua pasta de spam ou envie novamente o seu pedido. Obrigada.",
      },
      AADoc: {
        description1:
          "Um Representante Legal é um indivíduo com poderes para submeter um pedido de privacidade de dados em nome de um Titular dos Dados que não seja ele próprio. Os registos em questão estão acessíveis à Pessoa a quem dizem respeito. Para proteger a divulgação não autorizada, é necessário que forneça: uma prova de identidade tanto para si como para o Titular dos Dados que representa, bem como uma Procuração ou uma autorização escrita do Titular dos Dados que representa.",
        heading: "Instruções para carregar o documento:",
        description2:
          'Inicie sessão no Centro de Privacidade de Dados, clicando no botão "Visualizar ou gerenciar sua solicitação" no e-mail com a linha de assunto "Solicitação de titular de dados iniciada".',
      },
      requestTypes: {
        contactReason: "Razão de Contato",
      },
    },
  },
  ro: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        deleteMyCortevaAccountDescription: "Delete my Corteva user account",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.ro/politica-de-confidentialitate.html",
        urlText: "https://www.corteva.ro/politica-de-confidentialitate.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        deleteMyCortevaAccountDescription: "Delete my Corteva user account",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.ro/politica-de-confidentialitate.html",
        urlText: "https://www.corteva.ro/politica-de-confidentialitate.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    ro: {
      intakeForm: {
        title: "Solicitare de confidențialitate",
        introText:
          "Dacă depuneți o cerere în nume propriu, completați formularul de mai jos. Dacă sunteți un reprezentant legal, faceți clic pe butonul din partea de jos a ecranului pentru a accesa formularul pentru reprezentanții legali.",
        endingText:
          "Dacă sunteți un reprezentant legal care depune cererea privind confidențialitatea în numele unei persoane,",
        click: "faceți clic aici",
        access: "access",
        accessDescription: "Aduceți-mi la cunoștință informațiile stocate",
        deletion: "deletion",
        deletionDescription: "Ștergeți toate informațiile personale",
        correction: "correction",
        correctionDescription: "Actualizați datele personale",
        optOut: "optOut",
        optOutDescription: "Dezabonare de la comunicarile de marketing",
        other: "other",
        otherDescription: "Altă variantă - Descrieți mai jos",
        deleteMyCortevaAccountDescription: "Ștergeți contul meu Corteva",
        description1:
          "Corteva Agriscience™ îi pasă de confidențialitatea dumneavoastră. Informațiile dumneavoastră personale (nume, e-mail, număr de telefon și alte date de contact) vor fi stocate în sisteme ale clienților găzduite în principal în Statele Unite. Aceste informații vor fi folosite de companiile Corteva Agriscience™, afiliații săi, partenerii și terții selectați din alte țări pentru a vă furniza informațiile despre produse sau servicii solicitate. Pentru a afla mai multe, vă rugăm să vizitați ",
        description2:
          "Prin furnizarea informațiilor dumneavoastră personale, sunteți de acord cu termenii și condițiile acestei Declarații de confidențialitate.",
        url: "https://www.corteva.ro/politica-de-confidentialitate.html",
        urlText: "https://www.corteva.ro/politica-de-confidentialitate.html",
        customer: "Client/Agricultor/Distribuitor",
        employee: "Angajat/Solicitant/Fost angajat/Contractant",
        vendor: "Vânzător",
      },
      legalRepresentative: {
        title: "Solicitare de confidențialitate",
        header: "Pentru utilizare de către Reprezentantul Legal",
        introText:
          "Dacă sunteți un reprezentant legal care depune cererea privind confidențialitatea în numele unei persoane, completați formularul de mai jos.",
        endingText: "Dacă depuneți o cerere în nume propriu,",
        click: "faceți clic aici",
        access: "access",
        accessDescription: "Aduceți-mi la cunoștință informațiile stocate",
        deletion: "deletion",
        deletionDescription: "Ștergeți toate informațiile personale",
        correction: "correction",
        correctionDescription: "Actualizați datele personale",
        optOut: "optOut",
        optOutDescription: "Dezabonare de la comunicarile de marketing",
        other: "other",
        otherDescription: "Altă variantă - Descrieți mai jos",
        deleteMyCortevaAccountDescription: "Ștergeți contul meu Corteva",
        description1:
          "Corteva Agriscience™ îi pasă de confidențialitatea dumneavoastră. Informațiile dumneavoastră personale (nume, e-mail, număr de telefon și alte date de contact) vor fi stocate în sisteme ale clienților găzduite în principal în Statele Unite. Aceste informații vor fi folosite de companiile Corteva Agriscience™, afiliații săi, partenerii și terții selectați din alte țări pentru a vă furniza informațiile despre produse sau servicii solicitate. Pentru a afla mai multe, vă rugăm să vizitați ",
        description2:
          "Prin furnizarea informațiilor dumneavoastră personale, sunteți de acord cu termenii și condițiile acestei Declarații de confidențialitate.",
        url: "https://www.corteva.ro/politica-de-confidentialitate.html",
        urlText: "https://www.corteva.ro/politica-de-confidentialitate.html",
        customer: "Client/Agricultor/Distribuitor",
        employee: "Angajat/Solicitant/Fost angajat/Contractant",
        vendor: "Vânzător",
      },
      requestForm: {
        email: "E-mail",
        emailPlaceholder: "E-mail *",
        firstName: "Nume",
        firstNamePlaceholder: "Nome *",
        lastName: "Prenume",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefon",
        phonePlaceholder: "Telefono *",
        country: "Locație",
        countryPlaceholder: "Selectați Locație *",
        robot: "Non sono un robot",
        submitRequest: "Depunere cerere",
        errorText: "Este necesar un număr valid",
        relationshipToCorteva: "Relația cu Corteva",
        customer: "Client/Agricultor/Distribuitor",
        employee: "Angajat/Solicitant/Fost angajat/Contractant",
        vendor: "Vânzător",
        pleaseSpecify: "Vă rugăm să specificați",
        company: "Companie",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails:
          "Vă rugăm să ne furnizați detalii despre solicitarea dvs",
        legalName: "Numele și prenumele reprezentantului legal",
        legalEmail: "E-mailul reprezentantului legal",
        legalRelation:
          "Relația reprezentantului legal cu persoana pe care o reprezentați (persoana vizată)",
        consumerRelation: "Relația persoanei vizate cu Corteva",
        consumerFirstName: "Prenumele persoanei vizate",
        consumerLastName: "Numele persoanei vizate",
        consumerCompany: "Compania persoanei vizate",
        consumerPhone: "Numărul de telefon al persoanei vizate",
        consumerEmail: "E-mailul persoanei vizate",
        consumerCountry: "Țara persoanei vizate",
        required: "Acest câmp este obligatoriu",
        minPhone: "Este necesar un număr valid",
        formUploadDes: "Încărcați documentația Reprezentantului legal",
      },
      acknowledgement: {
        title: "Confirmare",
        ackText1: "ID-ul cererii dvs. privind confidențialitatea este",
        ackText2: "Păstrați o copie pentru arhiva dvs.",
        ackText3:
          "Se va trimite un link de verificare pe adresa de e-mail pe care ne-ați furnizat-o, pentru toate tipurile de solicitări, cu excepția",
        unsubscribe: "dezabonare",
        ackText4: "cererii de",
        ackText5:
          "Accesați-vă inboxul pentru a verifica cererea. Dacă nu ați primit un e-mail de la",
        ackText6:
          "verificați folderul spam sau retrimiteți cererea. Vă mulțumim.",
      },
      AADoc: {
        description1:
          "Un reprezentant legal este o persoană împuternicită să depună o cerere privind confidențialitatea datelor în numele unei persoane vizate, alta decât propria persoană. Aceste înregistrări sunt accesibile persoanei la care se referă. Pentru a proteja divulgarea neautorizată, este necesar să furnizați: o dovadă a identității atât pentru dumneavoastră, cât și pentru persoana vizată pe care o reprezentați, precum și o împuternicire sau o autorizație scrisă din partea persoanei vizate pe care o reprezentați.",
        heading: "Instrucțiuni pentru încărcarea documentului",
        description2:
          "Conectați-vă la Centrul pentru confidențialitatea datelor făcând clic pe butonul „Vizualizați sau gestionați-vă cererea” din e-mailul cu subiectul „Cererea privind persoana vizată a fost inițiată”.",
      },
      requestTypes: {
        contactReason: "Motivul solicitării",
      },
    },
  },
  sl: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.si/varstvo-podatkov.html",
        urlText: "https://www.corteva.si/varstvo-podatkov.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.si/varstvo-podatkov.html",
        urlText: "https://www.corteva.si/varstvo-podatkov.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    sl: {
      intakeForm: {
        title: "Zahteva za zasebnost",
        introText:
          "Če zahtevek pošiljate zase, izpolnite spodnji obrazec. Če ste zakoniti zastopnik, kliknite gumb na dnu zaslona, da odprete obrazec za zakonite zastopnike.",
        endingText:
          "Če ste zakoniti zastopnik in zahtevek za varstvo zasebnosti pošiljate v imenu druge osebe,",
        click: "kliknite tukaj",
        access: "access",
        accessDescription: "Obvestite me o podatkih, ki jih zbirate o meni",
        deletion: "deletion",
        deletionDescription: "Izbrišite vse moje osebne podatke",
        correction: "correction",
        correctionDescription: "Ažurirajte moje osebne podatke",
        optOut: "optOut",
        optOutDescription:
          "Izbrišite me iz seznama za marketinško komuniciranje",
        other: "other",
        otherDescription: "Drugo – opišite spodaj",
        description1:
          "V podjetju Corteva Agriscience™ skrbimo za vašo zasebnost. Vaši osebni podatki (ime, elektronska pošta, telefonske številke in ostali kontaktni podatki) se shranjujejo v naših izbranih sistemih za stranke, ki so večinoma v ZDA. Z namenom zagotovitve zahtevanih proizvodov in storitev, bodo te informacije  uporabljali Corteva Agriscience™,  njihove enote, partnerji in izbrane tretje osebe v drugih državah. Za več informacij obiščite ",
        description2:
          "Z zagotavljanjem osebnih podatkov se strinjate s pogoji te politike zasebnosti.",
        url: "https://www.corteva.si/varstvo-podatkov.html",
        urlText: "https://www.corteva.si/varstvo-podatkov.html",
        customer: "Stranka/Kmet/Distributer",
        employee: "Zaposleni/Kandidat/Bivši zaposleni/ Pogodbenik",
        vendor: "Prodajalec",
      },
      legalRepresentative: {
        title: "Zahteva za zasebnost",
        header: "Za uporabo s strani pravnega zastopnika",
        introText:
          "Če ste zakoniti zastopnik in zahtevek za varstvo zasebnosti vlagate v imenu druge osebe, izpolnite spodnji obrazec.",
        endingText: "Če zahtevek pošiljate zase,",
        click: "kliknite tukaj",
        access: "access",
        accessDescription: "Obvestite me o podatkih, ki jih zbirate o meni",
        deletion: "deletion",
        deletionDescription: "Izbrišite vse moje osebne podatke",
        correction: "correction",
        correctionDescription: "Ažurirajte moje osebne podatke",
        optOut: "optOut",
        optOutDescription:
          "Izbrišite me iz seznama za marketinško komuniciranje",
        other: "other",
        otherDescription: "Drugo – opišite spodaj",
        description1:
          "V podjetju Corteva Agriscience™ skrbimo za vašo zasebnost. Vaši osebni podatki (ime, elektronska pošta, telefonske številke in ostali kontaktni podatki) se shranjujejo v naših izbranih sistemih za stranke, ki so večinoma v ZDA. Z namenom zagotovitve zahtevanih proizvodov in storitev, bodo te informacije  uporabljali Corteva Agriscience™,  njihove enote, partnerji in izbrane tretje osebe v drugih državah. Za več informacij obiščite ",
        description2:
          "Z zagotavljanjem osebnih podatkov se strinjate s pogoji te politike zasebnosti.",
        url: "https://www.corteva.si/varstvo-podatkov.html",
        urlText: "https://www.corteva.si/varstvo-podatkov.html",
        customer: "Stranka/Kmet/Distributer",
        employee: "Zaposleni/Kandidat/Bivši zaposleni/ Pogodbenik",
        vendor: "Prodajalec",
      },
      requestForm: {
        email: "Elektronska pošta",
        emailPlaceholder: "E-mail *",
        firstName: "Ime",
        firstNamePlaceholder: "Nome *",
        lastName: "Priimek",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefon",
        phonePlaceholder: "Telefono *",
        country: "Kraj",
        countryPlaceholder: "Izberite Kraj *",
        robot: "Non sono un robot",
        submitRequest: "Pošlji zahtevo",
        errorText: "Zahtevana je veljavna številka",
        relationshipToCorteva: "Odnos do Corteva",
        customer: "Stranka/Kmet/Distributer",
        employee: "Zaposleni/Kandidat/Bivši zaposleni/ Pogodbenik",
        vendor: "Prodajalec",
        pleaseSpecify: "Prosimo navedite",
        company: "Podjetje",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Opišite podrobnosti za vašo zahtevo",
        legalName: "Ime in priimek zakonitega zastopnika",
        legalEmail: "Elektronska pošta zakonitega zastopnika",
        legalRelation:
          "Odnos zakonitega zastopnika z osebo, ki jo zastopate (posameznik, na katerega se nanašajo osebni podatki)",
        consumerRelation:
          "Razmerje med posameznikom, na katerega se nanašajo osebni podatki, in družbo Corteva",
        consumerFirstName:
          "Ime posameznika, na katerega se nanašajo osebni podatki",
        consumerLastName:
          "Priimek posameznika, na katerega se nanašajo osebni podatki",
        consumerCompany:
          "Podjetje posameznika, na katerega se nanašajo osebni podatki",
        consumerPhone:
          "Telefon posameznika, na katerega se nanašajo osebni podatki",
        consumerEmail:
          "Elektronska pošta posameznika, na katerega se nanašajo podatki",
        consumerCountry:
          "Država posameznika, na katerega se nanašajo osebni podatki",
        required: "to polje je obvezno",
        minPhone: "Zahtevana je veljavna številka",
        formUploadDes: "Nalaganje dokumentacije zakonitega zastopnika",
      },
      acknowledgement: {
        title: "Potrditev",
        ackText1: "ID vaše zahteve za zasebnost je",
        ackText2: "Shranite kopijo za svojo evidenco.",
        ackText3:
          "Na e-poštni naslov, ki ste ga navedli za vse vrste zahtev, razen za zahtevo za odjavo, vam bomo poslali povezavo za preverjanje pristnosti.",
        unsubscribe: "",
        ackText4: "",
        ackText5:
          "Preverite svojo prejeto pošto, da potrdite zahtevo. Če niste prejeli e-pošte z naslova",
        ackText6:
          "preverite mapo z neželeno pošto ali ponovno pošljite svojo zahtevo. Hvala.",
      },
      AADoc: {
        description1:
          "Zakoniti zastopnik je posameznik, ki je pooblaščen, da vloži zahtevo za varstvo podatkov v imenu posameznika, na katerega se nanašajo osebni podatki. Takšni zapisi so dostopni osebi, na katero se nanašajo. Za zaščito pred nepooblaščenim razkritjem je treba predložiti: dokazilo o identiteti zase in za posameznika, na katerega se nanašajo osebni podatki, ki ga zastopate, ter pooblastilo ali pisno pooblastilo posameznika, na katerega se nanašajo osebni podatki, ki ga zastopate.",
        heading: "Navodila za nalaganje dokumenta:",
        description2:
          'Prijavite se v Središče za zasebnost podatkov tako, da kliknete na gumb "Ogled ali urejanje zahtevka" v e-poštnem sporočilu z naslovom "Zahtevek za podatke je vložen".',
      },
      requestTypes: {
        contactReason: "Razlog",
      },
    },
  },
  sr: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "www.corteva.rs/politika-privatnosti.html",
        urlText: "www.corteva.rs/politika-privatnosti.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "www.corteva.rs/politika-privatnosti.html",
        urlText: "www.corteva.rs/politika-privatnosti.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    sr_BA: {
      intakeForm: {
        title: "Zahtev za privatnost",
        introText:
          "Ako zahtev šaljete za sebe, popunite obrazac u nastavku. Ako ste pravni zastupnik, kliknite na dugme na dnu ekrana da biste došli do obrasca za pravne zastupnike.",
        endingText:
          "Ako ste pravni zastupnik koji podnosi zahtev za privatnost u ime neke druge osobe,",
        click: "kliknite ovde",
        access: "access",
        accessDescription: "Informišite me o uskladištenim informacijama",
        deletion: "deletion",
        deletionDescription:
          "Izbriši sve lične informacije koje se odnose na mene",
        correction: "correction",
        correctionDescription: "Ažuriraj moje lične informacije",
        optOut: "optOut",
        optOutDescription: "Otkaži pretplatu na marketinške komunikacije",
        other: "other",
        otherDescription: "Ostalo - opišite ispod",
        description1:
          "Corteva Agriscience™ brine o vašoj privatnosti. Vaši lični podaci (ime, e-pošta, broj telefona i drugi kontakt podaci) biće uskladišteni u odabranim korisničkim sistemima koji su prvenstveno hostovani u Sjedinjenim Državama. Ove informacije će koristiti preduzeća Corteva Agriscience, njene filijale™, partneri i izabrana treća lica u drugim zemljama da bi vam pružili tražene informacije o proizvodu ili usluzi. Da biste saznali više, posetite ",
        description2:
          "Pružanjem ličnih informacija pristajete na odredbe i uslove ove Izjave o privatnosti.",
        url: "https://www.corteva.rs/politika-privatnosti.html",
        urlText: "https://www.corteva.rs/politika-privatnosti.html",
        customer: "Kupac/farmer/distributer",
        employee: "Zaposleni/podnosilac zahteva/bivši radnik/ Izvođač radova",
        vendor: "Dobavljač",
      },
      legalRepresentative: {
        title: "Zahtev za privatnost",
        header: "Za upotrebu od strane pravnog zastupnika",
        introText:
          "Ako ste pravni zastupnik koji podnosi zahtev za privatnost u ime neke druge osobe, popunite obrazac u nastavku.",
        endingText: "Ako zahtev šaljete za sebe,",
        click: "kliknite ovde",
        access: "access",
        accessDescription: "Informišite me o uskladištenim informacijama",
        deletion: "deletion",
        deletionDescription:
          "Izbriši sve lične informacije koje se odnose na mene",
        correction: "correction",
        correctionDescription: "Ažuriraj moje lične informacije",
        optOut: "optOut",
        optOutDescription: "Otkaži pretplatu na marketinške komunikacije",
        other: "other",
        otherDescription: "Ostalo - opišite ispod",
        description1:
          "Corteva Agriscience™ brine o vašoj privatnosti. Vaši lični podaci (ime, e-pošta, broj telefona i drugi kontakt podaci) biće uskladišteni u odabranim korisničkim sistemima koji su prvenstveno hostovani u Sjedinjenim Državama. Ove informacije će koristiti preduzeća Corteva Agriscience, njene filijale™, partneri i izabrana treća lica u drugim zemljama da bi vam pružili tražene informacije o proizvodu ili usluzi. Da biste saznali više, posetite ",
        description2:
          "Pružanjem ličnih informacija pristajete na odredbe i uslove ove Izjave o privatnosti.",
        url: "https://www.corteva.rs/politika-privatnosti.html",
        urlText: "https://www.corteva.rs/politika-privatnosti.html",
        customer: "Kupac/farmer/distributer",
        employee: "Zaposleni/podnosilac zahteva/bivši radnik/ Izvođač radova",
        vendor: "Dobavljač",
      },
      requestForm: {
        email: "E-pošta",
        emailPlaceholder: "E-mail *",
        firstName: "Ime",
        firstNamePlaceholder: "Nome *",
        lastName: "Prezime",
        lastNamePlaceholder: "Cognome *",
        phone: "Broj telefona",
        phonePlaceholder: "Telefono *",
        country: "Zemlja",
        countryPlaceholder: "Izaberite Zemlja *",
        robot: "Non sono un robot",
        submitRequest: "Pošalji zahtev",
        errorText: "Potreban je važeći broj",
        relationshipToCorteva: "Odnos prema aplikaciji Corteva",
        customer: "Kupac/farmer/distributer",
        employee: "Zaposleni/podnosilac zahteva/bivši radnik/ Izvođač radova",
        vendor: "Dobavljač",
        pleaseSpecify: "Navedite",
        company: "Kompanija",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Navedite detalje o vašem zahtevu",
        legalName: "Ime i prezime pravnog zastupnika",
        legalEmail: "E-pošta pravnog zastupnika",
        legalRelation:
          "Odnos pravnog zastupnika sa osobom koju zastupate (subjekat podataka)",
        consumerRelation: "Odnos subjekta podataka sa kompanijom Corteva",
        consumerFirstName: "Ime subjekta podataka",
        consumerLastName: "Prezime subjekta podataka",
        consumerCompany: "Kompanija subjekta podataka",
        consumerPhone: "Telefon subjekta podatak",
        consumerEmail: "E-pošta subjekta podataka",
        consumerCountry: "Država subjekta podataka",
        required: "Ovo polje je obavezno",
        minPhone: "Potreban je važeći broj",
        formUploadDes: "Otpremite dokumentaciju pravnog zastupnika",
      },
      acknowledgement: {
        title: "Potvrda",
        ackText1: "ID zahteva za privatnost je",
        ackText2: "Sačuvajte kopiju za svoju evidenciju.",
        ackText3:
          "Za sve vrste zahteva, osim zahteva za otkazivanje pretplate, na adresu e-pošte koju ste naveli poslaćemo link za potvrdu.",
        unsubscribe: "",
        ackText4: "",
        ackText5:
          "Proverite prijemno sanduče da biste potvrdili zahtev. Ako niste primili e-poruku sa adrese",
        ackText6:
          "proverite fasciklu sa neželjenom poštom ili ponovo pošaljite zahtev. Hvala.",
      },
      AADoc: {
        description1:
          "Pravni zastupnik je osoba ovlašćena da, osim u svoje ime, podnese zahtev za privatnost podataka i u ime subjekta podataka. Takve evidencije su dostupne licu na koje se odnose. U cilju onemogućavanja neovlašćenog otkrivanja, potrebno je da dostavite: dokaz o identitetu za sebe i za subjekta podataka kog zastupate, kao i punomoćje ili pismeno ovlašćenje od subjekta podataka kog zastupate.",
        heading: "Uputstvo za otpremanje dokumenta:",
        description2:
          "Prijavite se u Centar za privatnost podataka klikom na dugme „Prikaz ili upravljanje svojim zahtevom“ u poruci e-pošte sa temom „Započet zahtev za subjekta podataka“.",
      },
      requestTypes: {
        contactReason: "Razlog kontakta ",
      },
    },
  },
  sv: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.se/integritetspolicy.html",
        urlText: "https://www.corteva.se/integritetspolicy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.se/integritetspolicy.html",
        urlText: "https://www.corteva.se/integritetspolicy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    sv: {
      intakeForm: {
        title: "Sekretessförfrågan",
        introText:
          "Om du skickar in en begäran för dig själv, vänligen fyll i formuläret nedan. Om du är ett juridiskt ombud, klicka på knappen längst ner på skärmen för att komma till formuläret för juridiska ombud.",
        endingText:
          "Om du är ett juridiskt ombud som skickar in en begäran om sekretess för en persons räkning,",
        click: "klicka här",
        access: "access",
        accessDescription:
          "Låt mig veta vilken personlig information ni har lagrad om mig",
        deletion: "deletion",
        deletionDescription:
          "Ta bort all personlig information relaterad till mig",
        correction: "correction",
        correctionDescription: "Uppdatera min personliga information",
        optOut: "optOut",
        optOutDescription: "Avsluta prenumeration på nyhetsbrev",
        other: "other",
        otherDescription: "Annat - beskriv nedan",
        description1:
          "Din integritet är viktig för Corteva Agriscience. Dina personuppgifter (namn, e-post, telefonnummer och andra kontaktuppgifter) lagras i valda kundsystem på servrar huvudsakligen i Förenta Staterna. Denna information kommer att användas av Corteva Agriscience, dess dotterbolag, partners och utvalda tredje parter i andra länder för att ge dig den produkt- eller serviceinformation du begär. För att läsa mer, besök ",
        description2:
          "Genom att tillhandahålla din personliga information godkänner du villkoren i denna integritetspolicy.",
        url: "https://www.corteva.se/integritetspolicy.html",
        urlText: "https://www.corteva.se/integritetspolicy.html",
        customer: "Kund/Jordbrukare/Distributör",
        employee: "Anställd/Kandidat/Tidigare anställd/Leverantör",
        vendor: "Säljare",
      },
      legalRepresentative: {
        title: "Sekretessförfrågan",
        header: "För användning av juridisk representant",
        introText:
          "Om du är ett juridiskt ombud som skickar in en begäran om sekretess på en persons vägnar, vänligen fyll i formuläret nedan.",
        endingText: "Om du skickar in en begäran för dig själv,",
        click: "klicka här",
        access: "access",
        accessDescription:
          "Låt mig veta vilken personlig information ni har lagrad om mig",
        deletion: "deletion",
        deletionDescription:
          "Ta bort all personlig information relaterad till mig",
        correction: "correction",
        correctionDescription: "Uppdatera min personliga information",
        optOut: "optOut",
        optOutDescription: "Avsluta prenumeration på nyhetsbrev",
        other: "other",
        otherDescription: "Annat - beskriv nedan",
        description1:
          "Din integritet är viktig för Corteva Agriscience. Dina personuppgifter (namn, e-post, telefonnummer och andra kontaktuppgifter) lagras i valda kundsystem på servrar huvudsakligen i Förenta Staterna. Denna information kommer att användas av Corteva Agriscience, dess dotterbolag, partners och utvalda tredje parter i andra länder för att ge dig den produkt- eller serviceinformation du begär. För att läsa mer, besök ",
        description2:
          "Genom att tillhandahålla din personliga information godkänner du villkoren i denna integritetspolicy.",
        url: "https://www.corteva.se/integritetspolicy.html",
        urlText: "https://www.corteva.se/integritetspolicy.html",
        customer: "Kund/Jordbrukare/Distributör",
        employee: "Anställd/Kandidat/Tidigare anställd/Leverantör",
        vendor: "Säljare",
      },
      requestForm: {
        email: "E-postadress",
        emailPlaceholder: "E-mail *",
        firstName: "Förnamn",
        firstNamePlaceholder: "Nome *",
        lastName: "Efternamn",
        lastNamePlaceholder: "Cognome *",
        phone: "Mobilnummer",
        phonePlaceholder: "Telefono *",
        country: "Land",
        countryPlaceholder: "Välj land *",
        robot: "Non sono un robot",
        submitRequest: "Skicka in en begäran",
        errorText: "Ett giltigt nummer krävs",
        relationshipToCorteva: "Förhållande till Corteva",
        customer: "Kund/Jordbrukare/Distributör",
        employee: "Anställd/Kandidat/Tidigare anställd/Leverantör",
        vendor: "Säljare",
        pleaseSpecify: "Vänligen specificera",
        company: "Gård/Företag",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Vänligen ange vad din förfrågan handlar om",
        legalName: "Förnamn och efternamn för det juridiska ombudet",
        legalEmail: "E-postadress för det juridiska ombudet",
        legalRelation:
          "Det juridiska ombudets förhållande till den person som hen företräder (den registrerade)",
        consumerRelation: "Den registrerades förhållande till Corteva:",
        consumerFirstName: "Den registrerades förnamn",
        consumerLastName: "Den registrerades efternamn",
        consumerCompany: "Den registrerades företag",
        consumerPhone: "Den registrerades telefon",
        consumerEmail: "Den registrerades e-postadress",
        consumerCountry: "Den registrerades land",
        required: "Detta fält är obligatoriskt",
        minPhone: "Ett giltigt nummer krävs",
        formUploadDes: "Ladda upp dokumentation för Legal Representative",
      },
      acknowledgement: {
        title: "Bekräftelse",
        ackText1: "Ditt ID för begäran om sekretess är",
        ackText2: "Spara en kopia för dina register.",
        ackText3:
          "En verifieringslänk kommer att skickas till den e-postadress du uppgav för alla typer av förfrågningar utom för en begäran om ",
        unsubscribe: "att avsluta prenumerationene",
        ackText4: "",
        ackText5:
          "Var god kontrollera din inkorg för att verifiera begäran. Om du inte fick något e-postmeddelande från",
        ackText6: "kontrollera din skräpkorg eller skicka en ny begäran. Tack.",
      },
      AADoc: {
        description1:
          "Ett juridiskt ombud är en person som har befogenhet att lämna in en begäran om dataskydd för en annan registrerad persons räkning. Sådana register är tillgängliga för den person som de avser. För att skydda obehörigt avslöjande krävs att du tillhandahåller följande: ett identitetsbevis både för dig själv och för den registrerade person som du representerar, samt en fullmakt eller ett skriftligt tillstånd från den registrerade person som du representerar.",
        heading: "Instruktioner för att ladda upp dokumentet:",
        description2:
          'Logga in på datacentret för sekretess genom att klicka på knappen "Visa eller hantera din begäran" i e-postmeddelandet med ämnesraden "Begäran om uppgifter från den registrerade har påbörjats".',
      },
      requestTypes: {
        contactReason: "Anledning till hänvändelse",
      },
    },
  },
  sk: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.sk/sukromie.html",
        urlText: "https://www.corteva.sk/sukromie.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.sk/sukromie.html",
        urlText: "https://www.corteva.sk/sukromie.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    sk: {
      intakeForm: {
        title: "Žiadosť o ochranu osobných údajov",
        introText:
          "Ak podávate žiados? pre seba, vypl?te nasledujúci formulár. Ak ste právny zástupca, kliknutím na tla?idlo v dolnej ?asti obrazovky sa dostanete k formuláru pre právnych zástupcov.",
        endingText:
          "Ak ste právny zástupca a podávate žiadosť o ochranu osobných údajov v mene osoby,",
        click: "kliknite sem",
        access: "access",
        accessDescription: "Informujte ma o uložených informáciách",
        deletion: "deletion",
        deletionDescription: "Vymažte všetky osobné údaje, ktoré sa ma týkajú",
        correction: "correction",
        correctionDescription: "Aktualizujte moje osobné údaje",
        optOut: "optOut",
        optOutDescription: "Zrušenie odberu marketingovej komunikácie",
        other: "other",
        otherDescription: "Iné – popíšte nižšie",
        description1:
          "Corteva Agriscience™ sa stará o vaše súkromie. Vaše osobné údaje (meno, e-mail, telefónne číslo a ďalšie kontaktné údaje) budú uložené vo vybraných zákazníckych systémoch primárne hostených v Spojených štátoch. Tieto informácie budú použité spoločnosťami Corteva Agriscience™, jej pridruženými spoločnosťami, partnermi a vybranými tretími stranami v iných krajinách, aby vám poskytli požadované informácie o produkte alebo službe. Ak sa chcete dozvedieť viac, navštívte stránku ",
        description2:
          "Poskytnutím svojich osobných údajov súhlasíte s podmienkami tohto Vyhlásenia o ochrane osobných údajov.",
        url: "https://www.corteva.sk/sukromie.html",
        urlText: "https://www.corteva.sk/sukromie.html",
        customer: "Zákazník/Farmár/Distribútor",
        employee: "Zamestnanec/žiadateľ/bývalý zamestnanec/dodávateľ",
        vendor: "Predajca",
      },
      legalRepresentative: {
        title: "Žiadosť o ochranu osobných údajov",
        header: "Na použitie právnym zástupcom",
        introText:
          "Ak ste právny zástupca a podávate žiadosť o ochranu osobných údajov v mene osoby, vyplňte nižšie uvedený formulár.",
        endingText: "Ak podávate žiados? pre seba",
        click: "kliknite sem",
        access: "access",
        accessDescription: "Informujte ma o uložených informáciách",
        deletion: "deletion",
        deletionDescription: "Vymažte všetky osobné údaje, ktoré sa ma týkajú",
        correction: "correction",
        correctionDescription: "Aktualizujte moje osobné údaje",
        optOut: "optOut",
        optOutDescription: "Zrušenie odberu marketingovej komunikácie",
        other: "other",
        otherDescription: "Iné – popíšte nižšie",
        description1:
          "Corteva Agriscience™ sa stará o vaše súkromie. Vaše osobné údaje (meno, e-mail, telefónne číslo a ďalšie kontaktné údaje) budú uložené vo vybraných zákazníckych systémoch primárne hostených v Spojených štátoch. Tieto informácie budú použité spoločnosťami Corteva Agriscience™, jej pridruženými spoločnosťami, partnermi a vybranými tretími stranami v iných krajinách, aby vám poskytli požadované informácie o produkte alebo službe. Ak sa chcete dozvedieť viac, navštívte stránku ",
        description2:
          "Poskytnutím svojich osobných údajov súhlasíte s podmienkami tohto Vyhlásenia o ochrane osobných údajov.",
        url: "https://www.corteva.sk/sukromie.html",
        urlText: "https://www.corteva.sk/sukromie.html",
        customer: "Zákazník/Farmár/Distribútor",
        employee: "Zamestnanec/žiadateľ/bývalý zamestnanec/dodávateľ",
        vendor: "Predajca",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "E-mail *",
        firstName: "Krstné meno",
        firstNamePlaceholder: "Nome *",
        lastName: "Priezvisko",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefón",
        phonePlaceholder: "Telefono *",
        country: "Poloha",
        countryPlaceholder: "Vyberte krajinu *",
        robot: "nie som robot",
        submitRequest: "Odoslať žiadosť",
        errorText: "Vyžaduje sa platné číslo",
        relationshipToCorteva: "Vzťah ku Corteve",
        customer: "Zákazník/Farmár/Distribútor",
        employee: "Zamestnanec/žiadateľ/bývalý zamestnanec/dodávateľ",
        vendor: "Predajca",
        pleaseSpecify: "Vänligen specificera",
        company: "Spoločnosť",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails: "Uveďte podrobnosti o svojej žiadosti",
        legalName: "Meno a priezvisko právneho zástupcu",
        legalEmail: "E-mail právneho zástupcu",
        legalRelation:
          "Vzťah právneho zástupcu s osobou, ktorú zastupujete (dotknutá osoba)",
        consumerRelation: "Den registrerades förhållande till Corteva:",
        consumerFirstName: "Krstné meno dotknutej osoby",
        consumerLastName: "Priezvisko dotknutej osoby",
        consumerCompany: "Spolocnost dotknutá osoba",
        consumerPhone: "Telefón dotknutej osoby",
        consumerEmail: "E-mail dotknutej osoby",
        consumerCountry: "Krajina dotknutej osoby",
        required: "Detta fält är obligatoriskt",
        minPhone: "Vyžaduje sa platné číslo",
        formUploadDes: "Nahrajte dokumenty právneho zástupcu",
      },
      acknowledgement: {
        title: "Potvrdenie",
        ackText1: "Vaše ID žiadosti o ochranu osobných údajov je",
        ackText2: "Uložte si kópiu pre svoje záznamy.",
        ackText3:
          "En verifieringslänk kommer att skickas till den e-postadress du uppgav för alla typer av förfrågningar utom för en begäran om ",
        unsubscribe: "att avsluta prenumerationene",
        ackText4: "",
        ackText5:
          "Skontrolujte svoju doručenú poštu a overte svoju žiadosť. Ak ste od",
        ackText6:
          "nedostali e-mail, skontrolujte priečinok so spamom alebo znova odošlite žiadosť. Ďakujeme",
      },
      AADoc: {
        description1:
          "Právny zástupca je fyzická osoba oprávnená podať žiadosť o ochranu osobných údajov v mene inej dotknutej osoby, ako je jej vlastná. Takéto záznamy sú prístupné osobe, ktorej sa týkajú. Na ochranu pred neoprávneným zverejnením sa vyžaduje, aby ste predložili: doklad totožnosti pre seba aj dotknutú osobu, ktorú zastupujete, ako aj plnú moc alebo písomné splnomocnenie dotknutej osoby, ktorú zastupujete.",
        heading: "Pokyny na nahratie dokumentu:",
        description2:
          'Prihláste sa do Centra ochrany osobných údajov kliknutím na tlačidlo "Zobraziť alebo spravovať vašu žiadosť" v e-maile s predmetom "Žiadosť dotknutej osoby bola spustená".',
      },
      requestTypes: {
        contactReason: "Dôvod kontaktu",
      },
    },
  },
  tr: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.tr/gizlilik.html",
        urlText: "https://www.corteva.com.tr/gizlilik.html.",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.tr/gizlilik.html",
        urlText: "https://www.corteva.com.tr/gizlilik.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    tr: {
      intakeForm: {
        title: "Gizlilik Talebi",
        introText:
          "Kendiniz için bir talep gönderiyorsanız lütfen aşağıdaki formu doldurun. Yasal Temsilciyseniz Yasal Temsilcilere yönelik forma ulaşmak için lütfen ekranın altındaki düğmeye tıklayın.",
        endingText:
          "Gizlilik talebini başka bir kişi adına gönderen Yasal Temsilciyseniz lütfen",
        click: "buraya tıklayın",
        access: "access",
        accessDescription: "Depolanmış verilerim hakkında bilgi istiyorum",
        deletion: "deletion",
        deletionDescription:
          "Benimle ilgili bütün kişisel verileri silmenizi istiyorum",
        correction: "correction",
        correctionDescription: "Kişisel bilgilerimi güncelle",
        optOut: "optOut",
        optOutDescription: "Pazarlama iletisimi aboneligimi kaldir",
        other: "other",
        otherDescription: "Diğer - Aşağıda açıklayınız",
        description1:
          "Corteva Agriscience™ gizliliğinize önem verir. Kişisel bilgileriniz (ad, e-posta, telefon numarası ve diğer iletişim verileri), öncelikle Amerika Birleşik Devletleri'nde barındırılan seçilmiş müşteri sistemlerinde saklanacaktır. Bu bilgiler Corteva Agriscience™ işletmeleri, bağlı kuruluşları, ortakları ve diğer ülkelerdeki seçilmiş üçüncü şahıslar tarafından size istenen ürün veya hizmet bilgilerini sağlamak için kullanılacaktır. Daha fazla bilgi için lütfen ",
        description2:
          "adresini ziyaret edin. Kişisel bilgilerinizi vererek, bu Gizlilik Bildiriminin hüküm ve koşullarını kabul etmiş olursunuz.",
        url: "https://www.corteva.com.tr/gizlilik.html",
        urlText: "https://www.corteva.com.tr/gizlilik.html",
        customer: "Müşteri/Çiftçi/Distribütör",
        employee: "Çalışan/Aday/Eski çalışan/Müteahhit",
        vendor: "satici",
      },
      legalRepresentative: {
        title: "Gizlilik Talebi",
        header: "Yasal Temsilci tarafından kullanılmak üzere",
        introText:
          "Gizlilik talebini başka bir kişi adına gönderen Yasal Temsilciyseniz lütfen aşağıdaki formu doldurun.",
        endingText: "Kendiniz için bir talep gönderiyorsanız lütfen",
        click: "buraya tıklayın",
        access: "access",
        accessDescription: "Depolanmış verilerim hakkında bilgi istiyorum",
        deletion: "deletion",
        deletionDescription:
          "Benimle ilgili bütün kişisel verileri silmenizi istiyorum",
        correction: "correction",
        correctionDescription: "Kişisel bilgilerimi güncelle",
        optOut: "optOut",
        optOutDescription: "Pazarlama iletisimi aboneligimi kaldir",
        other: "other",
        otherDescription: "Diğer - Aşağıda açıklayınız",
        description1:
          "Corteva Agriscience™ gizliliğinize önem verir. Kişisel bilgileriniz (ad, e-posta, telefon numarası ve diğer iletişim verileri), öncelikle Amerika Birleşik Devletleri'nde barındırılan seçilmiş müşteri sistemlerinde saklanacaktır. Bu bilgiler Corteva Agriscience™ işletmeleri, bağlı kuruluşları, ortakları ve diğer ülkelerdeki seçilmiş üçüncü şahıslar tarafından size istenen ürün veya hizmet bilgilerini sağlamak için kullanılacaktır. Daha fazla bilgi için lütfen ",
        description2:
          "adresini ziyaret edin. Kişisel bilgilerinizi vererek, bu Gizlilik Bildiriminin hüküm ve koşullarını kabul etmiş olursunuz.",
        url: "https://www.corteva.com.tr/gizlilik.html",
        urlText: "https://www.corteva.com.tr/gizlilik.html",
        customer: "Müşteri/Çiftçi/Distribütör",
        employee: "Çalışan/Aday/Eski çalışan/Müteahhit",
        vendor: "satici",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "İsim",
        firstNamePlaceholder: "Nome *",
        lastName: "Soyisim",
        lastNamePlaceholder: "Cognome *",
        phone: "Telefon",
        phonePlaceholder: "Telefono *",
        country: "Ülke",
        countryPlaceholder: "Seçme Ülke *",
        robot: "Non sono un robot",
        submitRequest: "Talebi Gönder",
        errorText: "Geçerli bir numara gerekli",
        relationshipToCorteva: "Corteva ile ilişki",
        customer: "Müşteri/Çiftçi/Distribütör",
        employee: "Çalışan/Aday/Eski çalışan/Müteahhit",
        vendor: "satici",
        pleaseSpecify: "Per favore specificare",
        company: "Şirket Adı",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails:
          "Lütfen iletişim nedeniniz ile ilgili detayları aşağıda belirtiniz",
        legalName: "Yasal Temsilcinin Adı Soyadı",
        legalEmail: "Yasal Temsilcinin E-posta Adresi",
        legalRelation:
          "Yasal Temsilcinin Temsil Ettiği Kişiyle (Veri Sahibi) İlişkisi",
        consumerRelation: "Veri Sahibinin Corteva ile İlişkisi",
        consumerFirstName: "Veri Sahibinin Adı",
        consumerLastName: "Veri Sahibinin Soyadı",
        consumerCompany: "Veri Sahibinin Şirketi",
        consumerPhone: "Veri Sahibinin Telefonu",
        consumerEmail: "Veri Sahibinin E-posta Adresi",
        consumerCountry: "Veri Sahibinin Yaşadığı Ülke",
        required: "Bu alan gereklidir",
        minPhone: "Geçerli bir numara gerekli",
        formUploadDes: "Yasal Temsilci belgelerini yükleme",
      },
      acknowledgement: {
        title: "Onay",
        ackText1: "Gizlilik Talebi Kimliğiniz",
        ackText2: "Lütfen bir kopyasını kayıtlarınız için saklayın.",
        ackText3:
          "Abonelikten çıkma isteği dışındaki tüm istek türleri için verdiğiniz e-posta adresine bir doğrulama bağlantısı gönderilecek.",
        unsubscribe: "annullamento dell'iscrizione",
        ackText4: "richiesta di",
        ackText5:
          "Lütfen isteğinizi doğrulamak için gelen kutunuzu kontrol edin",
        ackText6:
          "controlla la cartella spam o invia di nuovo la richiesta. Grazie.",
      },
      AADoc: {
        description1:
          "Yasal temsilci, kendisi dışında bir Veri Sahibi adına veri gizliliği talebi gönderme yetkisine sahip bir kişidir. Bu tür kayıtlar, ilgili oldukları Kişinin erişimine açıktır. Yetkisiz ifşaya karşı koruma sağlamak için şunları sağlamanız gerekir: hem kendinizin hem de temsil ettiğiniz Veri Sahibinin kimliğini kanıtlayan bir belge ve temsil ettiğiniz Veri Sahibinden aldığınız Vekaletname veya yazılı izin.",
        heading: "Belgeyi yükleme talimatları:",
        description2:
          'Lütfen "Veri Konusu Talebi Başlatıldı" konu satırına sahip e-postadaki "Talebinizi görüntüleyin veya yönetin" düğmesine tıklayarak Veri Gizliliği Merkezi’nde oturum açın.',
      },
      requestTypes: {
        contactReason: "Iletisim Nedeni",
      },
    },
  },
  uk: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.ua/privacy-policy.html",
        urlText: "https://www.corteva.com.ua/privacy-policy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.com.ua/privacy-policy.html",
        urlText: "https://www.corteva.com.ua/privacy-policy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    uk: {
      intakeForm: {
        title: "Запит на конфіденційність",
        introText:
          "Якщо ви надсилаєте запит для себе, заповніть форму нижче. Якщо ви є законним представником, натисніть кнопку внизу екрана, щоб отримати форму для законних представників.",
        endingText:
          "Якщо ви є законним представником, який надсилає запит стосовно персональних даних від імені особи,",
        click: "натисніть тут",
        access: "access",
        accessDescription: "Повідомити мене про збережену інформацію",
        deletion: "deletion",
        deletionDescription:
          "Видалити всю особисту інформацію, пов'язану зі мною",
        correction: "correction",
        correctionDescription: "Оновити мою особисту інформацію",
        optOut: "optOut",
        optOutDescription: "Скасувати підписку на маркетингові повідомлення",
        other: "other",
        otherDescription: "Інше - опишіть нижче",
        description1:
          "Corteva Agriscience ™ дбає про вашу конфіденційність. Ваша особиста інформація (ім'я, електронна пошта, номер телефону та інші контактні дані) буде зберігатися в обраних системах клієнтів, які в основному розміщуються в США. Ця інформація буде використана підприємствами Corteva Agriscience ™, її філіями, партнерами та вибраними третіми сторонами в інших країнах, щоб надати Вам необхідну інформацію про продукт або послугу. Щоб дізнатися більше, відвідайте веб-сайт ",
        description2:
          "Надаючи вашу особисту інформацію, ви погоджуєтеся з умовами цього Положення про конфіденційність.",
        url: "https://www.corteva.com.ua/privacy-policy.html",
        urlText: "https://www.corteva.com.ua/privacy-policy.html",
        customer: "Клієнт/фермер/Дистриб'ютор",
        employee: "Співробітник/Кандидат/Колишній працівник/Підрядник",
        vendor: "Торговий агент/підрядник",
      },
      legalRepresentative: {
        title: "Запит на конфіденційність",
        header: "Для використання законним представником",
        introText:
          "Якщо ви є законним представником, який надсилає запит стосовно персональних даних від імені особи, заповніть форму нижче.",
        endingText: "Якщо ви надсилаєте запит для себе,",
        click: "натисніть тут",
        access: "access",
        accessDescription: "Повідомити мене про збережену інформацію",
        deletion: "deletion",
        deletionDescription:
          "Видалити всю особисту інформацію, пов'язану зі мною",
        correction: "correction",
        correctionDescription: "Оновити мою особисту інформацію",
        optOut: "optOut",
        optOutDescription: "Скасувати підписку на маркетингові повідомлення",
        other: "other",
        otherDescription: "Інше - опишіть нижче",
        description1:
          "Corteva Agriscience ™ дбає про вашу конфіденційність. Ваша особиста інформація (ім'я, електронна пошта, номер телефону та інші контактні дані) буде зберігатися в обраних системах клієнтів, які в основному розміщуються в США. Ця інформація буде використана підприємствами Corteva Agriscience ™, її філіями, партнерами та вибраними третіми сторонами в інших країнах, щоб надати Вам необхідну інформацію про продукт або послугу. Щоб дізнатися більше, відвідайте веб-сайт ",
        description2:
          "Надаючи вашу особисту інформацію, ви погоджуєтеся з умовами цього Положення про конфіденційність.",
        url: "https://www.corteva.com.ua/privacy-policy.html",
        urlText: "https://www.corteva.com.ua/privacy-policy.html",
        customer: "Клієнт/фермер/Дистриб'ютор",
        employee: "Співробітник/Кандидат/Колишній працівник/Підрядник",
        vendor: "Торговий агент/підрядник",
      },
      requestForm: {
        email: "Електронна пошта",
        emailPlaceholder: "E-mail *",
        firstName: "Ім'я",
        lastName: "Прізвище",
        phone: "Телефон",
        country: "Розташування ",
        countryPlaceholder: "Seleziona il Розташування *",
        robot: "Non sono un robot",
        submitRequest: "Надішліть запит.",
        errorText: "Потрібен дійсний номер",
        relationshipToCorteva: "Зв’язок з Corteva",
        customer: "Клієнт/фермер/Дистриб'ютор",
        employee: "Співробітник/Кандидат/Колишній працівник/Підрядник",
        vendor: "Торговий агент/підрядник",
        pleaseSpecify: "Будь ласка уточніть",
        company: "Компанія",
        otherRequestDetails: "Дайте докладну інформацію про свій запит",
        legalName: "Ім'я та прізвище законного представника",
        legalEmail: "Адреса електронної пошти законного представника",
        legalRelation:
          "Відносини законного представника з особою, яку ви представляєте (суб'єкт даних)",
        consumerRelation: "Зв'язок суб'єкта даних з Кортевою:",
        consumerFirstName: "Ім'я суб'єкта даних",
        consumerLastName: "Прізвище суб'єкта даних",
        consumerCompany: "Компанія суб'єкта даних",
        consumerPhone: "Телефон суб'єкта даних",
        consumerEmail: "Електронна адреса суб'єкта даних",
        consumerCountry: "Країна суб'єкта даних",
        required: "Це поле є обов'язковим",
        minPhone: "Потрібен дійсний номер",
        formUploadDes: "Завантажити документацію для законного представника",
      },
      acknowledgement: {
        title: "Підтвердження",
        ackText1: "Ваш ідентифікатор запиту стосовно персональних даних",
        ackText2: "Збережіть копію для своїх потреб.",
        ackText3:
          "На електронну адресу, яку ви вказали для всіх типів запитів, окрім запиту на скасування передплати, буде надіслано посилання для підтвердження.",
        unsubscribe: "",
        ackText4: "",
        ackText5:
          "Будь ласка, перевірте свою поштову скриньку, щоб підтвердити запит. Якщо ви не отримали електронного листа від",
        ackText6:
          "перевірте папку для спаму або надішліть запит іще раз. Дякуємо.",
      },
      AADoc: {
        description1:
          "Законний представник – це особа, яка має право подавати запит стосовно персональних даних від імені суб'єкта даних, крім його самого. Такі записи доступні особі, якої вони стосуються. Для захисту від несанкціонованого розголошення необхідно надати посвідчення особи як для себе, так і для суб'єкта даних, якого ви представляєте, а також довіреність або письмовий дозвіл від суб'єкта даних, якого ви представляєте.",
        heading: "Інструкції щодо завантаження документа:",
        description2:
          "Увійдіть до Центру конфіденційності даних, натиснувши кнопку «Перегляд запиту та керування ним» в електронному листі з темою «Запит суб'єкта даних розпочато».",
      },
      requestTypes: {
        contactReason: "Причина контакту",
      },
    },
  },
  es: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        deleteMyCortevaAccountDescription: "Delete my Corteva user account",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.es/politica-de-privacidad.htm",
        urlText: "https://www.corteva.es/politica-de-privacidad.htm",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        deleteMyCortevaAccountDescription: "Delete my Corteva user account",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.es/politica-de-privacidad.htm",
        urlText: "https://www.corteva.es/politica-de-privacidad.htm",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Retailer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        required: "This field is required",
        minPhone: "A valid number is required",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
    es: {
      intakeForm: {
        title: "Solicitud de privacidad",
        introText:
          "Si está enviando una solicitud propia, rellene el formulario siguiente. Si es representante legal, haga clic en el botón situado en la parte inferior de la pantalla para llegar al formulario de representantes legales.",
        endingText:
          "Si usted es representante legal y está presentando la solicitud de privacidad en nombre de otra persona, haga",
        click: " clic aquí",
        access: "access",
        accessDescription: "Informarme sobre la información almacenada",
        deletion: "deletion",
        deletionDescription:
          "Eliminar toda la información personal relacionada conmigo",
        correction: "correction",
        correctionDescription: "Actualizar mi información personal",
        optOut: "optOut",
        optOutDescription: "Darse de baja de las comunicaciones de marketing",
        other: "other",
        otherDescription: "Otro - Describa a continuació",
        deleteMyCortevaAccountDescription:
          "Eliminar mi Cuenta de Usuario de Corteva",
        description1:
          "Para Corteva Agriscience ™, la privacidad del cliente es importante. Los datos personales (nombre, correo electrónico, teléfono y otra información de contacto) se almacenarán en sistemas informáticos seleccionados, ubicados principalmente en los Estados Unidos. Estos datos serán utilizados por Corteva Agriscience ™, sus afiliados y socios, así como por terceros seleccionados en otros países, para proporcionar la información solicitada sobre productos o servicios. Para obtener más información, visite ",
        description2:
          "Al proporcionar datos personales, acepta los términos y condiciones de esta política de privacidad.",
        url: "https://www.corteva.es/politica-de-privacidad.html",
        urlText: "https://www.corteva.es/politica-de-privacidad.html",
        customer: "Cliente/Agricultor/Distribuidor",
        employee: "Empleado/Solicitante/Antiguo empleado/ Contratista",
        vendor: "Proveedor",
      },
      legalRepresentative: {
        title: "Solicitud de privacidad",
        header: "Para uso del Representante Legal",
        introText:
          "Si usted es representante legal y está presentando la solicitud de privacidad en nombre de otra persona, rellene el formulario siguiente.",
        endingText: "Si está enviando una solicitud propia, haga",
        click: "clic aquí",
        access: "access",
        accessDescription: "Informarme sobre la información almacenada",
        deletion: "deletion",
        deletionDescription:
          "Eliminar toda la información personal relacionada conmigo",
        correction: "correction",
        correctionDescription: "Actualizar mi información personal",
        optOut: "optOut",
        optOutDescription: "Darse de baja de las comunicaciones de marketing",
        other: "other",
        otherDescription: "Otro - Describa a continuació",
        deleteMyCortevaAccountDescription:
          "Eliminar mi Cuenta de Usuario de Corteva",
        description1:
          "Para Corteva Agriscience ™, la privacidad del cliente es importante. Los datos personales (nombre, correo electrónico, teléfono y otra información de contacto) se almacenarán en sistemas informáticos seleccionados, ubicados principalmente en los Estados Unidos. Estos datos serán utilizados por Corteva Agriscience ™, sus afiliados y socios, así como por terceros seleccionados en otros países, para proporcionar la información solicitada sobre productos o servicios. Para obtener más información, visite ",
        description2:
          "Al proporcionar datos personales, acepta los términos y condiciones de esta política de privacidad.",
        url: "https://www.corteva.es/politica-de-privacidad.html",
        urlText: "https://www.corteva.es/politica-de-privacidad.html",
        customer: "Cliente/Agricultor/Distribuidor",
        employee: "Empleado/Solicitante/Antiguo empleado/ Contratista",
        vendor: "Proveedor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Имейл *",
        firstName: "Nombre",
        firstNamePlaceholder: "Nome *",
        lastName: "Apellidos",
        lastNamePlaceholder: "Cognome *",
        phone: "Teléfono",
        phonePlaceholder: "Телефон *",
        country: "Localización",
        countryPlaceholder: "Seleccione Localización *",
        robot: "Non sono un robot",
        submitRequest: "Enviar solicitud",
        errorText: "Se requiere un número válido",
        relationshipToCorteva: "Relación con Corteva",
        customer: "Cliente/Agricultor/Distribuidor",
        employee: "Empleado/Solicitante/Antiguo empleado/ Contratista",
        vendor: "Proveedor",
        pleaseSpecify: "Por favor especifica *",
        company: "Empresa",
        companyPlaceholder: "Azienda (ragione sociale)",
        otherRequestDetails:
          "Por favor, proporcione más detalles sobre su solicitud",
        legalName: "Nombre y apellidos del representante legal",
        legalEmail: "Correo electrónico del representante legal",
        legalRelation:
          "Relación del representante legal con la persona a la que representa (sujeto de los datos)",
        consumerRelation: "Relación del sujeto de los datos con Corteva",
        consumerFirstName: "Nombre del sujeto de los datos",
        consumerLastName: "Apellido del sujeto de los datos",
        consumerCompany: "Compañía del sujeto de los datos",
        consumerPhone: "Teléfono del sujeto de los datos",
        consumerEmail: "Correo electrónico del sujeto de los datos",
        consumerCountry: "País del sujeto de los datos",
        required: "Este campo es obligatorio",
        minPhone: "Se requiere un número válido",
        formUploadDes: "Cargar documentación del representante legal",
      },
      acknowledgement: {
        title: "Confirmación",
        ackText1: "La ID de solicitud de privacidad es",
        ackText2: "Guarde una copia como registro.",
        ackText3: "",
        unsubscribe: "",
        ackText4: "",
        ackText5:
          "Compruebe su bandeja de entrada para verificar su solicitud. Si no ha recibido un correo electrónico de",
        ackText6:
          "compruebe la carpeta de spam o reenvíe su solicitud. Gracias.",
      },
      AADoc: {
        description1:
          "El representante legal es una persona facultada para presentar una solicitud de privacidad de los datos en nombre de un sujeto de los datos que no sea él mismo. La persona a la que pertenecen estos registros podrá acceder a ellos. Para proteger la divulgación no autorizada, se requiere que proporcione: una prueba de identidad tanto para usted como para el sujeto de los datos al que representa, así como un poder notarial o una autorización por escrito del sujeto de los datos al que representa.",
        heading: "Instrucciones para cargar el documento:",
        description2:
          "acceda al Centro de Privacidad de los Datos haciendo clic en el botón «Ver o gestionar su solicitud», que aparece en el correo electrónico con el asunto «Solicitud del interesado iniciada».",
      },
      requestTypes: {
        contactReason: "Motivo de contacto",
      },
    },
  },
  ke: {
    en: {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.co.ke/privacy.html",
        urlText: "https://www.corteva.co.ke/privacy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.co.ke/privacy.html",
        urlText: "https://www.corteva.co.ke/privacy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
  },
  "en-gb": {
    "en-gb": {
      intakeForm: {
        title: "Privacy Request",
        introText:
          "If you are submitting a request for yourself, please fill out the form below. If you are a Legal Representative please click the button at the bottom of the screen to reach the form for Legal Representatives.",
        endingText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.co.uk/privacy.html",
        urlText: "https://www.corteva.co.uk/privacy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      legalRepresentative: {
        title: "Privacy Request",
        header: "For use by Legal Representative",
        introText:
          "If you are a Legal Representative submitting the privacy request on behalf of a person, please fill out the form below.",
        endingText: "If you are submitting a request for yourself, please",
        click: "click here",
        access: "access",
        accessDescription: "Inform me about stored information",
        deletion: "deletion",
        deletionDescription: "Delete all personal information related to me",
        correction: "correction",
        correctionDescription: "Update my personal information",
        optOut: "optOut",
        optOutDescription: "Unsubscribe from marketing communications",
        other: "other",
        otherDescription: "Other - describe below",
        description1:
          "Corteva Agriscience™ cares about your privacy. Your personal information (name, email, phone number and other contact data) will be stored in chosen customer systems primarily hosted in the United States. This information will be used by businesses of Corteva Agriscience™, its affiliates, partners, and selected third parties in other countries to provide you with the product or service information requested. To learn more, please visit ",
        description2:
          "By providing your personal information, you agree to the terms and conditions of this Privacy Statement.",
        url: "https://www.corteva.co.uk/privacy.html",
        urlText: "https://www.corteva.co.uk/privacy.html",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
      },
      requestForm: {
        email: "Email",
        emailPlaceholder: "Email *",
        firstName: "First Name",
        firstNamePlaceholder: "First Name *",
        lastName: "Last Name",
        lastNamePlaceholder: "Last Name *",
        phone: "Phone Number",
        phonePlaceholder: "Phone Number *",
        country: "Country",
        countryPlaceholder: "Select Country *",
        robot: "I'm not a robot",
        submitRequest: "Submit Request",
        errorText: "A valid number is required",
        relationshipToCorteva: "Relationship to Corteva",
        customer: "Customer/Farmer/Distributor",
        employee: "Employee/Applicant/Former employee/Contractor",
        vendor: "Vendor",
        pleaseSpecify: "Please Specify *",
        company: "Company",
        companyPlaceholder: "Company",
        otherRequestDetails: "Please provide details about the request",
        legalName: "Legal Representative First Name and Last Name",
        legalEmail: "Legal Representative Email",
        legalRelation:
          "Legal Representative Relationship with the person you represent (Data Subject)",
        consumerRelation: "Data Subject Relationship to Corteva",
        consumerFirstName: "Data Subject First Name",
        consumerLastName: "Data Subject Last Name",
        consumerCompany: "Data Subject Company",
        consumerPhone: "Data Subject Phone Number",
        consumerEmail: "Data Subject Email",
        consumerCountry: "Data Subject Country",
        formUploadDes: "Upload Legal Representative documents",
      },
      acknowledgement: {
        title: "Confirmation",
        ackText1: "Your Privacy Request ID is",
        ackText2: "Please save a copy for your records.",
        ackText3:
          "A verification link will be sent to the email address you provided for all request types except for an",
        unsubscribe: "unsubscribe",
        ackText4: "request.",
        ackText5:
          "Please check your inbox to verify your request. If you did not receive an email from",
        ackText6:
          "please check your spam folder or resubmit your request. Thank you.",
      },
      AADoc: {
        description1:
          "A Legal representative is an individual empowered to submit a data privacy request on behalf of a Data Subject other than themselves. Such records are accessible to the Person to whom they pertain. To protect unauthorized disclosure, it is required that you provide: a proof of identity both for yourself and the Data Subject you represent, as well as a Power of Attorney or a written authorization from the Data Subject you represent.",

        heading: "Instructions for upload the document:",
        description2:
          'Please login to the Data Privacy Center by clicking on the "View or manage your request" button in the email with the subject line "Data Subject Request Started".',
      },
      requestTypes: {
        contactReason: "Contact Reason",
      },
    },
  },
};
