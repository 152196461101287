import React, { useState, useEffect, useContext } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { booleanContext } from "App";

// Components
import Recaptcha from "shared/components/ReCaptcha";
import RequestTypes from "shared/components/RequestTypes";

// Helpers
import getPayload from "shared/helpers/getPayload";
import { getService } from "shared/helpers/restApi";
import { Constants } from "shared/helpers/Constants";
import { getCountryName } from "shared/helpers/utils";
import { getText } from "shared/helpers/utils";

function RequestForm(props) {
  const { selectedLang, countryCode } = useContext(booleanContext);
  const handleText = (attr) => {
    return getText(attr, countryCode, selectedLang, "requestForm");
  };

  const textValidation = {
    required: handleText("required"),
  };

  const selectValidation = {
    required: handleText("required"),
  };

  const emailValidation = {
    required: handleText("required"),
  };

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [sendRequest, setSendRequest] = useState(false);
  const [vendorSelected, setVendorSelected] = useState(false);
  const [value, setValue] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [otherRequestType, setOtherRequestType] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  const {
    configValues,
    enableLoader,
    getAcknowledgement,
    getSubmitRequestId,
    requestFor,
    getRelationshipType,
    relationshipType,
  } = props;

  const handleReCaptchaClick = (value) => {
    setDisableSubmit(!value);
  };

  const handleVendorInput = (e) => {
    getRelationshipType(e.target.value);
    if (e.target.value === handleText("vendor")) {
      setVendorSelected(true);
    } else {
      setVendorSelected(false);
    }
  };

  const { register, handleSubmit, formState, errors, validate, control } =
    useForm({
      mode: "onChange",
    });

  const onSubmit = (data) => {
    setSendRequest(data);
  };

  useEffect(() => {
    const ENV = process.env;

    if (sendRequest) {
      // activate the loader
      enableLoader(true);
      // payload
      const inputsForPayLoad = {
        selectedRequests,
        inputsData: sendRequest,
        configValues,
        selectedLang,
        recaptchaToken,
      };

      const payload = getPayload(inputsForPayLoad);

      // API request
      const submitOptions = {
        method: `POST`,
        url: ENV.REACT_APP_CURRENT_ENVIRONMENT_API_URL,
        Headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: payload,
      };

      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (response.status === 201 && response.data) {
            // get the root id after successful request submit
            getSubmitRequestId(response.data.rootId);
            if (response.data.rootId && requestFor === "AuthorizedAgent") {
              const data = new FormData();
              data.append("files", selectedFile);
              data.append("dsarId", response.data.rootId);

              const uploadDocOptions = {
                method: `POST`,
                url: ENV.REACT_APP_CORTEVA_DSAR_AA_FILE_UPLOAD_URL,
                data,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };

              const uploadDocResponse = getService(uploadDocOptions);
              uploadDocResponse
                .then((response) => {
                  if (response.status === 200 && response.data) {
                    console.log("response", response.data);
                  }
                })
                .catch((err) => console.log("File Upload Error", err));
            }
          }
        })
        .then(() => {
          // deactivate the loader and enable the Acknowledgement
          enableLoader(false);
          getAcknowledgement(true);
        })
        .catch((err) => {
          console.error(err);
          // TODO: show error to user
        });
    }
    return () => {
      setSendRequest(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest]);

  const getResponseToken = (token) => {
    setRecaptchaToken(token);
  };

  const handleRequestTypesChange = (requestTypes) => {
    setSelectedRequests(requestTypes);
  };

  const handleOtherRequestType = (bool) => {
    setOtherRequestType(bool);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  return (
    <div className="main-content">
      <Card className="my-5 mx-auto form shadow">
        <Card.Body>
          <Form className="form-inputs" onSubmit={handleSubmit(onSubmit)}>
            {requestFor === "Myself" ? (
              <>
                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("relationshipToCorteva")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Row className="ml-2">
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label={handleText("customer")}
                            name="relationshipToCorteva"
                            id="customer"
                            value={handleText("customer")}
                            className="radio-label-item"
                            // className="left radio-label-item white-space-nowrap"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                      <Row className="ml-2">
                        {" "}
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label={handleText("employee")}
                            name="relationshipToCorteva"
                            value={handleText("employee")}
                            id="employee"
                            className="radio-label-item"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                      <Row className="ml-2">
                        {" "}
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label={handleText("vendor")}
                            name="relationshipToCorteva"
                            id="vendor"
                            value={handleText("vendor")}
                            className="radio-label-item"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                <RequestTypes
                  requestTypes={configValues.requestTypes}
                  onRequestTypesChange={handleRequestTypesChange}
                  onOtherRequestType={handleOtherRequestType}
                  relationshipType={relationshipType}
                />

                {Constants.apacCountryCodes.includes(countryCode) &&
                  selectedRequests.includes("correction") && (
                    <Form.Group className="mb-3">
                      <Row xs={1}>
                        <Col className="mb-2">
                          <Form.Label className="fw-bold label-text">
                            {handleText("correctionDetails")}{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="correctionDetails"
                            className="mb-2"
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  )}

                {Constants.latamCountryCodes.includes(countryCode) ? (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          {handleText("subjectDetails")}{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="subjectDetails"
                          className="mb-2"
                          ref={register({ required: false })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : Constants.apacCountryCodes.includes(countryCode) &&
                  selectedRequests.includes("correction") &&
                  selectedRequests.length === 1 ? (
                  ""
                ) : (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          {handleText("otherRequestDetails")}{" "}
                          {otherRequestType ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="otherRequestDetails"
                          className="mb-2"
                          ref={register({ required: otherRequestType })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                )}

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        {handleText("firstName")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        className={!errors.firstName ? "mb-2" : "error-border"}
                        ref={register(textValidation)}
                      />
                      {errors.firstName && (
                        <small className="text-danger">
                          {" "}
                          {errors.firstName.message}{" "}
                        </small>
                      )}
                    </Col>

                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("lastName")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        className={!errors.lastName ? "mb-2" : "error-border"}
                        ref={register(textValidation)}
                      />
                      {errors.lastName && (
                        <small className="text-danger">
                          {" "}
                          {errors.lastName.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("company")}{" "}
                        {vendorSelected && (
                          <span className="text-danger">*</span>
                        )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="company"
                        className={!errors.company ? "mb-2" : "error-border"}
                        ref={register({ required: vendorSelected })}
                      />
                      {errors.company && (
                        <small className="text-danger">
                          {" "}
                          {errors.company.message}{" "}
                        </small>
                      )}
                    </Col>
                    <Col className="mb-3">
                      <Form.Label className="fw-bold label-text">
                        {handleText("phone")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>

                      <Controller
                        as={
                          <PhoneInput
                            value={value}
                            onChange={(value) => setValue(value)}
                            country={countryCode}
                            inputProps={{
                              name: "phoneNumber",
                              required: true,
                            }}
                            ref={() => {
                              register({ required: true });
                            }}
                            name="phoneNumber"
                            placeholder=""
                            enableSearch
                            buttonClass="rounded"
                            inputStyle={{ width: "100%" }}
                          />
                        }
                        name="phoneNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: handleText("required"),

                          minLength: {
                            value: 10,
                            message: handleText("minPhone"),
                          },
                        }}
                      />
                      {errors.phoneNumber && (
                        <small className="text-danger">
                          {" "}
                          {errors.phoneNumber.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("email")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        className={!errors.email ? "mb-2" : "error-border mb-2"}
                        ref={register(emailValidation)}
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </Col>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("country")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="country"
                        className={!errors.country ? "mb-2" : "error-border"}
                        ref={register(selectValidation)}
                      >
                        <option value="">
                          {handleText("countryPlaceholder")}
                        </option>
                        {getCountryName(countryCode).map(
                          (countryVal, index) => (
                            <option key={index} value={countryVal}>
                              {countryVal}
                            </option>
                          )
                        )}
                      </Form.Control>
                      {errors.country && (
                        <small className="text-danger">
                          {" "}
                          {errors.country.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3 w-75">
                  <Row></Row>
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group className="mb-3">
                  <Row xs={1} md={1}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("legalEmail")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        className={
                          !errors.legalRepresentativeEmail
                            ? "mb-2"
                            : "error-border"
                        }
                        ref={register(emailValidation)}
                      />
                      {errors.legalRepresentativeEmail && (
                        <small className="text-danger">
                          {" "}
                          {errors.legalRepresentativeEmail.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={1}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("legalName")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="legalRepresentativeName"
                        className={
                          !errors.legalRepresentativeName
                            ? "mb-2"
                            : "error-border"
                        }
                        ref={register(textValidation)}
                      />
                      {errors.legalRepresentativeName && (
                        <small className="text-danger">
                          {" "}
                          {errors.legalRepresentativeName.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("legalRelation")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="legalRepresentativeRelationshipWithDataSubject"
                        className={
                          !errors.legalRepresentativeRelationshipWithDataSubject
                            ? "mb-2"
                            : "error-border"
                        }
                        ref={register(textValidation)}
                      />
                      {errors.legalRepresentativeRelationshipWithDataSubject && (
                        <small className="text-danger">
                          {" "}
                          {
                            errors
                              .legalRepresentativeRelationshipWithDataSubject
                              .message
                          }{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("consumerRelation")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Row className="ml-2">
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label={handleText("customer")}
                            name="dataSubjectRelationship"
                            id="customer"
                            value={handleText("customer")}
                            className="radio-label-item"
                            // className="left radio-label-item white-space-nowrap"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                      <Row className="ml-2">
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label={handleText("employee")}
                            name="dataSubjectRelationship"
                            value={handleText("employee")}
                            id="employee"
                            className="radio-label-item"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                      <Row className="ml-2">
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label={handleText("vendor")}
                            name="dataSubjectRelationship"
                            id="vendor"
                            value={handleText("vendor")}
                            className="radio-label-item"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>

                <RequestTypes
                  requestTypes={configValues.requestTypes}
                  onRequestTypesChange={handleRequestTypesChange}
                  onOtherRequestType={handleOtherRequestType}
                  relationshipType={relationshipType}
                />

                {Constants.apacCountryCodes.includes(countryCode) &&
                  selectedRequests.includes("correction") && (
                    <Form.Group className="mb-3">
                      <Row xs={1}>
                        <Col className="mb-2">
                          <Form.Label className="fw-bold label-text">
                            {handleText("correctionDetails")}{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="correctionDetails"
                            className="mb-2"
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  )}

                {Constants.latamCountryCodes.includes(countryCode) ? (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          {handleText("subjectDetails")}{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="subjectDetails"
                          className="mb-2"
                          ref={register({ required: false })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : Constants.apacCountryCodes.includes(countryCode) &&
                  selectedRequests.includes("correction") &&
                  selectedRequests.length === 1 ? (
                  ""
                ) : (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          {handleText("otherRequestDetails")}{" "}
                          {otherRequestType ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="otherRequestDetails"
                          className="mb-2"
                          ref={register({ required: otherRequestType })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                )}

                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("consumerFirstName")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dataSubjectFirstName"
                        className={
                          !errors.dataSubjectFirstName ? "mb-2" : "error-border"
                        }
                        ref={register(textValidation)}
                      />
                      {errors.dataSubjectFirstName && (
                        <small className="text-danger">
                          {" "}
                          {errors.dataSubjectFirstName.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("consumerLastName")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dataSubjectLastName"
                        className={
                          !errors.dataSubjectLastName ? "mb-2" : "error-border"
                        }
                        ref={register(textValidation)}
                      />
                      {errors.dataSubjectLastName && (
                        <small className="text-danger">
                          {" "}
                          {errors.dataSubjectLastName.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("consumerCompany")}{" "}
                        {vendorSelected && (
                          <span className="text-danger">*</span>
                        )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dataSubjectCompany"
                        className={
                          !errors.dataSubjectCompany ? "mb-2" : "error-border"
                        }
                        ref={register({ required: vendorSelected })}
                      />
                      {errors.dataSubjectCompany && (
                        <small className="text-danger">
                          {" "}
                          {errors.dataSubjectCompany.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("consumerPhone")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Controller
                        as={
                          <PhoneInput
                            value={value}
                            country={countryCode}
                            onChange={(value) => setValue(value)}
                            inputProps={{
                              name: "dataSubjectPhoneNumber",
                              required: true,
                            }}
                            ref={() => {
                              register({ required: true });
                            }}
                            placeholder=""
                            enableSearch
                            name="dataSubjectPhoneNumber"
                            buttonClass="rounded"
                            inputStyle={{ width: "100%" }}
                          />
                        }
                        name="dataSubjectPhoneNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: handleText("required"),

                          minLength: {
                            value: 10,
                            message: handleText("minPhone"),
                          },
                        }}
                      />

                      {errors.dataSubjectPhoneNumber && (
                        <small className="text-danger">
                          {" "}
                          {errors.dataSubjectPhoneNumber.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("consumerEmail")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="dataSubjectEmail"
                        className={
                          !errors.dataSubjectEmail ? "mb-2" : "error-border"
                        }
                        ref={register(emailValidation)}
                      />
                      {errors.dataSubjectEmail && (
                        <small className="text-danger">
                          {" "}
                          {errors.dataSubjectEmail.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {handleText("consumerCountry")}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="dataSubjectCountry"
                        className={
                          !errors.dataSubjectCountry ? "mb-2" : "error-border"
                        }
                        ref={register(selectValidation)}
                      >
                        <option value="">
                          {handleText("countryPlaceholder")}
                        </option>
                        {getCountryName(countryCode).map(
                          (countryVal, index) => (
                            <option key={index} value={countryVal}>
                              {countryVal}
                            </option>
                          )
                        )}
                      </Form.Control>
                      {errors.dataSubjectCountry && (
                        <small className="text-danger">
                          {" "}
                          {errors.dataSubjectCountry.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group as={Col} controlId="file" className="mt-4">
                  <Form.Row>
                    <Form.Label className="fw-bold label-text">
                      <u>{handleText("formUploadDes")}</u>{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.File
                      id="uploadFile"
                      name="uploadFile"
                      onChange={handleFileChange}
                      ref={register({ required: true })}
                      className=""
                      required
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                    />
                  </Form.Row>
                </Form.Group>
              </>
            )}

            <div className="d-flex justify-content-center mt-4">
              <Recaptcha
                reCaptchaClick={handleReCaptchaClick}
                responseToken={getResponseToken}
                selectedLang={selectedLang}
                countryCode={countryCode}
              />
            </div>

            <Form.Group>
              <Row className="d-flex justify-content-center mt-5">
                <Col sm={12} md={10} lg={10} className="text-center">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    style={{
                      width: "inherit",
                    }}
                    disabled={
                      !formState.isValid ||
                      disableSubmit ||
                      validate ||
                      !selectedRequests.length
                    }
                  >
                    {handleText("submitRequest")}
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default RequestForm;
