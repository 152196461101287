export const Constants = {
  states: [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Federated States Of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Perto Rico",
    "Rhone Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Islands",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ],
  countries: [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Czechia",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Kazakhstan",
    "Latvia",
    "Lithuania",
    "Moldova",
    "Netherlands",
    "Poland",
    "Portugal",
    "Romania",
    "Serbia",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Turkey",
    "Ukraine",
    "United Kingdom",
  ],
  brazil: ["Brazil"],
  ireland: ["Ireland"],
  kenya: ["Kenya"],
  UnitedKingdom: ["United Kingdom"],
  colombia: ["Colombia"],
  mexico: ["Mexico"],
  argentina: ["Argentina"],
  chile: ["Chile"],
  southAfrica: ["South Africa"],
  australia: ["Australia"],
  newzealand: ["New Zealand"],

  countryLangCodes: {
    cl: {
      es: "SPANISH (ES)",
    },
    za: {
      af: "Afrikaans (AF)",
    },
    br: {
      pt_BR: "Portuguese (PT_BR)",
    },
    co: {
      es: "SPANISH (ES)",
    },
    ar: {
      es: "SPANISH (ES)",
    },
    mx: {
      es: "SPANISH (ES)",
    },
    be: {
      fr_be: "French (FR_BE)",
      de: "German (DE)",
      nl_be: "Dutch (NL_BE)",
    },
    bg: "Bulgaria (BG)",
    cs: "Czech (CZ)",
    da: "Danish (DA)",
    de: "Germany (DE)",
    el: "Greek (EL)",
    es: "Spanish (ES)",
    et: "Estonia (ET)",
    fi: "Finland (FI)",
    fr: "France (FR)",
    hr: "Croatia (HR)",
    hu: "Hungarian (HU)",
    it: "Italian (IT)",
    lt: "Lithuania (LT)",
    lv: "Latvian (LV)",
    mld: "Moldova (MLD)",
    nl: "Dutch (NL)",
    pl: "Polish (PL)",
    pt: "Portugese (PT)",
    ro: "Romanian (RO)",
    sl: "Slovenian (SL)",
    sr: { sr_BA: "Serbian (SR_BA)" },
    sv: "Sweden (SV)",
    sk: "Slovakia (SK)",
    tr: "Turkey (TR)",
    uk: "Ukraine (UK)",
    au: "Australia (EN)",
    nz: "New Zealand (EN)",
    ie: "Ireland (EN)",
    ke: "Kenya (EN)",
    "en-gb": "United Kingdom (EN-GB)",
  },
  latamCountryCodes: ["co", "mx", "ar", "cl"],
  apacCountryCodes: ["au", "nz"],
};
