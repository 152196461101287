import React, { useEffect, useState } from "react";
import RecaptchaCheck from "react-google-recaptcha";

function ReCaptcha(props) {
  const { reCaptchaClick, selectedLang, responseToken } = props;
  const [recaptchaLang, setRecaptchaLang] = useState(selectedLang);

  useEffect(() => {
    if (selectedLang) {
      const recaptchaLang = selectedLang === "en" ? "en" : selectedLang;

      setRecaptchaLang(recaptchaLang);
      const iframes = document.getElementsByTagName("iframe");
      const iframesArr = Array.from(iframes);
      if (iframesArr.length) {
        const recapIframe = iframesArr.filter(
          (iframe) => iframe.attributes[0].value === "reCAPTCHA"
        );

        if (recapIframe.length) {
          // Get language code from iframe
          var langIframe = recapIframe[0].src.match(/hl=(.*?)&/).pop();

          // Check if language code of element is not equal by selected language, we need to set new language code
          if (langIframe !== selectedLang) {
            // For setting new language
            recapIframe[0].src = recapIframe[0].src.replace(
              /hl=(.*?)&/,
              "hl=" + selectedLang + "&"
            );
          }
        }
      }
    }
  }, [selectedLang]);

  const verifyCallback = (response) => {
    if (response) {
      responseToken(response);
      reCaptchaClick(true);
    }
  };

  const expiredCallback = () => {
    reCaptchaClick(false);
    setRecaptchaLang(selectedLang === "en" ? "en" : selectedLang);
  };

  return (
    <RecaptchaCheck
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      onChange={verifyCallback}
      onExpired={expiredCallback}
      className="gg-recaptcha"
      hl={recaptchaLang}
    />
  );
}

export default ReCaptcha;
