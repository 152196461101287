import React from "react";
import SrrHeader from "./SrrHeader";

function Notfound(props) {
  const { countryCode, lang } = props;
  return (
    <div>
      <SrrHeader
        heading={`${countryCode || lang} is not a valid ${
          countryCode ? "Country Code" : "Language Code"
        }`}
        errorMsg
      />
    </div>
  );
}

export default Notfound;
