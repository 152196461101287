import React, { useState, useEffect, useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import { booleanContext } from "App";

// Components
import SrrHeader from "shared/components/SrrHeader";
import Acknowledgement from "shared/components/Acknowledgement";
import RequestForm from "shared/components/RequestForm";
import AADocument from "shared/components/AADocument";
import Notfound from "shared/components/Notfound";

// Helpers
import { getService } from "shared/helpers/restApi";
import {
  checkCountryCode,
  checkLang,
  getPrivacyFormURL,
  getRequestTypeDes,
  getText,
} from "shared/helpers/utils";

function LegalRepresentative() {
  const { selectedLang, countryCode, params } = useContext(booleanContext);
  const handleText = (attr) => {
    return getText(attr, countryCode, selectedLang, "legalRepresentative");
  };
  const [loader, setLoader] = useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [configValues, setConfigValues] = useState({});
  const [relationshipType, setRelationshipType] = useState("");

  // handling the loader activation here
  const handleLoader = (value) => {
    setLoader(value);
  };

  // handling the acknowledgement activation here
  const handleAcknowledgement = (acknowledge) => {
    setShowAcknowledgement(acknowledge);
  };

  // handling the submit request root Id here
  const handleSubmitRequestId = (value) => {
    setRequestId(value);
  };

  // useEffect to get the configuration values
  useEffect(() => {
    if (checkLang(countryCode, params?.lang || selectedLang)) {
      let configOptions = {
        method: "GET",
        url: getPrivacyFormURL(
          countryCode,
          relationshipType,
          selectedLang,
          "AA",
          "legalRepresentative"
        ),
      };
      const configData = getService(configOptions);

      configData
        .then((response) => {
          if (response.status === 200 && response.data) {
            const { requests, settingsId } = response.data.srrConfig;
            const actions = requests.reduce((actionsMap, request) => {
              actionsMap[request.requestType] = request;
              return actionsMap;
            }, {});

            let finalOrderedActions;

            // changing the order of actions object
            let objectTemp1 = {
              access: null,
              correction: null,
              deletion: null,
              optOut: null,
              other: null,
            };

            objectTemp1 =
              (countryCode === "es" ||
                countryCode === "fr" ||
                countryCode === "de") &&
              relationshipType === handleText("customer")
                ? delete objectTemp1["other"] && {
                    ...objectTemp1,
                    "delete-my-corteva-account": null,
                    other: null,
                  }
                : countryCode === "ro"
                ? delete objectTemp1["other"] && {
                    ...objectTemp1,
                    "delete-my-corteva-account": null,
                    other: null,
                  }
                : countryCode === "br"
                ? delete objectTemp1["other"]
                : objectTemp1;

            let objectTemp2 = {
              access: null,
              correction: null,
              deletion: null,
              other: null,
            };

            objectTemp2 =
              countryCode === "br" ? delete objectTemp2["other"] : objectTemp2;

            const finalObjTemp =
              relationshipType && relationshipType === handleText("employee")
                ? objectTemp2
                : objectTemp1;

            if (Object.keys(actions).length > 0) {
              finalOrderedActions = Object.assign(finalObjTemp, actions);
            }

            const requestTypes = Object.keys(finalOrderedActions).map(
              (request) =>
                getRequestTypeDes(
                  countryCode,
                  request,
                  selectedLang,
                  "legalRepresentative"
                )
            );

            setConfigValues({
              actions,
              requestTypes,
              settings: settingsId,
              _id: settingsId,
            });
          }
        })
        .catch((err) => {
          throw err;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLang, relationshipType]);

  const handleRelationshipType = (value) => {
    setRelationshipType(value);
  };

  if (
    !checkCountryCode(countryCode) ||
    !checkLang(countryCode, params?.lang || selectedLang)
  ) {
    return (
      <Notfound
        countryCode={!checkCountryCode(countryCode) ? countryCode : ""}
        lang={params?.lang || selectedLang}
      />
    );
  }

  return (
    <div>
      <SrrHeader heading={handleText("title")} />

      <Container className="my-3">
        {loader ? (
          <div className="">
            <Spinner animation="grow" variant="primary" className="spinner" />
          </div>
        ) : showAcknowledgement ? (
          <Acknowledgement id={requestId} />
        ) : (
          <>
            <p>{handleText("introText")}</p>

            <RequestForm
              configValues={configValues}
              enableLoader={handleLoader}
              getAcknowledgement={handleAcknowledgement}
              getSubmitRequestId={handleSubmitRequestId}
              getRelationshipType={handleRelationshipType}
              relationshipType={relationshipType}
              requestFor="AuthorizedAgent"
            />

            <AADocument requestFor="AuthorizedAgent" />

            <p>
              {handleText("endingText")}{" "}
              <a
                href={
                  process.env.REACT_APP_CURRENT_ENV === "UAT"
                    ? `/?lang=${countryCode}`
                    : params?.lang
                    ? `/?lang=${params?.lang}`
                    : `/`
                }
              >
                {handleText("click")}
              </a>
              .
            </p>
          </>
        )}
      </Container>
    </div>
  );
}

export default LegalRepresentative;
