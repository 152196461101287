import React from "react";

function SrrHeader({ heading, errorMsg = "" }) {
  return (
    <>
      <div className={`heading mb-5 ${errorMsg ? "errorMsg" : "headingMsg"}`}>
        <div className="text-center">
          <h1>{heading}</h1>
        </div>
      </div>
    </>
  );
}

export default SrrHeader;
