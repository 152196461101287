import React, { useContext } from "react";
import { Container, Card } from "react-bootstrap";
import { booleanContext } from "App";

// Helpers
import { getText } from "shared/helpers/utils";

function Acknowledgement(props) {
  const { id } = props;
  const { selectedLang, countryCode } = useContext(booleanContext);
  const handleText = (attr) => {
    return getText(attr, countryCode, selectedLang, "acknowledgement");
  };

  return (
    <div>
      <Container className="my-5">
        <Card>
          <Card.Body className="ml-2 text-justify">
            <Card.Title className="mb-5 font-weight-bold">
              <u>
                <h3 className="text-center">{handleText("title")}</h3>
              </u>
            </Card.Title>
            <div className="mb-5">
              <Card.Text>
                {handleText("ackText1")} <strong>{id}</strong>.{" "}
                {handleText("ackText2")}
              </Card.Text>

              <Card.Text>
                {handleText("ackText5")}{" "}
                <a
                  href="mailto: no-reply@privacyrequest.corteva.com"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  target="_blank"
                  rel="noreferrer noopener"
                  title="no-reply@privacyrequest.corteva.com"
                >
                  no-reply@privacyrequest.corteva.com
                </a>
                , {handleText("ackText6")}
              </Card.Text>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Acknowledgement;
